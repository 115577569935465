import { SET_GENERATE_USER_ASSET_REPORT_DATA } from "../constants/ActionTypes";

const initState = {
  // Trade report data
  generateUserAssetReportData: "",
};
const generateUserAssetReport = (state = initState, action) => {
  switch (action.type) {
    case SET_GENERATE_USER_ASSET_REPORT_DATA:
      return { ...state, generateUserAssetReportData: action.payload };

    default:
      return state;
  }
};

export default generateUserAssetReport;
