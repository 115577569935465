import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  USER_DATA,
  SET_USER_TIMEZONE,
  THEME_COLOR
} from "./constants/ActionTypes";

import configureStore, { history } from "./store";
import App from "./containers/App";
import {
  setUserData,
  setUserTimeZoneLS,
  setOffsetTime,
  setThemeColor
} from "./util/localStorageService";
import CacheBuster from "./CacheBuster";

export const store = configureStore();

store.subscribe(() => {
  const state = store.getState();
  switch (state.commonData.lastActionType) {
    case USER_DATA: {
      setUserData(JSON.stringify(state.auth.authUser));
      break;
    }
    case SET_USER_TIMEZONE:
      setUserTimeZoneLS(state.app.userTimeZone);
      setOffsetTime(state.app.timeZoneOffsetTime);
      break;
    case THEME_COLOR: {
      setThemeColor(state.settings.themeColor);
      break;
    }
    default:
      break;
  }
});

if (window.performance && performance.navigation.type === 1) {
  // flag - check for token authentication on page refresh here
  // alert("This page is reloaded");
}

const MainApp = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Switch>
                <Route path="/" component={App} />
              </Switch>
            </ConnectedRouter>
          </Provider>
        );
      }}
    </CacheBuster>
  );
};

export default MainApp;
