import { SET_CHART_dATA } from "../constants/ActionTypes";

const initState = {
  chartData: [],
};

const ChartReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CHART_dATA:
      return {
        ...state,
        chartData: action.payload,
      };
    default:
      return state;
  }
};

export default ChartReducer;
