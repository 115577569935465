import {
  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_OPTIONS,
  GET_ID_NOTIFICATION,
} from "../constants/ActionTypes";
import { APP_DEFAULTS, LISTING_REQ_ORIGIN } from "../util/Utility";

const initState = {
  notificationsListData: [],
  notificationListingTablePageIndex: 0,
  notificationListingRowsPerPage: APP_DEFAULTS.TABLE_PAGE_SIZE_10,
  notificationListingRecordsCount: 0,
};

const notifications = (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            notificationListingTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            notificationListingRowsPerPage: action.stateData,
            notificationListingTablePageIndex: 0,
          };
          break;
      }
      return {
        ...state,
        notificationsListData: action.payload.data,
        notificationListingRecordsCount: action.payload.totalCount,
        ...stateDataHelper,
      };
    }

    case GET_ALL_NOTIFICATION_OPTIONS:
      return { ...state, notificationsOptionData: action.payload };

    case GET_ID_NOTIFICATION:
      return { ...state, notificationsIdData: action.payload };

    default:
      return state;
  }
};

export default notifications;
