import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper } from "@material-ui/core";
import InfoView from "../../../../components/InfoView";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import Button from "@material-ui/core/Button";
import CreateNotification from "../createNotification";
import {
  deleteNotifications,
  getAllNotifications,
  getNotificationsById,
} from "../../../../actions/notification";
import TableComponent from "../../../../components/Table/TableComponent1";
import { headCells } from "./tableHead";
import { getActionColumn, getColumnComponent } from "./tableColumns";

const ItemNotification = ({ match }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    showRoleDialog: false,
    create: false,
  });

  const handleChangePage = (event, _newPage) => {
    return;
  };

  const handleChangeRowsPerPage = (_event) => {
    return;
  };

  const {
    notificationsListData: notificationList = [],
    notificationListingRecordsCount: totalNotificationCount = null,
    notificationListingTablePageIndex: notificationListPageIndex,
    notificationListingRowsPerPage: notificationListRowsPerPage,
    notificationsIdData,
  } = useSelector((state) => state.notification);

  const openCreateNotification = () => {
    setState({ ...state, showRoleDialog: true, create: true });
  };

  const editDatafunction = (id) => {
    dispatch(getNotificationsById(id));
    setState({ ...state, showRoleDialog: true, create: false });
  };

  function deleteNotificationData(id) {
    dispatch(deleteNotifications(id));
  }

  useEffect(() => {
    dispatch(getAllNotifications());
  }, []);

  const transformData = useMemo(() => {
    return notificationList.reverse().map((row) => ({
      created: getColumnComponent(row.createdDate, "datetime"),
      title: getColumnComponent(row.title, "string"),
      description: getColumnComponent(row.description, "string"),
      startDate: getColumnComponent(row.startDateUtc, "date"),
      endDate: row.endDateUtc
        ? getColumnComponent(row.endDateUtc, "date")
        : "Not Available",
      type: row.notificationType,
      action: getActionColumn(row, editDatafunction, deleteNotificationData),
    }));
  }, [notificationList]);

  return (
    <div>
      <InfoView />
      <ContainerHeader
        match={match}
        title={<IntlMessages id="Notification" />}
      />

      <Paper>
        <Grid
          container
          direction="row"
          spacing={3}
          className="listing-table-view"
        >
          <Grid item md={8}></Grid>

          <Grid item md={4} style={{ textAlign: "right" }}>
            <Button
              variant="contained"
              color="primary"
              className="jr-btn"
              onClick={openCreateNotification}
              style={{ margin: "14px 0px" }}
            >
              <span>{"Add Notification"}</span>
            </Button>
          </Grid>
        </Grid>
        <CreateNotification
          notificationsIdData={notificationsIdData}
          setShowRoleDialog={setState}
          openDialog={state.showRoleDialog}
          type={state.create}
        />
      </Paper>
      <Paper style={{ padding: "24px", marginTop: "24px" }}>
        <TableComponent
          count={totalNotificationCount}
          totalRecordsCount={totalNotificationCount}
          headCells={headCells}
          tableData={transformData}
          onPageChange={handleChangePage}
          rowsPerPage={notificationListRowsPerPage}
          rowsPerPageChangeHandler={handleChangeRowsPerPage}
          pageIndex={notificationListPageIndex}
        />
      </Paper>
    </div>
  );
};

export default ItemNotification;
