import {
  RESET_STATE,
  GET_ALL_TRANSFERS_LISTINGS,
  GET_SINGLE_TRANSFER_INFO,
  RESET_TRANSFER_TABLE,
  OVERRIDE_TRANSFER_ID,
  SET_END_DATE_TRANSFERSLISTING,
  SET_START_DATE_TRANSFERSLISTING,
  SET_ACH_RETURN_UPDATE_STATUS,
} from "../constants/ActionTypes";
import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  ACTIVE_FILTER_DROPDOWN,
} from "../util/Utility";

const initState = {
  // account listing info
  tranferListingData: [],
  tranferListingRecordsCount: 0,
  tranferListingTotalRecordsCount: 0,
  tranferListingSearchTerm: "",
  tranferListingTablePageIndex: 0,
  tranferListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  tranferListingOrderBy: "Created",
  tranferListingOrder: "desc",
  transferListingFilter: FILTER_OPTIONS.SHOWALL,
  transferStatusFilter: [],
  transferMovementTypeFilter: [],
  transferACHWatchlistTypeFilter: [],
  startDate: null,
  endDate: null,
  isActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,

  // Single bank account
  transferData: "",

  // ACH Return update status
  achReturnUpdateStatus: "",
};

const transferListing = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_ALL_TRANSFERS_LISTINGS: {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateDataHelper = {
            tranferListingSearchTerm: action.stateData,
            tranferListingTablePageIndex: 0,
          };

          break;

        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            tranferListingTablePageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            tranferListingRowsPerPage: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateDataHelper = {
            transferStatusFilter: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.MOVEMENT_FILTER: {
          stateDataHelper = {
            transferMovementTypeFilter: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACH_WATCHLIST_FILTER: {
          stateDataHelper = {
            transferACHWatchlistTypeFilter: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            tranferListingOrderBy: orderData[0],
            tranferListingOrder: orderData[1],
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX:
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            transferListingFilter: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            startDate: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            endDate: action.stateData,
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            isActiveFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            tranferListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ACH_WITHDRAWAL_PRESET: {
          stateDataHelper = {
            transferStatusFilter: ["Pending"],
            transferMovementTypeFilter: ["BankWithdraw"],
            transferACHWatchlistTypeFilter: [
              "AccountsWithin90Days",
              "MultipleBanks",
              "NoTrades",
            ],
            tranferListingTablePageIndex: 0,
          };
          break;
        }

        default:
          break;
      }

      return {
        ...state,
        tranferListingData: action.data,
        tranferListingRecordsCount: action.totalMatch,
        tranferListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    case GET_SINGLE_TRANSFER_INFO: {
      return { ...state, transferData: action.payload };
    }

    case RESET_TRANSFER_TABLE: {
      return { ...initState };
    }

    case OVERRIDE_TRANSFER_ID: {
      let listingData = [...state.tranferListingData];
      let index = listingData.findIndex(
        (x) => x.TransferId === action.payload.TransferId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, tranferListingData: listingData };
    }

    case SET_END_DATE_TRANSFERSLISTING: {
      return { ...state, endDate: action.payload };
    }

    case SET_START_DATE_TRANSFERSLISTING: {
      return { ...state, startDate: action.payload };
    }

    case RESET_STATE: {
      return { ...initState };
    }

    case SET_ACH_RETURN_UPDATE_STATUS: {
      return { ...state, achReturnUpdateStatus: action.payload };
    }
    default:
      return state;
  }
};

export default transferListing;
