import {
  GET_ALL_SYMBOLS_DATA,
  GET_SYMBOLS_DATA_COUNT,
  GET_SYMBOLS_DATA_PAGE_INDEX,
  GET_SYMBOLS_DATA_SEARCH,
  GET_SYMBOLS_ID_DATA,
  GET_SYMBOLS_ROW_PER_PAGE,
} from "../constants/ActionTypes";
import { APP_DEFAULTS } from "../util/Utility";

const initState = {
  searchedSymbolsData: [],
  allSymbolData: [],
  symbolIdData: {},
  symbolListingTablePageIndex: 0,
  symbolListingRowsPerPage: APP_DEFAULTS.TABLE_PAGE_SIZE_10,
  symbolListingRecordsCount: 0,
};

const symbols = (state = initState, action) => {
  switch (action.type) {
    case GET_SYMBOLS_DATA_SEARCH:
      return { ...state, searchedSymbolsData: action.payload };
    case GET_ALL_SYMBOLS_DATA:
      return { ...state, allSymbolData: action.payload };
    case GET_SYMBOLS_ROW_PER_PAGE:
      return { ...state, symbolListingRowsPerPage: action.payload };
    case GET_SYMBOLS_DATA_COUNT:
      return { ...state, symbolListingRecordsCount: action.payload };
    case GET_SYMBOLS_DATA_PAGE_INDEX:
      return { ...state, symbolListingTablePageIndex: action.payload };
    case GET_SYMBOLS_ID_DATA:
      return { ...state, symbolIdData: action.payload };
    default:
      return state;
  }
};

export default symbols;
