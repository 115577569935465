import {
  SET_RECONCILIATION_REPORT_TRADE_REPORT_DATA,
  SET_RECONCILIATION_REPORT_POSITIONS_REPORT_DATA,
  SET_RECONCILIATION_REPORT_ACCOUNT_BALANCE_REPORT_DATA,
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  EVS_FILTER_DROPDOWN,
  BROKER_FILTER_DROPDOWN,
  CONFIRMED_EMAIL_FILTER_DROPDOWN,
  AFFILIATE_FILTER_DROPDOWN,
  STATUS_FILTER_DROPDOWN,
  ACTIVE_FILTER_DROPDOWN,
} from "../util/Utility";

const initState = {
  // Trade report data
  reconciliationReportTradeData: [],
  reconciliationReportTradeOrderByColumn: "Data",
  reconciliationReportTradeOrder: "desc",
  reconciliationReportTradeTablePageIndex: 0,
  reconciliationReportTradeRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  reconciliationReportTradeRecordsCount: 0,
  reconciliationReportTradeTotalRecordsCount: 0,
  reconciliationReportTradeSearchTerm: "",
  reconciliationReportTradeStartDate: null,
  reconciliationReportTradeEndDate: null,

  // Position report data
  reconciliationReportPositionData: [],
  reconciliationReportPositionOrderByColumn: "Source",
  reconciliationReportPositionOrder: "desc",
  reconciliationReportPositionTablePageIndex: 0,
  reconciliationReportPositionRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  reconciliationReportPositionRecordsCount: 0,
  reconciliationReportPositionTotalRecordsCount: 0,
  reconciliationReportPositionSearchTerm: "",
  reconciliationReportPositionStartDate: null,
  reconciliationReportPositionEndDate: null,

  // Account balance report data
  reconciliationReportAccountBalanceData: [],
  reconciliationReportAccountBalanceOrderByColumn: "Name",
  reconciliationReportAccountBalanceOrder: "asc",
  reconciliationReportAccountBalanceTablePageIndex: 0,
  reconciliationReportAccountBalanceRowsPerPage:
    APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  reconciliationReportAccountBalanceRecordsCount: 0,
  reconciliationReportAccountBalanceTotalRecordsCount: 0,
  reconciliationReportAccountBalanceSearchTerm: "",
  reconciliationReportAccountBalanceStartDate: null,
  reconciliationReportAccountBalanceEndDate: null,

  // filters
  complianceListingFilter: FILTER_OPTIONS.SHOWALL,
  complianceActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,
  complianceEvsFilter: EVS_FILTER_DROPDOWN.NONE,
  complianceBrokerFilter: BROKER_FILTER_DROPDOWN.NONE,
  emailConfirmedFilter: CONFIRMED_EMAIL_FILTER_DROPDOWN.BOTH,
  accountStatusFilter: STATUS_FILTER_DROPDOWN.NONE,
  affiliateIdFilter: AFFILIATE_FILTER_DROPDOWN.ALL,
};

const reconciliationReport = (state = initState, action) => {
  switch (action.type) {
    case SET_RECONCILIATION_REPORT_TRADE_REPORT_DATA: {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            reconciliationReportTradeSearchTerm: action.stateData,
            reconciliationReportTradeTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            reconciliationReportTradeStartDate: action.stateData,
            reconciliationReportTradeTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            reconciliationReportTradeEndDate: action.stateData,
            reconciliationReportTradeTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            reconciliationReportTradeTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            reconciliationReportTradeRowsPerPage: action.stateData,
            reconciliationReportTradeTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateDataHelper = {
            reconciliationReportTradeOrderByColumn: action.orderBy,
            reconciliationReportTradeOrder: action.order,
            reconciliationReportTradeTablePageIndex: 0,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        reconciliationReportTradeData: action.payload,
        reconciliationReportTradeRecordsCount: action.totalMatch,
        reconciliationReportTradeTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    case SET_RECONCILIATION_REPORT_POSITIONS_REPORT_DATA: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            reconciliationReportPositionSearchTerm: action.stateData,
            reconciliationReportPositionTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            reconciliationReportPositionStartDate: action.stateData,
            reconciliationReportPositionTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            reconciliationReportPositionEndDate: action.stateData,
            reconciliationReportPositionTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            reconciliationReportPositionTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            reconciliationReportPositionRowsPerPage: action.stateData,
            reconciliationReportPositionTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateDataHelper = {
            reconciliationReportPositionOrderByColumn: action.orderBy,
            reconciliationReportPositionTablePageIndex: 0,
            reconciliationReportPositionOrder: action.order,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        reconciliationReportPositionData: action.payload,
        reconciliationReportPositionRecordsCount: action.totalMatch,
        reconciliationReportPositionTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    case SET_RECONCILIATION_REPORT_ACCOUNT_BALANCE_REPORT_DATA: {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            complianceAccountBalanceSearchTerm: action.stateData,
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            complianceAccountBalanceStartDate: action.stateData,
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            complianceAccountBalanceEndDate: action.stateData,
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            reconciliationReportAccountBalanceTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            reconciliationReportAccountBalanceRowsPerPage: action.stateData,
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateDataHelper = {
            reconciliationReportAccountBalanceOrderByColumn: action.orderBy,
            reconciliationReportAccountBalanceTablePageIndex: 0,
            reconciliationReportAccountBalanceOrder: action.order,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            reconciliationReportAccountBalanceTablePageIndex: 0,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        reconciliationReportAccountBalanceData: action.payload,
        reconciliationReportAccountBalanceRecordsCount: action.totalMatch,
        reconciliationReportAccountBalanceTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }
    default:
      return state;
  }
};

export default reconciliationReport;
