import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Compliance = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/compliance`} />
        <Route
          path={`${match.url}/affiliations`}
          component={asyncComponent(() => import("./routes/Affiliations"))}
        />
        <Route
          path={`${match.url}/negativesettledcashreport`}
          component={asyncComponent(() => import("./routes/Reports/NegativeSettledCashReport"))}
        />
         <Route
          path={`${match.url}/RestrictedAccounts`}
          component={asyncComponent(() => import("./routes/RestrictedAccounts"))}
        />
         <Route
          path={`${match.url}/RestrictedPermissions`}
          component={asyncComponent(() => import("./routes/RestrictedPermissions"))}
        />
         <Route
          path={`${match.url}/AchWatchlist`}
          component={asyncComponent(() => import("./routes/AchWatchlist"))}
        />
        {/* <Route
          path={`${match.url}/compliance`}
          component={asyncComponent(() => import("./routes/Compliance"))}
        />
        <Route
          path={`${match.url}/affiliations`}
          component={asyncComponent(() => import("./routes/Affiliations"))}
        /> */}
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Compliance;
