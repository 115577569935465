import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_GENERATE_USER_ASSET_REPORT_DATA,
} from "../constants/ActionTypes";
import { LISTING_REQ_ORIGIN } from "../util/Utility";
import axios from "util/Api";

export const sKey = "c9ca1b82-af1f-4c01-9f1e-aa982957ae3f1";

export const fetchGenerateUserAssetReport = (startDate, endDate) => (
  dispatch
) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  const filterStartDate = startDate ? `startDate=${startDate}` : "";
  const filterEndDate = endDate ? `endDate=${endDate}` : "";
  let filterDate;
  if (filterStartDate && filterEndDate) {
    filterDate = `?${filterStartDate}` + `&${filterEndDate}`;
  } else if (filterStartDate) {
    filterDate = `?${filterStartDate}`;
  } else if (filterEndDate) {
    filterDate = `?${filterEndDate}`;
  } else {
    filterDate = "";
  }

  let url = `/api/admin/user-asset-report${filterDate}`;

  axios
    .get(url)
    .then(({ data }) => {
      dispatch({
        type: SET_GENERATE_USER_ASSET_REPORT_DATA,
        payload: data,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured when fetching OnBoarding Status data. ${error}`,
      });
    });
};
