import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateSymbols } from "../../../../actions/Symbol";

const EditSymbol = ({ setShowRoleDialog, openDialog, symbolData }) => {
  const dispatch = useDispatch();

  const [data, setData] = useState(symbolData);
  const handleCancel = () => {
    setShowRoleDialog({ showRoleDialog: false });
  };

  function setSymbolData(symbolData) {
    setData(symbolData);
  }

  useEffect(() => {
    setSymbolData(symbolData);
  }, [symbolData]);

  const submitForm = () => {
    dispatch(updateSymbols(data));
    setShowRoleDialog({ showRoleDialog: false });
  };
  return (
    <>
      <Dialog open={openDialog} onClose={handleCancel}>
        <DialogTitle>Update ({symbolData?.symbol})</DialogTitle>

        <DialogContent
          style={{
            width: "500px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DropDown
                  label={"IsEnabled"}
                  options={[
                    {
                      key: true,
                      value: "True",
                    },
                    {
                      key: false,
                      value: "False",
                    },
                  ]}
                  value={data.isEnabled}
                  handleChange={(e) => {
                    const newValue = e.target.value === "true";
                    setData({ ...data, isEnabled: newValue });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DropDown
                  label={"IsFractionable"}
                  options={[
                    {
                      key: true,
                      value: "True",
                    },
                    {
                      key: false,
                      value: "False",
                    },
                  ]}
                  value={data.isFractionable}
                  handleChange={(e) => {
                    const newValue = e.target.value === "true";
                    setData({
                      ...data,
                      isFractionable: newValue,
                    });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DropDown
                  label={"IsBlacklist"}
                  options={[
                    {
                      key: true,
                      value: "True",
                    },
                    {
                      key: false,
                      value: "False",
                    },
                  ]}
                  value={data.isBlacklist}
                  handleChange={(e) => {
                    const newValue = e.target.value === "true";
                    setData({ ...data, isBlacklist: newValue });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DropDown
                  label={"IsWhitelist"}
                  options={[
                    {
                      key: true,
                      value: "True",
                    },
                    {
                      key: false,
                      value: "False",
                    },
                  ]}
                  value={data.isWhitelist}
                  handleChange={(e) => {
                    const newValue = e.target.value === "true";
                    setData({ ...data, isWhitelist: newValue });
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <DropDown
                  label={"IsSmartfolioDisabled"}
                  options={[
                    {
                      key: true,
                      value: "True",
                    },
                    {
                      key: false,
                      value: "False",
                    },
                  ]}
                  value={data.isSmartfolioDisabled}
                  handleChange={(e) => {
                    const newValue = e.target.value === "true";
                    setData({ ...data, IsSmartfolioDisabled: newValue });
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel()} color="secondary">
            Cancel
          </Button>
          <Button onClick={submitForm} color="primary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export function DropDown({ label, value, handleChange, options, helperText }) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {label}
        </InputLabel>
        <NativeSelect value={value} onChange={handleChange}>
          {options?.map((e) => {
            return (
              <option key={e.key} value={e.key}>
                {e.value}
              </option>
            );
          })}
        </NativeSelect>
        <p>{helperText}</p>
      </FormControl>
    </>
  );
}

export default EditSymbol;
