import axios from "axios";
import {
  getAccessToken,
  getRefreshToken,
  setTokens,
  clearTokens,
  getUserServer,
} from "./localStorageService";
import { USER_SERVER, FLIP_SERVER_LIST, PRICE_SERVER_LIST } from "./Utility";
import { USER_DATA } from "../constants/ActionTypes";

const userServer = getUserServer();

export const baseURL =
  FLIP_SERVER_LIST[
    `${Object.keys(USER_SERVER).find((key) => USER_SERVER[key] === userServer)}`
  ];
export const basePriceURL =
  PRICE_SERVER_LIST[
    `${Object.keys(USER_SERVER).find((key) => USER_SERVER[key] === userServer)}`
  ];
export const getBasePriceURL = (server) => {
  return PRICE_SERVER_LIST[
    `${Object.keys(USER_SERVER).find((key) => USER_SERVER[key] === server)}`
  ];
};

// Toggle to true/false, when developing or pushing code on production url.
// const isProd = process.env.NODE_ENV === "development" ? false : true;

// const getLandingUrl = () => {
//   return isProd ? "http:///" : "localhost:3000";
// };

let instance = axios.create({
  baseURL: baseURL,
  timeout: 800000,
  // headers: {
  //   "Content-Type": "application/json"
  // }
});

// Interceptor for outgoing requests
instance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["access-token"] = "Bearer " + token;
    }
    // config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// flag Implementation of Refresh token -- to be uncommented and debugged once refresh token is provided
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function(error) {
    const originalRequest = error.config;
    if (
      error.response.status &&
      error.response.status === 401 &&
      originalRequest.url === `${baseURL}/connect/token`
    ) {
      // flag -- replace this with production url
      window.location.replace(document.location.hostname);
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = getRefreshToken();
      let refreshTokenBody = new FormData();
      refreshTokenBody.append("refresh_token", refreshToken);
      refreshTokenBody.append("client_id", "flip_mobile");
      refreshTokenBody.append("grant_type", "refresh_token");
      refreshTokenBody.append(
        "scope",
        "openid email phone profile offline_access roles flipapi_api"
      );

      return axios
        .post("/connect/token", refreshTokenBody, {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
        .then((res) => {
          if (res.status === 201) {
            setTokens({
              access_token: res.data.access_token,
              refresh_token: res.data.refresh_token,
            });
            axios.defaults.headers.common["Authorization"] =
              "Bearer " + res.data.access_token;
            return axios(originalRequest);
          }
        })
        .catch((error) => {
          clearTokens();
          localStorage.removeItem(USER_DATA);
          window.location.replace(document.location.hostname);
        });
    }
    // }

    return Promise.reject(error);
  }
);

export default instance;
