import { GET_ALL_RESTRICTED_ACCOUNTS } from "../constants/ActionTypes";
import { LISTING_REQ_ORIGIN, APP_DEFAULTS } from "../util/Utility";

const initState = {
  restrictedAccountListingData: [],
  restrictedAccountListingRecordsCount: 0,
  restrictedAccountListingTotalRecordsCount: 0,
  restrictedAccountListingPageIndex: 0,
  restrictedAccountListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
};

const RestrictedAccount = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_ALL_RESTRICTED_ACCOUNTS: {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            restrictedAccountListingPageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            restrictedAccountListingRowsPerPage: action.stateData,
            restrictedAccountListingPageIndex: 0,
          };
          break;

        default:
          break;
      }

      return {
        ...state,
        restrictedAccountListingData: action.data,
        restrictedAccountListingRecordsCount: action.totalMatch,
        restrictedAccountListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    default:
      return state;
  }
};

export default RestrictedAccount;
