import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
  Checkbox,
  Grid,
  FormGroup,
  InputLabel,
  NativeSelect,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  createNotifications,
  getNotificationsOptions,
  updateNotifications,
} from "../../../../actions/notification";
import { useDispatch, useSelector } from "react-redux";
import { GET_ID_NOTIFICATION } from "../../../../constants/ActionTypes";

const useStyles = makeStyles((theme) => ({
  customScrollbar: {
    width: "10px",
    marginRight: "5px",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 5px grey",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#c1c1c1",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#c1c1c1",
    },
  },
}));

export default function CreateNotification({
  setShowRoleDialog,
  openDialog,
  notificationsIdData = null,
  type,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dropdownOptions = useSelector((state) => state.notification);
  const { notificationsOptionData } = dropdownOptions;

  const intialState = {
    title: "",
    description: "",
    startDate: null,
    endDate: null,
    actionType: "",
    targetAudience: "",
    hideAllowAfter: "",
    routeTypes: "",
    inApp: null,
    pushNotification: null,
    externalUrl: "",
    routeParams: "",
    notificationType: null,
    textContent: "",
    htmlContent: "",
    modalGraphicUrl: "",
    colorOfBanner: "",
    colorSequence: Number,
    icon: "",
    actionText: "",
    modalActionText: "",
    dismissText: "",
  };

  const initialValidate = {
    title: false,
    description: false,
    actionType: false,
    externalUrl: false,
    routeParams: false,
    textContent: false,
    modalGraphicUrl: false,
    htmlContent: false,
    route: false,
    endDate: false,
    startDate: false,
    colorOfBanner: false,
    icon: false,
  };

  const [data, setData] = useState(intialState);
  const [validate, setValidate] = useState(initialValidate);

  const openForm = data.inApp === true || data.pushNotification === true;

  function handleCancel() {
    setShowRoleDialog({ showRoleDialog: false });
    setData(intialState);
    setValidate(initialValidate);
    dispatch({
      type: GET_ID_NOTIFICATION,
      payload: null,
    });
  }

  const validateFuntion = () => {
    const pushEndDate =
      data.inApp === false && data.pushNotification === true ? false : true;

    if (data.title === "") {
      setValidate({ ...validate, title: true });
      return false;
    } else if (data.description === "" || data.description.length < 11) {
      setValidate({ ...validate, description: true });
      return false;
    } else if (
      data.startDate === "" ||
      data.startDate === null ||
      data.startDate === undefined
    ) {
      setValidate({ ...validate, startDate: true });
      return false;
    } else if (pushEndDate && (data.endDate === null || data.endDate === "")) {
      setValidate({ ...validate, endDate: true });
      return false;
    } else if (
      data.colorOfBanner === "" ||
      data.colorOfBanner === null ||
      data.colorOfBanner === undefined ||
      Number.isNaN(data.colorOfBanner)
    ) {
      setValidate({ ...validate, colorOfBanner: true });
      return false;
    } else if (
      data.icon === "" ||
      data.icon === null ||
      data.icon === undefined ||
      Number.isNaN(data.icon)
    ) {
      setValidate({ ...validate, icon: true });
      return false;
    } else if (data.actionType === "") {
      setValidate({ ...validate, actionType: true });
      return false;
    } else if (
      data.actionType === "routeInternally" &&
      (data.routeTypes === "" || data.routeTypes === null)
    ) {
      setValidate({ ...validate, route: true });
      return false;
    } else if (
      data.actionType === "routeInternally" &&
      (data.routeParams === "" || data.routeParams === null)
    ) {
      setValidate({ ...validate, routeParams: true });
      return false;
    } else if (
      data.actionType === "routeExternally" &&
      (data.externalUrl === "" || data.externalUrl === null)
    ) {
      setValidate({ ...validate, externalUrl: true });
      return false;
    } else if (
      data.actionType === "halfScreenTextModal" &&
      (data.textContent === "" || data.textContent === null)
    ) {
      setValidate({ ...validate, textContent: true });
      return false;
    } else if (
      data.actionType === "fullScreenHtmlModal" &&
      (data.htmlContent === "" || data.htmlContent === null)
    ) {
      setValidate({ ...validate, htmlContent: true });
      return false;
    } else if (
      data.actionType === "halfScreenTextModal" &&
      (data.modalGraphicUrl === "" || data.modalGraphicUrl === null)
    ) {
      setValidate({ ...validate, modalGraphicUrl: true });
      return false;
    } else {
      return true;
    }
  };

  function submitForm() {
    const fncData = validateFuntion();
    if (fncData === true) {
      if (notificationsIdData === null) {
        dispatch(createNotifications(data));
        setData(intialState);
        setValidate(initialValidate);
        setShowRoleDialog({ showRoleDialog: false });
      } else {
        dispatch(
          updateNotifications(notificationsIdData.notificationMasterId, data)
        );
        setData(intialState);
        setValidate(initialValidate);
        setShowRoleDialog({ showRoleDialog: false });
      }
    }
  }

  function objectToArray(obj) {
    if (obj !== undefined && obj !== null)
      return Object.keys(obj).map((key) => ({
        key: key,
        value: obj[key],
      }));
  }

  function getKeyByValue(val, selectable_options) {
    let returnValue = "";
    selectable_options.filter((e) => {
      if (e.value === val) {
        returnValue = e.key;
      }
    });
    return returnValue;
  }

  const convertApiValueToSelectableKey = (type, apiVal) => {
    let returnValue = "";
    if (
      type === "ActionType" &&
      apiVal &&
      notificationsOptionData?.actionTypes
    ) {
      const optionArray = objectToArray(notificationsOptionData?.actionTypes);
      returnValue = getKeyByValue(apiVal, optionArray);
    }
    if (
      type === "TargetAudience" &&
      apiVal &&
      notificationsOptionData?.targetAudienceTypes
    ) {
      const optionArray = objectToArray(
        notificationsOptionData?.targetAudienceTypes
      );
      returnValue = getKeyByValue(apiVal, optionArray);
    }
    if (type === "Route" && apiVal && notificationsOptionData?.routeTypes) {
      const optionArray = objectToArray(notificationsOptionData?.routeTypes);
      returnValue = getKeyByValue(apiVal, optionArray);
    }
    if (type === "Color" && apiVal && notificationsOptionData?.colorTypes) {
      const optionArray = objectToArray(notificationsOptionData?.colorTypes);
      returnValue = getKeyByValue(apiVal, optionArray);
    }

    return returnValue;
  };

  function lowerCaseFirstLetter(string) {
    if (string !== null || string !== undefined) {
      return string?.charAt(0)?.toLowerCase() + string?.slice(1);
    }
    return string;
  }

  function setFormData() {
    setData({
      ...data,
      title: notificationsIdData?.title ?? "",
      description: notificationsIdData?.description ?? "",
      startDate: notificationsIdData?.startDateUtc
        ? moment(notificationsIdData?.startDateUtc).format("MM/DD/YYYY")
        : null,
      endDate: notificationsIdData?.endDateUtc
        ? moment(notificationsIdData?.endDateUtc).format("MM/DD/YYYY")
        : null,
      // selectable
      actionType: convertApiValueToSelectableKey(
        "ActionType",
        notificationsIdData?.actionString ?? ""
      ),
      targetAudience: convertApiValueToSelectableKey(
        "TargetAudience",
        notificationsIdData?.targetAudienceString ?? ""
      ),
      // selectable
      hideAllowAfter: notificationsIdData?.hideAllowedAfter ?? "",
      // selectable
      routeTypes: convertApiValueToSelectableKey(
        "Route",
        notificationsIdData?.route ?? ""
      ),
      inApp:
        notificationsIdData?.notificationType === "InApp" ||
        notificationsIdData?.notificationType === "Both"
          ? true
          : false,
      pushNotification:
        notificationsIdData?.notificationType === "Push" ||
        notificationsIdData?.notificationType === "Both"
          ? true
          : false,
      externalUrl: notificationsIdData?.externalUrl ?? null,
      routeParams: notificationsIdData?.routeParams ?? null,
      colorOfBanner: lowerCaseFirstLetter(notificationsIdData?.color),
      colorSequence: notificationsIdData?.sequence ?? null,
      icon: lowerCaseFirstLetter(notificationsIdData?.icon),
      actionText: notificationsIdData?.actionText ?? null,
      modalActionText: notificationsIdData?.modalActionText ?? null,
      dismissText: notificationsIdData?.modalDismissText ?? null,
      textContent: notificationsIdData?.textContent ?? null,
      htmlContent: notificationsIdData?.htmlContent ?? null,
      modalGraphicUrl: notificationsIdData?.modalGraphicUrl ?? null,
    });
  }

  function setActionWithCleanUnusedState(e) {
    if (e.target.value === "routeExternally") {
      setData({
        ...data,
        actionType: e.target.value,
        routeParams: "",
        routeTypes: "",
        textContent: "",
        modalGraphicUrl: "",
        htmlContent: "",
      });
    } else if (e.target.value === "routeInternally") {
      setData({
        ...data,
        actionType: e.target.value,
        externalUrl: "",
        textContent: "",
        modalGraphicUrl: "",
        htmlContent: "",
      });
    } else if (e.target.value === "halfScreenTextModal") {
      setData({
        ...data,
        actionType: e.target.value,
        routeParams: "",
        routeTypes: "",
        htmlContent: "",
        externalUrl: "",
      });
    } else if (e.target.value === "fullScreenHtmlModal") {
      setData({
        ...data,
        actionType: e.target.value,
        externalUrl: "",
        routeParams: "",
        routeTypes: "",
        textContent: "",
        modalGraphicUrl: "",
      });
    } else {
      setData({
        ...data,
        actionType: e.target.value,
        routeParams: "",
        routeTypes: "",
        textContent: "",
        modalGraphicUrl: "",
        htmlContent: "",
        externalUrl: "",
      });
    }
  }

  function setInApp() {
    if (data.inApp === false) {
      setData({
        ...data,
        inApp: !data.inApp,
        hideAllowAfter: "",
        endDate: "",
      });
    }
    setData({
      ...data,
      inApp: !data.inApp,
    });
  }

  function setPushNotification() {
    if (data.inApp === false) {
      setData({
        ...data,
        pushNotification: !data.pushNotification,
        actionText: "",
        modalActionText: "",
        dismissText: "",
      });
    }
    setData({
      ...data,
      pushNotification: !data.pushNotification,
    });
  }

  useEffect(() => {
    dispatch(getNotificationsOptions());
  }, []);

  useEffect(() => {
    setFormData();
  }, [notificationsIdData, notificationsOptionData]);

  return (
    <Dialog
      style={{
        width: "600px !important",
        height: "600px !important",
      }}
      open={openDialog}
      onClose={handleCancel}
    >
      {type === false && !notificationsIdData && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {type === true && <DialogTitle> Create Notification</DialogTitle>}
      {type === false && <DialogTitle>Edit Notification</DialogTitle>}

      <DialogContent
        className={classes.customScrollbar}
        style={{
          width: "600px",
          height: "650px",
        }}
      >
        <FormGroup
          style={{
            width: "65%",
            display: "flex",
            justifyContent: "space-between",
          }}
          row
        >
          <FormControlLabel
            control={<Checkbox onClick={setInApp} checked={data.inApp} />}
            label="In App"
          />
          <FormControlLabel
            required
            control={
              <Checkbox
                onClick={setPushNotification}
                checked={data.pushNotification}
              />
            }
            label="Push Notification"
          />
        </FormGroup>
        {openForm && (
          <div>
            <FormControl fullWidth>
              <TextField
                required
                id="Title"
                label="Title"
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                fullWidth
                helperText={validate.title === true && "This field is required"}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Description *"
                style={{ marginBottom: "15px", marginTop: "15px" }}
                value={data.description}
                onChange={(e) =>
                  setData({ ...data, description: e.target.value })
                }
                multiline
                fullWidth
                helperText={
                  validate.description === true &&
                  "Please fill this field between 10 to 240 chars atleast"
                }
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  {/* Start Date */}

                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      clearable
                      disablePast="true"
                      animateYearScrolling="true"
                      inputVariant="standard"
                      format="MM-dd-yyyy"
                      label={"Start Date *"}
                      value={data.startDate}
                      onChange={(e) => {
                        setData({ ...data, startDate: e });
                      }}
                      helperText={
                        validate.startDate === true && "This field is required"
                      }
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>

              {data.inApp !== false && data.inApp !== null && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        clearable
                        animateYearScrolling="true"
                        disablePast="true"
                        inputVariant="standard"
                        format="MM-dd-yyyy"
                        minDate={data.startDate}
                        label={"End Date *"}
                        value={data.endDate}
                        onChange={(e) => {
                          setData({ ...data, endDate: e });
                        }}
                        helperText={
                          validate.endDate === true && "This field is required"
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Color Sequence"
                    type="number"
                    value={data.colorSequence}
                    onChange={(e) =>
                      setData({ ...data, colorSequence: e.target.value })
                    }
                    fullWidth
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DropDown
                    label={"Color *"}
                    options={objectToArray(notificationsOptionData?.colorTypes)}
                    value={data.colorOfBanner}
                    handleChange={(e) => {
                      setData({ ...data, colorOfBanner: e.target.value });
                    }}
                    helperText={
                      validate.colorOfBanner === true &&
                      "This field is required"
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DropDown
                    label={"Target Audience"}
                    options={objectToArray(
                      notificationsOptionData?.targetAudienceTypes
                    )}
                    value={data.targetAudience}
                    handleChange={(e) => {
                      setData({ ...data, targetAudience: e.target.value });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DropDown
                    label={"Icon *"}
                    options={objectToArray(notificationsOptionData?.iconTypes)}
                    value={data.icon}
                    handleChange={(e) => {
                      setData({ ...data, icon: e.target.value });
                    }}
                    helperText={
                      validate.icon === true && "This field is required"
                    }
                  />
                </FormControl>
              </Grid>

              {data.inApp !== false && data.inApp !== null && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <DropDown
                      label={"Hide Allow After"}
                      options={[
                        { key: 1, value: 1 },
                        { key: 2, value: 2 },
                        { key: 3, value: 3 },
                        { key: 4, value: 4 },
                        { key: 5, value: 5 },
                        { key: 6, value: 6 },
                        { key: 7, value: 7 },
                        { key: 8, value: 8 },
                        { key: 9, value: 9 },
                        { key: 10, value: 10 },
                      ]}
                      value={data.hideAllowAfter}
                      handleChange={(e) => {
                        setData({ ...data, hideAllowAfter: e.target.value });
                      }}
                    />
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <DropDown
                    label={"Action Type *"}
                    options={objectToArray(
                      notificationsOptionData?.actionTypes
                    )}
                    value={data ? data?.actionType : "no data availble now "}
                    handleChange={(e) => {
                      setActionWithCleanUnusedState(e);
                    }}
                    helperText={
                      validate.actionType === true && "This field is required"
                    }
                  />
                </FormControl>
              </Grid>

              {data.inApp === true &&
                data.pushNotification === false &&
                (data.actionType === "routeInternally" ||
                  data.actionType === "routeExternally") && (
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Action Text"
                        value={data.actionText}
                        onChange={(e) =>
                          setData({
                            ...data,
                            actionText: e.target.value,
                          })
                        }
                        fullWidth
                      />
                    </FormControl>
                  </Grid>
                )}

              {data.inApp === true &&
                data.pushNotification === false &&
                (data.actionType === "halfScreenTextModal" ||
                  data.actionType === "fullScreenHtmlModal") && (
                  <>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Modal Action Text"
                          value={data.modalActionText}
                          onChange={(e) =>
                            setData({
                              ...data,
                              modalActionText: e.target.value,
                            })
                          }
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Dismiss Text"
                          value={data.dismissText}
                          onChange={(e) =>
                            setData({
                              ...data,
                              dismissText: e.target.value,
                            })
                          }
                          fullWidth
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

              {data.actionType === "routeInternally" && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <DropDown
                      label={"Route *"}
                      options={objectToArray(
                        notificationsOptionData?.routeTypes
                      )}
                      value={data.routeTypes}
                      handleChange={(e) => {
                        setData({ ...data, routeTypes: e.target.value });
                      }}
                      edit={notificationsIdData}
                      helperText={
                        validate.route === true && "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}

              {(data.actionType === "routeInternally" ||
                data.actionType === "Route Internally") && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Route Params*"
                      value={data.routeParams}
                      onChange={(e) =>
                        setData({ ...data, routeParams: e.target.value })
                      }
                      fullWidth
                      helperText={
                        validate.routeParams === true &&
                        "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}

              {(data.actionType === "routeExternally" ||
                data.actionType === "Route Externally") && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="External URL *"
                      value={data.externalUrl}
                      onChange={(e) =>
                        setData({ ...data, externalUrl: e.target.value })
                      }
                      fullWidth
                      helperText={
                        validate.externalUrl === true &&
                        "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}

              {(data.actionType === "fullScreenHtmlModal" ||
                data.actionType === "Full Html Modal") && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Html Content *"
                      value={data.htmlContent}
                      onChange={(e) =>
                        setData({ ...data, htmlContent: e.target.value })
                      }
                      fullWidth
                      helperText={
                        validate.htmlContent === true &&
                        "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}
              {(data.actionType === "Half Text Modal" ||
                data.actionType === "halfScreenTextModal") && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="TextContent *"
                      value={data.textContent}
                      onChange={(e) =>
                        setData({ ...data, textContent: e.target.value })
                      }
                      fullWidth
                      helperText={
                        validate.textContent === true &&
                        "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}
              {(data.actionType === "Half Text Modal" ||
                data.actionType === "halfScreenTextModal") && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Modal Graphic Url *"
                      value={data.modalGraphicUrl}
                      onChange={(e) =>
                        setData({ ...data, modalGraphicUrl: e.target.value })
                      }
                      fullWidth
                      helperText={
                        validate.modalGraphicUrl === true &&
                        "This field is required"
                      }
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleCancel()} color="secondary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary">
          {!notificationsIdData ? "Create" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function DropDown({ label, value, handleChange, options, helperText }) {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">
          {label}
        </InputLabel>
        <NativeSelect value={value} onChange={handleChange}>
          <option></option>
          {options?.map((e) => {
            return (
              <option key={e.key} value={e.key}>
                {e.value}
              </option>
            );
          })}
        </NativeSelect>
        <p>{helperText}</p>
      </FormControl>
    </>
  );
}
