import {
  SET_NegativeSettle_REPORT_DATA,
  SET_COMPLIANCE_LISTING_DATA,
  RESET_COMPLIANCE_LIST,
  SET_RESTRICTED_ACCOUNT_DATA,
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  BROKER_FILTER_DROPDOWN,
  CONFIRMED_EMAIL_FILTER_DROPDOWN,
  AFFILIATE_FILTER_DROPDOWN,
  ACTIVE_FILTER_DROPDOWN,
} from "../util/Utility";

const initState = {
  // Negative settle report data
  negativeSettleReportData: [],
  negativeSettleReportOrderByColumn: "Data",
  negativeSettleReportOrder: "desc",
  negativeSettleReportTablePageIndex: 0,
  negativeSettleReportRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  negativeSettleReportRecordsCount: 0,
  negativeSettleReportTotalRecordsCount: 0,
  negativeSettleReportSearchTerm: "",
  negativeSettleReportStartDate: null,
  negativeSettleReportEndDate: null,
  complianceListingData: [],
  complianceListingOrderByColumn: "Created",
  complianceListingOrder: "desc",
  complianceListingTablePageIndex: 0,
  complianceListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  complianceListingRecordsCount: 0,
  complianceListingTotalRecordsCount: 0,

  // filters
  complianceListingFilter: FILTER_OPTIONS.SHOWALL,
  complianceActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,
  complianceEvsFilter: [],
  complianceBrokerFilter: BROKER_FILTER_DROPDOWN.NONE,
  emailConfirmedFilter: CONFIRMED_EMAIL_FILTER_DROPDOWN.BOTH,
  accountStatusFilter: [],
  affiliateIdFilter: AFFILIATE_FILTER_DROPDOWN.ALL,
  complianceSearchTerm: "",
  complianceStartDate: null,
  complianceEndDate: null,
};

const compliance = (state = initState, action) => {
  switch (action.type) {
    case SET_COMPLIANCE_LISTING_DATA: {
      let stateDataHelper;

      switch (action.origin) {
        // Filter
        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            complianceListingFilter: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            complianceActiveFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.EVS_FILTER: {
          stateDataHelper = {
            complianceEvsFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.BROKER_FILTER: {
          stateDataHelper = {
            complianceBrokerFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.EMAIL_CONFIRMED: {
          stateDataHelper = {
            emailConfirmedFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateDataHelper = {
            accountStatusFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.AFFILIATE_FILTER: {
          stateDataHelper = {
            affiliateIdFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateDataHelper = {
            complianceSearchTerm: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            complianceStartDate: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            complianceEndDate: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            complianceListingTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            complianceListingRowsPerPage: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            complianceListingOrderByColumn: orderData[0],
            complianceListingTablePageIndex: 0,
            complianceListingOrder: orderData[1],
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        complianceListingData: action.data,
        complianceListingRecordsCount: action.totalMatch,
        complianceListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    case RESET_COMPLIANCE_LIST: {
      return {
        ...initState,
      };
    }

    case SET_NegativeSettle_REPORT_DATA: {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            negativeSettleReportSearchTerm: action.stateData,
            negativeSettleReportTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            negativeSettleReportStartDate: action.stateData,
            negativeSettleReportTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            negativeSettleReportEndDate: action.stateData,
            negativeSettleReportTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            negativeSettleReportTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            negativeSettleReportRowsPerPage: action.stateData,
            negativeSettleReportTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateDataHelper = {
            negativeSettleReportOrderByColumn: action.orderBy,
            negativeSettleReportOrder: action.order,
            negativeSettleReportTablePageIndex: 0,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        negativeSettleReportData: action.payload,
        negativeSettleReportRecordsCount: action.totalMatch,
        negativeSettleReportTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }
    case SET_RESTRICTED_ACCOUNT_DATA: {
      let stateDataHelper;

      switch (action.origin) {
        // Filter
        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            complianceListingFilter: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            complianceActiveFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.EVS_FILTER: {
          stateDataHelper = {
            complianceEvsFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.BROKER_FILTER: {
          stateDataHelper = {
            complianceBrokerFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.EMAIL_CONFIRMED: {
          stateDataHelper = {
            emailConfirmedFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateDataHelper = {
            accountStatusFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.AFFILIATE_FILTER: {
          stateDataHelper = {
            affiliateIdFilter: action.stateData,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            complianceSearchTerm: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            complianceStartDate: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            complianceEndDate: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        // Table releated
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            complianceListingTablePageIndex: action.stateData,
          };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            listingRowsPerPage: action.stateData,
            complianceListingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            lisitngOrderBy: orderData[0],
            complianceListingTablePageIndex: 0,
            listingOrder: orderData[1],
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };
          break;
        }
        default:
          stateDataHelper = {
            complianceListingTablePageIndex: 0,
          };

          break;
      }
      return {
        ...state,
        complianceListingData: action.data,
        complianceListingRecordsCount: action.totalMatch,
        complianceListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    default:
      return state;
  }
};

export default compliance;
