import { GET_RESTRICTED_PERMISSIONS } from "../constants/ActionTypes";

import { LISTING_REQ_ORIGIN, APP_DEFAULTS } from "../util/Utility";

const initState = {
  // listing info
  restrictedPermissionsListingData: [],
  restrictedPermissionsListingRecordsCount: 0,
  restrictedPermissionsListingTotalRecordsCount: 0,
  restrictedPermissionsListingTablePageIndex: 0,
  restrictedPermissionsListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  restrictedPermissionsListingOrderBy: "AccountNumber",
  restrictedPermissionsListingOrder: "asc",

  restrictedPermissionsData: "",
};

const restrictedPermissions = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_RESTRICTED_PERMISSIONS: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            restrictedPermissionsListingTablePageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            restrictedPermissionsListingRowsPerPage: action.stateData,
            restrictedPermissionsListingTablePageIndex: 0,
          };
          break;

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            restrictedPermissionsListingOrderBy: orderData[0],
            restrictedPermissionsListingOrder: orderData[1],
            restrictedPermissionsListingTablePageIndex: 0,
          };
          break;
        }

        default:
          break;
      }

      return {
        ...state,
        restrictedPermissionsListingData: action.data,
        restrictedPermissionsListingRecordsCount: action.totalMatch,
        restrictedPermissionsListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    default:
      return state;
  }
};

export default restrictedPermissions;
