import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import RolesMain from "./Roles";
import ManageUsersDialog from "./ManageUsersDialog";
import asyncComponent from "../../../util/asyncComponent";

const Roles = props => {
  const showManageUsersDialog = useSelector(
    state => state.roles.showManageUsersDialog
  );

  const authUser = useSelector(state => state.auth.authUser);

  return (
    <div>
      <>
        <Switch>
          {authUser && authUser.Claims.includes("claims.view") && (
            <Route
              path={props.match.url}
              render={() => {
                if (authUser && authUser.Claims.includes("claims.view")) {
                  return <RolesMain {...props} />;
                } else {
                  return <Redirect to="/unauthorized" />;
                }
              }}
            />
          )}
          <Route
            component={asyncComponent(() =>
              import("app/routes/extraPages/routes/404")
            )}
          />
        </Switch>
        {showManageUsersDialog && <ManageUsersDialog />}
      </>
    </div>
  );
};

export default Roles;
