import {
  RESET_STATE,
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  FIXED_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  THEME_COLOR
} from "constants/ActionTypes";

import { DARK_INDIGO, DARK_DEEP_ORANGE, DARK_DEEP_PURPLE } from "../constants/ThemeColors";
import { getThemeColor as themeFromLS } from "../util/localStorageService";
import { APP_DEFAULTS } from "../util/Utility";

const rltLocale = ["ar"];
const initialSettings = {
  navCollapsed: false,
  drawerType: FIXED_DRAWER,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  locale: {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  themeColor: getThemeColor(),
  env: getEnv() || APP_DEFAULTS.DEVELOPMENT
};

function getThemeColor() {
  const themeColor = themeFromLS();
  if (themeColor) {
    const body = document.body.classList;
    // body.remove(this.props.themeColor);
    body.remove("dark-theme");
    body.add(themeColor);
  } else {
    return DARK_INDIGO;
  }
}

function getEnv() {
  return getThemeColor() === DARK_DEEP_ORANGE
    ? APP_DEFAULTS.PRODUCTION
    :  getThemeColor() === DARK_DEEP_PURPLE ?  APP_DEFAULTS.DEVELOPMENT :  APP_DEFAULTS.STAGING;
}

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case THEME_COLOR:
      const environment =
        action.color === DARK_DEEP_ORANGE
          ? APP_DEFAULTS.PRODUCTION
          : action.color === DARK_DEEP_PURPLE ?  APP_DEFAULTS.DEVELOPMENT : APP_DEFAULTS.STAGING;
      return { ...state, themeColor: action.color, env: environment };

    case RESET_STATE:
      return { ...initialSettings };

    default:
      return state;
  }
};

export default settings;
