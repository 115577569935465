import { ACTION_STATUS } from "../util/Utility";
import { USER_CREATION_STATUS } from "../constants/ActionTypes";

const initState = {
  userAccountResponse: ACTION_STATUS.INITSTATE,
  userAccountResponseErrors: {}
};

const UserAccountsReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_CREATION_STATUS:
      return {
        ...state,
        userAccountResponse: action.payload,
        userAccountResponseErrors: action.errors
      };
    default:
      return state;
  }
};

export default UserAccountsReducer;
