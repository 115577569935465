import moment from "moment";
import "moment-timezone";
import { store } from "../MainApp";

/**  Compares arrays of primitives and returns true/false if they match/not-match
 * @param {*} arr1 - Array 1
 * @param {*} arr2 - Array 2
 */
export const compareArrays = (arr1, arr2) => {
  if (arr1 === arr2) return true;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) return false;
  }
  return true;
};

/** Note: Please do not remove the second parameter.
 * The second parameter is used for recomputing and rerendering when the time zone is changed.
 */
export const getTimeZoneAdjustedTime = (UtcTime) => {
  const state = store.getState();
  const addTime = state.app.addToUTCTime;
  const offsetHours = state.app.offsetHours;
  const timezone = state.app.userTimeZone;
  const mapped_timezone = (tzone) =>
    MOMENT_TIMEZONE_MAP[tzone.slice(10, tzone.length)];
  if (UtcTime) {
    const mappedtimezone = mapped_timezone(timezone);
    if (mappedtimezone) {
      const momentobject = moment(UtcTime).tz(mappedtimezone);
      const adjustedtime = momentobject._d.toString();
      const formattedtime = moment(adjustedtime).format("MM/DD/YYYY hh:mm A");
      return formattedtime;
    } else {
      return addTime
        ? moment(UtcTime)
            .add(offsetHours, "h")
            .format("MM/DD/YYYY hh:mm A")
        : moment(UtcTime)
            .subtract(offsetHours, "h")
            .format("MM/DD/YYYY hh:mm A");
    }
  } else {
    return "";
  }
};

/**  mapping of object keys By Value
 * @param {object} object
 * @param {string} value
 *  */

export const getKeyBYValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const getFormattedValue = (object, value) => {
  let newKey = Object.keys(object).find((key) => object[key] === value);
  return FormattedValue.get(newKey);
};

// Getting Smartfolio Names By SmartfolioId
export const smartfolioSwitch = (smartfolioId) => {
  switch (smartfolioId) {
    case 1:
      return "4Q Rotate";
    case 3:
      return "SPY+";
    case 4:
      return "unknown";
    case 5:
      return "SPY I";
    case 6:
      return "Red Oil";
    default:
      return;
  }
};

/** Sweetalert configuartion
 * @param {boolean} showSweetAlert
 * @param {string} sweetAlertType - Allowed values: 'default', 'info', 'success', 'warning', 'danger', 'error', 'input', 'custom'
 * @param {boolean} sweetAlertShowCancel
 * @param {string} sweetAlertConfirmBtnText
 * @param {string} sweetAlertConfirmBtnStyle - Recommended values: 'default', 'primary', 'link', 'info', 'success', 'warning', 'danger', 'secondary', 'outline-{variant}'
 * @param {string} sweetAlertCancelBtnText
 * @param {string} sweetAlertCancelBtnStyle - Recommended values: 'default', 'primary', 'link', 'info', 'success', 'warning', 'danger', 'secondary', 'outline-{variant}'
 * @param {string} sweetAlertTitle
 * @param {string} sweetAlertMessage
 * @param {function} sweetAlertConfirmHandler - Callback function to handle the "Confirm" button action
 * @param {function} sweetAlertCancelButtonHandler - Callback function to handler "Cancel" button action
 */
export const configureSweetAlert = (
  showSweetAlert = false,
  sweetAlertType = "default",
  sweetAlertShowCancel = true,
  sweetAlertConfirmBtnText = "Confirm",
  sweetAlertConfirmBtnStyle = "default",
  sweetAlertCancelBtnText = "Cancel",
  sweetAlertCancelBtnStyle = "default",
  sweetAlertTitle = "Your title here",
  sweetAlertMessage = "Your message here",
  sweetAlertConfirmHandler = () => {},
  sweetAlertCancelButtonHandler = () => {}
) => {
  return {
    showSweetAlert,
    sweetAlertType, // Allowed values: 'default', 'info', 'success', 'warning', 'danger', 'error', 'input', 'custom'
    sweetAlertShowCancel, // If true, the Cancel button will show.
    sweetAlertConfirmBtnText, // Default: 'OK'
    sweetAlertConfirmBtnStyle, // Recommended values: 'default', 'primary', 'link', 'info', 'success', 'warning', 'danger', 'secondary', 'outline-{variant}'
    sweetAlertCancelBtnText, // Default: 'Cancel'
    sweetAlertCancelBtnStyle, // Recommended values: 'default', 'primary', 'link', 'info', 'success', 'warning', 'danger', 'secondary', 'outline-{variant}'
    sweetAlertTitle, // Default: undefined
    sweetAlertMessage,
    sweetAlertConfirmHandler,
    sweetAlertCancelButtonHandler,
  };
};

export const debounce = (func, wait, immediate) => {
  var timeout;
  return function executedFunction() {
    var context = this;
    var args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

// checking usergovtId base64 string contains image or pdf.
export const checkBase64IsPdf = (encodedString) => {
  let isPdf = true;
  if (encodedString.length === 0) {
    isPdf = false;
    return isPdf;
  }
  const firstChar = encodedString.charAt(0);
  if (firstChar === "/" || firstChar === "i") {
    isPdf = false;
  }
  return isPdf;
};

export const APP_DEFAULTS = {
  DEFAULT_TABLE_PAGE_SIZE: 25,
  TABLE_PAGE_SIZE_10: 10,
  SECURITY_KEY: "c9ca1b82-af1f-4c01-9f1e-aa982957ae3f1",
  USER_TIMEZONE: "user_time-zone",
  OFFSET_TIME: "offset_time",
  // Production or QA Environment
  PRODUCTION: "PROD",
  STAGING: "QA",
  DEVELOPMENT: "LOCAL",
};

// App Variables and configurations
export const LISTING_REQ_ORIGIN = {
  NEW_PAGE_DATA: "NEW_PAGE_DATA",
  CHANGE_ROWSPP: "CHANGE_ROWSPP",
  ORDERED_DATA: "ORDERED_DATA",
  PAGE_CHANGE: "PAGE_CHANGE",
  UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: "UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX",
  SHOWINCOMPLETE_CHECKBOX: "SHOWINCOMPLETE_CHECKBOX",

  /** Listing filters */
  LISTING_FILTER: "LISTING_FILTER",
  ACTIVE_FILTER: "ACTIVE_FILTER",
  BANK_REVIEW_FILTER: "BANK_REVIEW_FILTER",
  STATUS_FILTER: "STATUS_FILTER",
  BROKER_FILTER: "BROKER_FILTER",
  EMAIL_CONFIRMED_FILTER: "EMAIL_CONFIRMED_FILTER",
  EVS_FILTER: "EVS_FILTER",
  ONBOARDING_FILTER: "ONBOARDING_FILTER",
  BANK_MATCH_STATUS_FILTER: "BANK_MATCH_STATUS_FILTER",
  START_DATE_FILTER: "START_DATE_FILTER",
  END_DATE_FILTER: "END_DATE_FILTER",
  SEARCH_FILTER: "SEARCH_FILTER",
  MOVEMENT_FILTER: "MOVEMENT_FILTER",
  ACH_WATCHLIST_FILTER: "ACH_WATCHLIST_FILTER",
  SMARTFOLIO_FILTER: "SMARTFOLIO_FILTER",

  ORDER_TYPE_FILTER: "ORDER_TYPE_FILTER",
  BUY_SELL_FILTER: "BUY_SELL_FILTER",
  AFFILIATE_FILTER: "AFFILIATE_FILTER",
  AUDIT_TRAIL_FILTER: "AUDIT_TRAIL_FILTER",
  STOCK_STATUS_FILTER: "STOCK_STATUS_FILTER",
  STOCK_TYPE_FILTER: "STOCK_TYPE_FILTER",
  BROKER_DOCUMENT_FILTER: "BROKER_DOCUMENT_FILTER",
  RESET_FILTER: "RESET_FILTER",

  DOCUMENT_CATEGORY_FILTER: "DOCUMENT_CATEGORY_FILTER",
  DOCUMENT_USER_VISIBILITY_FILTER: "DOCUMENT_USER_VISIBILITY_FILTER",
  DOCUMENT_VELOX_VISIBILITY_FILTER: "DOCUMENT_VELOX_VISIBILITY_FILTER",
  DOCUMENT_BROKER_FILTER: "DOCUMENT_BROKER_FILTER",

  ACH_WITHDRAWAL_PRESET: "ACH_WITHDRAWAL_PRESET",

  ORDERS_SUBMITTED_PRESET: "ORDERS_SUBMITTED_PRESET",
  ORDERS_UNSUBMITTED_PRESET: "ORDERS_UNSUBMITTED_PRESET",
  ORDERS_PROCESSED_PRESET: "ORDERS_PROCESSED_PRESET",
  ORDERS_UNPROCESSED_PRESET: "ORDERS_UNPROCESSED_PRESET",

  IS_ONLY_SELECTED_ORDERS_FILTER: "IS_ONLY_SELECTED_ORDERS_FILTER",
  ORDER_STATUS_FILTER: "ORDER_STATUS_FILTER",
  ORDER_ACTIONS_FILTER: "ORDER_ACTIONS_FILTER",
  OPTIONS_FILTER: "OPTIONS_FILTER",

  PROCESS_ORDERS: "PROCESS_ORDERS",
};

/** Filter Types
 */
export const FILTER_TYPES = {
  RECORDS_COUNT: "recordCount",
  ORDERED_DATA: "orderedData",
  PAGE_CHANGE: "pageChange",
  OVERRIDE_FILTER: "overrideFilter",
  ACTIVE_FILTER: "activeFilter",
  BANK_REVIEW_FILTER: "bankReviewFilter",
  ACCOUNT_STATUS_FILTER: "accountStatusFilter",
  BROKER_FILTER: "brokerFilter",
  EMAIL_CONFIRMED_FILTER: "emailConfirmedFilter",
  EVS_FILTER: "evsFilter",
  ONBOARDING_FILTER: "onboardingFilter",
  BANK_MATCH_STATUS_FILTER: "bankMatchStatusFilter",
  SEARCH_FILTER: "searchFilter",
  OPTIONS_FILTER: "optionsFilter",
  ORDER_ACTIONS_FILTER: "orderActionsFilter",
  END_DATE_FILTER: "endDateFilter",
  STOCK_STATUS_FILTER: "stockStatusFilter",
  STOCK_TYPE_FILTER: "stockTypeFilter",
  START_DATE_FILTER: "startDateFilter",
  LISTING_FILTER: "listingFilter",
  TRADE_STATUS_FILTER: "tradeStatusFilter",
  TRADE_SMARTFOLIO_FILTER: "tradeSmartfolioFilter",
  TRADE_TYPE_FILTER: "tradeTypeFilter",
  MOVEMENT_TYPE_FILTER: "movementTypeFilter",
  AFFILIATE_ID_FILTER: "affiliateIdFilter",
  RESET_FILTER: "resetFilter",
};

/** Trading account listing,
 * Trading account details - Trade,
 * */

export const LISTING_FILTER_DROPDOWN = {
  SHOWALL: "ShowAll",
  SHOW_Hidden: "showHidden",
  SHOW_INCOMPLETE: "ShowIncomplete",
  SHOW_NOT_HIDDEN: "ShowNotHidden",
  SHOW_ACTIVE: "ShowActive",
  SHOW_IN_ACTIVE: "ShowInActive",
};

/** Trading account listing,
 * Trading account details - trade,
 * */

export const ACTIVE_FILTER_DROPDOWN = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  ALL: "all",
};

/** Bank account listing,
 * */

export const BANK_REVIEW_FILTER_DROPDOWN = {
  ALL: "All",
  NEEDS_REVIEW: "NeedsReview",
  PENDING_USER: "PendingUser",
  REJECTED: "Rejected",
  PASSED: "Passed",
};

/** Trading account listing,
 * */

export const STATUS_FILTER_DROPDOWN = {
  APPROVED: "approved",
  PENDING: "pending",
  FAILED: "failed",
  ERROR: "error",
  REJECTED: "rejected",
  VERIFIED: "verified",
};

/** Trading account listing,
 * */

export const BROKER_FILTER_DROPDOWN = {
  SUCCESS: "success",
  SUBMITTED: "submitted",
  SENDING: "sending",
  CALLBACK_RECEIVED: "callback_received",
  DURATION_OVERRIDE: "duration_override",
  SUBMISSION_ERROR: "submission_error",
  ERROR_CODE: "error_code",
};

/** Trading account listing,
 * */

export const CONFIRMED_EMAIL_FILTER_DROPDOWN = {
  YES: "yes",
  NO: "no",
  BOTH: "both",
};

/** Trading account listing,
 * */

export const EVS_FILTER_DROPDOWN = {
  NONE: "",
  FAILED: "Failed",
  PASSED: "Passed",
  REVIEWING: "Reviewing",
};

/** Trading account listing,
 * */

export const USER_ONBOARDING_STATUS_DROPDOWN = {
  UNKNOWN: "Unknown",
  WAITING_BROKER_VERIFY: "Waiting Broker Verify",
  BROKER_VERIFY_FAILED: "Broker Verify Failed",
  BROKER_ACCOUNT_REQUIRED: "Broker Account Required",
  BANK_ACCOUNT_FAILED: "Bank Account Failed",
  BANK_ACCOUNT_PENDING: "Bank Account Pending",
  FLIP_FUNDING_REQUIRED: "Flip Funding Required",
  FLIP_FUNDING_PENDING: "Flip Funding Pending",
  ONBOARDING_COMPLETE: "Onboarding Complete",
  INVESTED: "Invested",
};

/** Trading account details - Position,
 * */
export const STOCK_STATUS_DROPDOWN = {
  OPEN: "Open",
  CLOSE: "Close",
  ALL: "All",
};

/** Trading account details - Position,
 * */
export const STOCK_TYPE_DROPDOWN = {
  MANUAL: "Manual",
  SMARTFOLIO: "Smartfolio",
  ALL: "All",
};

/** Trading account details - Trades,
 * */

export const BUY_SELL_FILTER_DROPDOWN = {
  BUY: "BUY",
  SELL: "SELL",
  ALL: "All",
};
export const SMARTFOLIO_INVEST_WITHDRAW_DROPDOWN = {
  INVEST: "INVEST",
  WITHDRAW: "WITHDRAW",
  ALL: "All",
};
/** Trading account details - Trades,
 * */

export const TRADE_STATUS_FILTER_DROPDOWN = {
  COMPLETED: "complete",
  CANCELLED: "cancelled",
  SKIPPED: "skipped",
  SENDING: "sending",
  REJECTED: "rejected",
  ONOPEN: "on open",
  NOTSENT: "NotSent_UnknownReason",
};

/** Trading account details - CashMovement,
 * */
export const CASH_MOVEMENT_TYPE_FILTER_DROPDOWN = {
  ALL: "All",
  BANK_DEPOSIT: "BankDeposit",
  BANK_WITHDRAW: "BankWithdraw",
  SMARTFOLIO_INVEST: "SmartfolioInvest",
  SMARTFOLIO_WITHDRAW: "SmartfolioWithdraw",
  FROM_BROKER: "FromBroker",
};

export const CASH_MOVEMENT_STATUS_FILTER_DROPDOWN = {
  COMPLETE: "Complete",
  PENDING: "Pending",
  ERROR: "Error",
  REJECTED: "Rejected",
  COMPLETED: "Completed",
};

export const CASH_MOVEMENT_ACH_WATCHLIST_FILTER_DROPDOWN = {
  RECENT_ACCOUNTS: "AccountsWithin90Days",
  MULTIPLE_BANKS: "MultipleBanks",
  NO_TRADES: "NoTrades",
};

/** Trading account details - History,
 * */

export const HISTORY_TRADE_STATUS_DROPDOWN = {
  COMPLETE: "Complete",
  PENDING: "Pending",
  HELD: "Held",
  ERROR: "Error",
  OnOpen: "On Open",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  SKIPPED: "Skipped",
};

/** Trading account details - History,
 * */

export const TRADE_TYPE_DROPDOWN = {
  ACH_RETURN_FEE: "ACH RETURN FEE",
  CR: "CR",
  CD: "CD",
  WITHDRAW: "Withdraw",
  ACH_RETURN: "ACH RETURN",
  CASH_JOURNAL: "Cash Journal",
  REVERSE: "Reverse",
  BUY: "Buy",
  INTREST_PAID: "Interest Paid",
  INVEST: "Invest",
  SELL: "Sell",
  DEPOSIT: "Deposit",
  DIVIDEND_PAID: "Dividend Paid",
};

/** Trading account details - Audit Trail,
 * */

export const AUDIT_TRAIL_FILTER_DROPDOWN = {
  UNKOWN: "0",
  BANK_ACCOUNT_TRANSFER: "1",
  COMPLETED_BANK_ACCOUNT_TRANSFER: "2",
  TRADE: "3",
  COMPLETED_TRADE: "4",
  SMARTFOLIO_TRANSFER: "5",
  COMPLETED_SMARTFOLIO_TRANSFER: "6",
  CASH_CHANGE: "7",
  PEMDING_SMARTFOLIO_TRANSFER: "8",
  ADMIN_PAGE: "9",
  IDENTITY_VERIFICATION: "10",
  BROKER_CASH_MOVEMENT: "11",
};

/** Trading account details - Audit Trail,
 * */
export const BANK_MATCH_STATUS = {
  Unknown: 0,
  StatementNotRequired: 1,
  StatementRequired: 2,
  StatementReceived: 3,
  Rejected: 4,
  Passed: 5,
};
export const BANK_MATCH_STATUS_UI = {
  0: "Unknown",
  1: "Statement Not Required",
  2: "Statement Required",
  3: "Statement Received",
  4: "Rejected",
  5: "Passed",
};

export const USER_BANK_MATCH_STATUS_DROPDOWN = {
  UNKNOWN: "Unknown",
  STATEMENT_NOT_REQUIRED: "Statement Not Required",
  STATEMENT_REQUIRED: "Statement Required",
  STATEMENT_RECEIVED: "Statement Received",
  REJECTED: "Rejected",
  PASSED: "Passed",
};

/** Trading account details - identity -Documnets,
 * */

export const DOCUMENT_VISIBILTY_DROPDOWN = {
  ALL: "All",
  VISIBLE: true,
  INVISIBLE: false,
};

export const BROKER_DROPDOWN = {
  Velox: "Velox",
};

export const DOCUMENT_VELOX_AVAILABLITY_DROPDOWN = {
  ALL: "All",
  AVAILABLE: true,
  MISSING: false,
};

/** Trading account details - Broker Documents,
 * */

export const BROKER_DOCUMENTS_DOCUMENT_ORGANISATION_FILTER_DROPDOWN = {
  ALL: "All",
  STATEMENT: "Statements",
  CONFIRMATION: "Confirmations",
};

export const ACTION_STATUS = {
  FAILED: "failed",
  SUCCESS: "success",
  INITSTATE: "init_state",
};

export const ROLES_ACTIONS = {
  CREATE: "create",
  UPDATE: "update",
};

export const TRADE_STATUS_DROPDOWN = {
  COMPLETE: "Complete",
  CANCELLED: "Cancelled",
  REJECTED: "Rejected",
  PENDING: "Pending",
  ALL: "All",
};

// export const BROKER_DOCUMENTS_STATEMENTTYPE_FILTER_DROPDOWN = {
//   ALL:"All",
//   DAILY_FILE: "Daily",
//   META_FILE: "Meta"
// };

export const AFFILIATE_FILTER_DROPDOWN = {
  ALL: 0,
  PUBLICLY_TRADED_COMPANY: 2,
  MEMBER_FIRM: 4,
};

export const USER_SERVER = {
  PROD: "production",
  QA: "qa",
  DEV: "dev",
  LOCAL: "local",
  DEVALPHA: "devalpha",
};

export const USER_SERVER_PREFIX = {
  0: "#",
  1: "*",
  2: "&",
  3: "%",
};

export const FLIP_SERVER_LIST = {
  PROD: "https://flipapi-prod.iflipinvest.com",
  QA: "https://flipapi-qa.iflipinvest.com",
  DEV: "https://flipapi-dev.iflipinvest.com",
  DEVALPHA: "https://flipapi-prodtest.iflipinvest.com",
  LOCAL: "https://localhost:44350",
};

export const PRICE_SERVER_LIST = {
  PROD: "https://flip-price-api-prod-01.azurewebsites.net",
  QA: "https://flipprice-qa.iflipinvest.com",
  DEV: "https://flip-price-api-n7dev.azurewebsites.net",
  DEVALPHA: "https://flipprice-prodtest.iflipinvest.com",
  LOCAL: "https://localhost:44331",
};

export const ID_TYPES = {
  0: "Not Required",
  1: "Required/Not Provided",
  2: "Driver's License",
  3: "Passport",
};

export const TRADING_ACCOUNTS_TABS = {
  Account: "Account",
  History: "History",
  Trades: "Trades",
  Positions: "Positions",
  AuditTrail: "Audit Trail",
  AuditTrailIdentity: "Audit Trail Identity",
  BankAccount: "Bank Account",
  CashMovement: "Cash Movement",
  Roles: "Roles",
  Identity: "Identity",
  Documents: "Documents",
  BrokerDocuments: "Broker Documents",
  IdentityEmployment: "Employment",
  IdentityFinancial: "Financial",
  IdentityTrustedContact: "Trusted Contact",
  UserAffiliations: "User Affiliations",
  UserDocumentUpload: "Documents",
  Restrictions: "Restrictions",
};

export const ORDERS_TABS = {
  AllOrders: "All Orders",
  AllTransfers: "All Transfers",
  AggregateAccounts: "Aggregate Accounts",
  AggregateSymbols: "Aggregate Symbols",
  AggregateActions: "Aggregate Actions",
  AllFixedOrders: "Fix Orders",
  ExecutionReports: "Execution Reports",
};

export const SYMBOL_STRATEGIES_TABS = {
  AllSymbolStrategies: "Symbol Strategies",
  EditSymbolStrategyPairs: "Symbol Strategy Pairs",
};

// ***********************************************************************************
// Accounts section variables and configurations
export const ACCOUNTS_ID_VERIFICATION_STATUS = {
  FAILED: "Failed",
  PASSED: "Passed",
  REVIEWING: "Reviewing",
};

export const ACCOUNTS_EVS_MESSAGE_MAP = {
  F: "Manual Fail",
  P: "Manual Pass",
  R: "Needs Further Review",
};

export const ANNUAL_INCOME_DROPDOWN = {
  Unknown: "0",
  Lessthan25000USD: "1",
  From25000To39999USD: "2",
  From40000To64999USD: "3",
  From65000To124999USD: "4",
  Greaterthan125000USD: "5",
};

export const ESTIMATED_NET_WORTH_DROPDOWN = {
  Unknown: "0",
  Lessthan25000USD: "1",
  From25000To49999USD: "2",
  From50000To199999USD: "3",
  From200000To499999USD: "4",
  From500000To999999USD: "5",
  From1000000To2999999USD: "6",
  Greaterthan3000000USD: "7",
};

export const INVESTABLE_LIQUID_ASSET_DROPDOWN = {
  Unknown: "0",
  Lessthan25000USD: "1",
  From20000To49999USD: "2",
  From50000To99999USD: "3",
  From100000To249999USD: "4",
  From250000To499999USD: "5",
  From500000To999999USD: "6",
  Greaterthan1000000USD: "7",
};

export const INVESTMENT_PROFILE_DROPDOWN = {
  Unknown: "0",
  AggressiveHigherRisk: "1",
  CapitalAppreciation: "2",
  CapitalPreservation: "3",
};

export const STOCK_INVESTMENT_KNOWLEDGE_DROPDOWN = {
  UnderOne: "1",
  OneToFive: "2",
  SixToTen: "3",
  OverTen: "4",
};

export const EMPLOYMENT_TYPE_DROPDOWN = {
  Unknown: "0",
  Employed: "1",
  Retired: "2",
  SelfEmployed: "3",
  Unemployed: "4",
  Student: "5",
  Other: "6",
};

export const OCCUPATION_TYPE_DROPDOWN = {
  Unknown: "0",
  ProfessionalOrTechnicalOrkindredworker: "1",
  ManagerOrProprietorOrPublicofficial: "2",
  CraftsmanOrOtherskilledworker: "3",
  Unemployed: "4",
  Student: "5",
  Other: "6",
};

export const COUNTRY_TYPE_DROPDOWN = {
  CHINA: "CHINA",
  JAPAN: "JAPAN",
  RUSSIA: "RUSSIA",
  AUSTRALIA: "AUSTRALIA",
};

export const OPTIONS_DROPDOWN = {
  // INCLUDEINCOMPLETEANDTODAY: "includeincompleteandtoday",
  ONLYSMARTFOLIOS: "onlysmartfolios",
  ONLYMANUAL: "onlymanual",
};

export const CHANGE_ORDERS_TO_DROPDOWN = {
  NONE: "",
  ONOPEN: "On Open",
  ONHOLD: "On Hold",
  CANCELLED: "Cancelled",
  // SUBMITTED: "Submitted",
  // ENQUEUED: "Enqueued",
  // SENDING: "Sending",
  // ACCEPTED: "Accepted",
  // REJECTED: "Rejected",
};

export const SEARCH_ORDERS_BY_DROPDOWN = {
  ALL: "All",
  SYMBOL: "Symbol",
  SMARTFOLIO: "Smartfolio",
  ACCOUNTNUMBER: "Account Number",
  STATUS: "Status",
  ERRORMESSAGE: "Error Message",
};

export const SEARCH_FIX_ORDERS_BY_DROPDOWN = {
  ALL: "All",
  SYMBOL: "Symbol",
  ACCOUNTNUMBER: "Account",
  ORDERSYSTEMIDENTIFIER: "OrderSystemIdentifier",
  BUYSELLREQUESTID: "BuySellRequestId",
};

export const DATE_RANGE_ORDERS_BY_DROPDOWN = {
  CREATED: "Created",
  FILLTIME: "FillTime",
};

export const ORDER_STATUS_DROPDOWN = {
  COMPLETE: "Complete",
  ONOPEN: "OnOpen",
  ONHOLD: "OnHold",
  SUBMITTED: "Submitted",
  ENQUEUED: "Enqueued",
  REJECTED: "Rejected",
  CANCELLED: "Cancelled",
  SKIPPED: "Skipped",
  SUBMITTEDOTHER: "SubmittedOther",
  UNSUBMITTEDOTHER: "UnsubmittedOther",
};

export const FIX_ORDER_STATUS_DROPDOWN = {
  NEW: 0,
  QUEUED: 1,
  PENDING: 2,
  SUBMITTED: 3,
  ACCEPTED: 4,
  EXECUTED: 5,
  PARTIALFILL: 6,
  FILLED: 7,
  HOLD: 8,
  CANCELLED: 9,
  DELETED: 10,
  ERROR: 11,
  REJECTED: 12,
  ASSIGNING: 13,
  PARTIALFILLCANCELLED: 14,
  PartialFillRejected: 15,
  AUTOMATICCANCELLING: 16,
  USERCANCELLING: 17,
  TIMEINFORCECANCELLING: 18,
  CANCELREPLACING: 19,
  REPLACED: 20,
};

export const ORDERS_ACTIONS = {
  BUY: "Buy",
  SELL: "Sell",
  INVEST: "Invest",
  WITHDRAW: "Withdraw",
};

export const ORDERS_TYPES = {
  ALL: "All",
  MARKET: "Market",
  LIMIT: "Limit",
};

// ***********************************************************************************
export const FormattedValue = new Map([
  ["Unknown", ""],
  ["Lessthan25000USD", "Less than 25000 USD"],
  ["From25000To39999USD", "From 25000 To 39999 USD"],
  ["From40000To64999USD", "From 40000 To 64999 USD"],
  ["From65000To124999USD", "From 65000 To 124999 USD"],
  ["Greaterthan125000USD", "Greater than 125000 USD"],
  ["From25000To49999USD", "From 25000 To 49999 USD"],
  ["From50000To199999USD", "From 50000 To 199999 USD"],
  ["From200000To499999USD", "From 200000 To 499999 USD"],
  ["From500000To999999USD", "From 500000 To 999999 USD"],
  ["From1000000To2999999USD", "From 1000000 To 2999999 USD"],
  ["Greaterthan3000000USD", "Greater than 3000000 USD"],
  ["From20000To49999USD", "From 20000 To 49999 USD"],
  ["From50000To99999USD", "From 50000 To 99999 USD"],
  ["From100000To249999USD", "From 100000 To 249999 USD"],
  ["From250000To499999USD", "From 250000 To 499999 USD"],
  ["Greaterthan1000000USD", "Greater than 1000000 USD"],
  ["AggressiveHigherRisk", "Aggressive Higher Risk"],
  ["CapitalAppreciation", "Capital Appreciation"],
  ["CapitalPreservation", "Capital Preservation"],
  ["UnderOne", "Under One"],
  ["OneToFive", "One To Five"],
  ["SixToTen", "Six To Ten"],
  ["OverTen", "Over Ten"],
]);

export const MOMENT_TIMEZONE_MAP = {
  "Samoa Standard Time": "Pacific/Pago_Pago",
  Hawaii: "Pacific/Honolulu",
  "Marquesas Islands": "Pacific/Marquesas",
  Alaska: "America/Anchorage",
  "Eastern Canada": "America/Nipigon",
  "Atlantic Time(Canada)": "America/Halifax",
  "Newfoundland(Canada)": "America/St_Johns",
  Greenland: "America/Godthab",
  "Fernando de Noronha(Brazil)": "America/Noronha",
  "Cabo Verde Is.": "Atlantic/Cape_Verde",
  "Central Africa Time": "Africa/Maputo",
  "Central European Time": "Europe/Brussels",
  "Arabia Standard Time": "Asia/Riyadh",
  "Iran Standard Time": "Asia/Tehran",
  "Mauritius Time": "Indian/Mauritius",
  "Afghanistan Time": "Asia/Kabul",
  "Pakistan Standard Time": "Asia/Karachi",
  "Indian Standard Time": "Asia/Kolkata",
  "Nepal Time": "Asia/Kathmandu",
  "Bangladesh Standard Time": "Asia/Dhaka",
  "Myanmar Standard Time": "Asia/Yangon",
  "IndoChina Time": "Asia/Bangkok",
  "China Standard Time": "Asia/Shanghai",
  "Japan Standard Time": "Asia/Tokyo",
  "Lord Howe Standard Time": "Australia/Lord_Howe",
  "New Caledonia Time": "Pacific/Noumea",
  "Tonga Time": "Pacific/Tongatapu",
  "Line Islands Time": "Pacific/Kiritimati",
  "Chatham Standard Time": "Pacific/Chatham",
  "Fiji Time": "Pacific/Fiji",
  "Australian Central Western Standard Time": "Australia/Eucla",
  "Australian Eastern Standard Time": "Australia/Hobart",
  "Australian Central Standard Time": "Australia/Darwin",
  "Pacific Time(US & Canada)": "America/Tijuana",
  "Mountain Time(US & Canada)": "America/Ojinaga",
  "Central Time(US & Canada)": "America/Matamoros",
  "Eastern Time(US & Canada)": "America/New_York",
};

export const TRADING_ACCOUNT_TYPE_UI = Object.freeze({
  0: "Unknown",
  1: "Individual Cash",
  2: "Individual Margin",
  3: "Joint Cash",
  4: "Joint Margin",
  5: "Traditional IRA",
  6: "Roth IRA",
  7: "Self Employed (SEP) IRA",
  8: "Inherited IRA",
  9: "Self Directed",
  10: "Trust",
  11: "Corporate",
  12: "Simple IRA",
  13: "401K",
  14: "CESA",
  15: "HSA",
  16: "Beneficiary IRA",
});

export const TIER_UI = Object.freeze({
  1: "Free",
  2: "Lite",
  3: "Advanced",
  4: "Pro",
});

export const PAYMENT_METHOD_UI = Object.freeze({
  0: "Unknown",
  1: "Debit Account",
  2: "Credit Card",
});

export const ACCOUNT_PERMISSIONS = {
  CashDeposit: {
    PermissionId: 2,
    AccountPermissionId: 1,
    DisplayName: "Cash Deposit",
  },
  CashWithdrawal: {
    PermissionId: 3,
    AccountPermissionId: 2,
    DisplayName: "Cash Withdrawal",
  },
  StockBuy: {
    PermissionId: 4,
    AccountPermissionId: 3,
    DisplayName: "Stock Buy",
  },
  StockSell: {
    PermissionId: 5,
    AccountPermissionId: 4,
    DisplayName: "Stock Sell",
  },
  SmartfolioInvest: {
    PermissionId: 6,
    AccountPermissionId: 5,
    DisplayName: "Smartfolio Invest",
  },
  SmartfolioWithdraw: {
    PermissionId: 7,
    AccountPermissionId: 6,
    DisplayName: "Smartfolio Withdraw",
  },
  SmartfolioBuy: {
    PermissionId: 8,
    AccountPermissionId: 7,
    DisplayName: "Smartfolio Buy",
  },
  SmartfolioSell: {
    PermissionId: 9,
    AccountPermissionId: 8,
    DisplayName: "Smartfolio Sell",
  },
};

export const isLastDayOfMonth = () => {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  return today.getMonth() !== tomorrow.getMonth();
};
