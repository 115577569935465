import {
  RESET_STATE,
  SET_LISTING_DATA,

  //**************************************************************/
  // Trading account listing
  //*****************************************************************/
  //Tabs
  SET_DETAILS_ACTIVE_TAB,
  SET_USER_ACCOUNT_TRADING_DETAILS_DATA,
  SET_MEMBERSHIP_INFO,
  SET_MEMBERSHIP_INFO_UPDATED_STATUS,
  RESET_MEMBERSHIP_INFO_UPDATED_STATUS,
  RESET_TRADING_ACCOUNT_DETAILS_DATA,
  SET_USER_REFERRAL_LINK,
  SET_USER_SMARTFOLIO_INFO,
  SET_WITHDRAWABLE_CASH,
  SET_TRADING_ACCOUNT_COUNT,
  //Filters
  RESET_ACCOUNT_LISTING_FILTER,
  SET_TRADING_ACCOUNT_LISTING_OVERRIDE_FILTER,
  SET_TRADING_ACCOUNT_LISTING_ACTIVE_FILTER,
  SET_TRADING_ACCOUNT_LISTING_STATUS_FILTER,
  SET_TRADING_ACCOUNT_LISTING_BROKER_FILTER,
  SET_TRADING_ACCOUNT_LISTING_EMAILCONFIRMED_FILTER,
  SET_TRADING_ACCOUNT_LISTING_EVS_FILTER,
  SET_TRADING_ACCOUNT_LISTING_ONBOARDING_FILTER,
  SET_TRADING_ACCOUNT_LISTING_BANK_MATCH_STATUS_FILTER,
  SET_TRADING_ACCOUNT_LISTING_START_DATE_FILTER,
  SET_TRADING_ACCOUNT_LISTING_END_DATE_FILTER,
  SET_TRADING_ACCOUNT_LISTING_SEARCH_FILTER,
  //********************************************************************/
  // Trading account details
  //********************************************************************/
  // Trading account listing - Positions
  SET_USER_POSITION_DATA,
  SET_USER_POSITIONS_STOCK_STATUS_FILTER,
  SET_USER_POSITIONS_STOCK_TYPE_FILTER,
  SET_USER_POSITIONS_SEARCH_FILTER,
  SET_USER_POSITIONS_START_DATE_FILTER,
  SET_USER_POSITIONS_END_DATE_FILTER,
  // Trading account listing - Trade
  SET_USER_TRADES_DATA,
  OVERRIDE_TRADES_ID_TRADING_ACCOUNT,
  SET_USER_TRADE_LISTING_FILTER,
  SET_USER_TRADE_ISACTIVE_FILTER,
  SET_USER_TRADE_ISBUYORSALE_FILTER,
  SET_USER_TRADE_STATUS_FILTER,
  SET_USER_TRADE_SMARTFOLIO_FILTER,
  SET_USER_TRADE_START_DATE_FILTER,
  SET_USER_TRADE_END_DATE_FILTER,
  SET_USER_TRADE_SEARCH_FILTER,

  // Trading Account Lising - Cash Movement
  SET_USER_BANK_TRANSFER_DATA,
  SET_USER_ACH_RETURN_UPDATE_STATUS,
  OVERRIDE_TRANSFER_ID_TRADING_ACCOUNT,
  SET_USER_CASHMOVEMENT_LISTING_FILTER,
  SET_USER_CASHMOVEMENT_ISACTIVE_FILTER,
  SET_USER_CASHMOVEMENT_STATUS_FILTER,
  SET_USER_CASHMOVEMENT_MOVEMENT_FILTER,
  SET_USER_CASHMOVEMENT_SEARCH_FILTER,
  SET_USER_CASHMOVEMENT_START_DATE_FILTER,
  SET_USER_CASHMOVEMENT_END_DATE_FILTER,
  // Trading account listing - History
  RESET_USER_ACCOUNT_HISTORY_DATA,
  SET_USER_ACCOUNT_HISTORY_DATA,
  SET_USER_ACCOUNT_HISTORY_TRADE_TYPE_FILTER,
  SET_USER_ACCOUNT_HISTORY_OPEN_DATE_FILTER,
  SET_USER_ACCOUNT_HISTORY_CLOSE_DATE_FILTER,
  SET_USER_ACCOUNT_HISTORY_TRADE_STATUS_FILTER,
  // Trading Account Details - Audit Trail
  SET_USER_AUDIT_TRAIL_DATA,
  SET_AUDIT_TRAIL_CATEGORY_FILTER,
  SET_AUDIT_TRAIL_SEARCH_FILTER,
  SET_USER_AUDIT_IDENTITY_TRAIL_DATA,

  // Trading Account Details - BankAccount
  SET_USER_BANK_ACCOUNT_DATA,
  OVERRIDE_BANKACCOUNT_ID,
  SET_USER_BANKACCOUNT_LISTING_FILTER,
  SET_USER_BANKACCOUNT_ISACTIVE_FILTER,
  SET_USER_BANKACCOUNT_REVIEW_FILTER,
  SET_USER_BANKACCOUNT_STATUS_FILTER,
  SET_USER_BANKACCOUNT_SEARCH_FILTER,
  SET_USER_BANKACCOUNT_START_DATE_FILTER,
  SET_USER_BANKACCOUNT_END_DATE_FILTER,
  SET_USER_BANK_ACCOUNT_DETAIL_BY_ID,
  IS_BANK_DETAIL_FETCHED,
  SET_USER_BANK_PROCESSING_STATUS,
  SET_USER_BANK_ACCOUNT_STATEMENT,
  IS_LISTING_DATA_FETCHED,
  SET_USER_BANK_AUDIT_TRAIL_DATA,
  // Trading Account Details - Identity
  SET_USER_IDENTITY_DATA,
  SET_USER_IDENTITY_EMPLOYMENT,
  SET_USER_IDENTITY_TRUSTED_CONTACT,
  SET_USER_IDENTITY_FINANCIALS,
  SET_USER_IDENTITY_AFFILIATIONS,
  SET_USER_ONBOARDING_DOCUMENTS,
  SET_USER_ONBOARDING_PDF_DOCUMENTS,
  SET_USER_IDENTITY_DOCUMENT_CATEGORY,
  SET_USER_IDENTITY_DOCUMENTS_LIST,
  SET_USER_IDENTITY_DOCUMENT_BROKER_FILTER,
  SET_USER_IDENTITY_DOCUMENT_CATEGORY_FILTER,
  SET_USER_IDENTITY_DOCUMENT_VELOX_VISIBILITY_FILTER,
  SET_USER_IDENTITY_DOCUMENT_USER_VISIBILITY_FILTER,
  SET_USER_IDENTITY_DOCUMENT_START_DATE_FILTER,
  SET_USER_IDENTITY_DOCUMENT_END_DATE_FILTER,
  SET_USER_IDENTITY_DOCUMENT_SEARCH_FILTER,
  SET_USER_IDENTITY_DOCUMENT_PROCESS_STATUS,
  SET_IDENTITY_PDF_DOCUMENT,
  SET_IDENTITY_UPDATE_STATUS,
  // Trading Account Details - Broker Documents
  SET_USER_BROKER_DOCUMENT_DATA,
  SET_USER_BROKER_DOCUMENT,
  SET_USER_BROKER_DOCUMENT_URL,
  SET_USER_BROKER_DOCUMENT_ORGANIZATION_FILTER,
  SET_USER_BROKER_DOCUMENT_STARTDATE_FILTER,
  SET_USER_BROKER_DOCUMENT_ENDDATE_FILTER,

  // GET
  SET_USER_ACCOUNT_HISTORY_ITEM,
  SET_USER_ROLES_DATA,
  // UPDATE
  UPDATE_USER_ACCOUNT_DATA,
  UPDATE_USER_GOVERMETNTID_IMAGE,

  // CREATE
  UPLOAD_GOVERMETNT_ID_RESPONSE,
  SET_NEWUSER_CREATE_SUCCESS,
  SET_NEWUSER_CREATE_ERROR,

  // EDIT
  SET_EDITACCOUNT_SUCCESS,
  RESET_EDITACCOUNT_SUCCESS,
  UPDATE_ACCOUNTS_LISTINGDATA,
  SET_USER_ROLES_UPDATE_STATUS,
  RESEND_EMAIL_CONFIRMATION,
  DELETE_USER_ACCOUNT_RESPONSE,
  DISABLE_USER_ACCOUNT_RESPONSE,
  ENABLE_USER_ACCOUNT_RESPONSE,
  GET_REFERAL_URL,
  SET_USER_SEARCH_RESULT,
  // Restrictive Permissions
  GET_USER_TRADINGACCOUNT_RESTRICTION,
  SET_USER_TRADINGACCOUNT_RESTRICTION,
  SET_LIST_OF_PERMISSION_GROUP,
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  ACTION_STATUS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  ACTIVE_FILTER_DROPDOWN,
  BANK_REVIEW_FILTER_DROPDOWN,
  CONFIRMED_EMAIL_FILTER_DROPDOWN,
  STOCK_STATUS_DROPDOWN,
  STOCK_TYPE_DROPDOWN,
  BROKER_DOCUMENTS_DOCUMENT_ORGANISATION_FILTER_DROPDOWN,
  CASH_MOVEMENT_TYPE_FILTER_DROPDOWN,
  DOCUMENT_VISIBILTY_DROPDOWN,
  DOCUMENT_VELOX_AVAILABLITY_DROPDOWN,
} from "../util/Utility";

const initState = {
  listingData: [], // tradingAccount listing data
  listingRecordsCount: 0,
  listingTotalRecordsCount: 0,
  listingTablePageIndex: 0,
  listingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  lisitngOrderBy: "Created",
  listingOrder: "desc",

  // Listing filter
  overrideFilter: FILTER_OPTIONS.SHOWALL,
  isActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,
  bankReviewFilter: BANK_REVIEW_FILTER_DROPDOWN.ALL,
  statusFilter: [],
  brokerFilter: [],
  emailConfirmedFilter: CONFIRMED_EMAIL_FILTER_DROPDOWN.BOTH,
  evsFilter: [],
  onboardingFilter: [],
  bankMatchStatusFilter: [],
  listingSearchTerm: "",
  startDate: null,
  endDate: null,

  // User Search Suggestions - Trading Account Details
  searchResultData: [], // Search reuslt data
  // User account data for user
  userAccountDetailsData: "",
  userAccountMembershipInfo: {},
  userMembershipInfoUpdateStatus: "init_state",
  userBranchLink: "",
  userSmartfolioInfo: [],
  withdrawableCash: 0,
  userTradingAccountCount: 1,
  // Trading account data for user
  userTradingAccountData: "",
  // Trading account history data for user
  userAccountHistoryData: [],
  userAccountHistoryDetailsRecordsCount: 0,
  userAccountHistoryDetailsTotalRecordsCount: 0,
  userAccountHistoryDetailsRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userAccountHistoryDetailsOrderBy: "created",
  userAccountHistoryDetailsOrder: "desc",
  userAccountHistoryDetailsTablePageIndex: 0,
  userAccountHistoryItem: "",
  userAccountHistoryTradeStatus: [],
  userAccountHistoryTradeType: [],
  userAccountHistoryTradeOpenDate: null,
  userAccountHistoryTradeCloseDate: null,

  // Trading account audit trail data for user
  userAuditTrailData: [],
  userAuditTrailDetailsRecordsCount: 0,
  userAuditTrailDetailsTotalRecordsCount: 0,
  userAuditTrailDetailsRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userAuditTrailDetailsOrderBy: "created",
  userAuditTrailDetailsOrder: "desc",
  userAuditTrailDetailsTablePageIndex: 0,
  userAuditTrailCategoryFilter: [],
  userAuditTrailSearchWord: "",
  userAuditTrailIdentityFilter: ["9"],

  // Trading account Identity audit trail data for user
  userIdentityAuditTrailData: [],
  userAuditTrailIdentityDetailsRecordsCount: 0,
  userAuditTrailIdentityDetailsTotalRecordsCount: 0,
  userAuditTrailIdentityDetailsRowsPerPage: APP_DEFAULTS.TABLE_PAGE_SIZE_10,
  userAuditTrailIdentityDetailsOrderBy: "created",
  userAuditTrailIdentityDetailsOrder: "desc",
  userAuditTrailIdentityDetailsTablePageIndex: 0,
  // Trading account Identity Documents Tab
  userIdentityDocumentCategories: [],
  userIdentityDocumentList: [],
  userIdentityDocProcessStatus: "",
  userIdentityDocProcessMessage: "",
  userIdentityFetchedDocument: "",
  userIdentityDocumentSearchTerm: "",
  userIdentityDocumentRecordsCount: 0,
  userIdentityDocumentTotalRecordsCount: 0,
  userIdentityDocumentRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userIdentityDocumentTablePageIndex: 0,

  userIdentityDocumentOrderBy: "StartDate",
  userIdentityDocumentOrder: "desc",
  userIdentityDocumentBrokerFilter: "",
  userIdentityDocumentCategoryFilter: [],
  userIdentityDocumentUserVisibilityFilter: DOCUMENT_VISIBILTY_DROPDOWN.ALL,
  userIdentityDocumentVeloxVisibilityFilter:
    DOCUMENT_VELOX_AVAILABLITY_DROPDOWN.ALL,
  userIdentityDocumentStartDate: null,
  userIdentityDocumentEndDate: null,
  userIdentityUpdateStatus: "",
  // Trading account position data for user
  userPositionData: [],
  userPositionsDetailsRecordsCount: 0,
  userPositionsDetailsTotalRecordsCount: 0,
  userPositionsDetailsRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userPositionsDetailsOrderBy: "created",
  userPositionsDetailsOrder: "desc",
  userPositionsDetailsTablePageIndex: 0,
  userPositionsStockStatusFilter: STOCK_STATUS_DROPDOWN.OPEN,
  userPositionsStockTypeFilter: STOCK_TYPE_DROPDOWN.ALL,
  userPositionsStartDateFilter: null,
  userPositionsEndDateFilter: null,
  userPositionSearchWord: "",

  //Trading account trades data for user
  userTradesListingData: [],
  userTradesListingRecordsCount: 0,
  userTradesListingTotalRecordsCount: 0,
  userTradesListingSearchTerm: "",
  userTradesListingTablePageIndex: 0,
  userTradesListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userTradesListingOrderBy: "CreatedDate",
  userTradesListingOrder: "desc",
  userTradesListingFilter: FILTER_OPTIONS.SHOWALL,
  userTradesStatusFilter: [],
  userTradeSmartfolioFilter: "",
  userTradesStartDate: null,
  userTradesEndDate: null,
  userTradeisActiveFilter: "",
  userTradeisBuyOrSellFilter: "",
  userTradeReturnUpdateStatus: "",

  // Trading account bank data for user
  userBankAccountData: [],
  userBankAccountDetailsRecordsCount: 0,
  userBankAccountDetailsTotalRecordsCount: 0,
  userBankAccountDetailsRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userBankAccountDetailsOrderBy: "created",
  userBankAccountDetailsOrder: "desc",
  userBankAccountDetailsTablePageIndex: 0,
  userBankAccountDetailsListingFilter: FILTER_OPTIONS.SHOWALL,
  userBankAccountDetailsIsActiveFilter: ACTIVE_FILTER_DROPDOWN.ALL,
  userBankAccountReviewFilter: BANK_REVIEW_FILTER_DROPDOWN.ALL,
  userBankAccountDetailsStatusFilter: [],
  userBankAccountDetailsStartDateFilter: null,
  userBankAccountDetailsEndDateFilter: null,
  userBankAccountDetailsSearchTerm: "",
  userBankAccountProcessStatus: "",
  userBankAccountProcessMessage: "",
  userBankAccountDetail: {},
  bankDetailFetched: false,
  userBankAccountStatement: "",
  isListingDataFetched: false,
  userBankAuditTrailData: [],
  userBankAuditTrailRecordsCount: 0,
  userBankAuditTrailTotalRecordsCount: 0,
  userBankAuditTrailTablePageIndex: 0,
  userBankAuditTrailTableRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userBankAuditTrailTableOrderBy: "Created",
  userBankAuditTrailTableOrder: "desc",

  // Trading account bank transfer histroy
  userBankTransferData: [],
  userBanktranferDetailsRecordsCount: 0,
  userBanktranferDetailsTotalRecordsCount: 0,
  userBankTranferDetailsRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userBankTranferDetailsOrderBy: "created",
  userBankTranferDetailsOrder: "desc",
  userBankTranferDetailsTablePageIndex: 0,
  userBankTranferDetailsListingFilter: FILTER_OPTIONS.SHOWALL,
  userBankTranferDetailsIsActiveFilter: ACTIVE_FILTER_DROPDOWN.ALL,
  userBankTranferDetailsStatusFilter: [],
  userBankTranferDetailsMovementFilter: CASH_MOVEMENT_TYPE_FILTER_DROPDOWN.ALL,
  userBankTranferDetailsStartDateFilter: null,
  userBankTranferDetailsEndDateFilter: null,
  userBankTranferDetailsSearchTerm: "",
  achReturnUpdateStatus: "",

  // Trading account Broker document
  userBrokerDocumentData: [],
  userBrokerDocumentFile: "",
  userBrokerDocumentUrl: "",
  userBrokerDocumentRecordsCount: 0,
  userBrokerDocumentTotalRecordsCount: 0,
  userBrokerDocumentRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  userBrokerDocumentOrderBy: "date",
  userBrokerDocumentOrder: "desc",
  userBrokerDocumentTablePageIndex: 0,
  userBrokerDocumentOrganizationFilter:
    BROKER_DOCUMENTS_DOCUMENT_ORGANISATION_FILTER_DROPDOWN.ALL,
  userBrokerDoucmentStartDate: null,
  userBrokerDoucmentEndDate: null,

  // Trading account Identity tab Employer Details
  userEmployeeDetailData: "",

  // Trading account Identity tab Trusted Contact Details
  userTrustedContactDetailData: "",

  // Trading account Identity tab Financial Details
  userFinancialDetailData: "",
  // Trading account Details - Identity - Documents
  userOnboardingDocuments: [],
  userOnboardingPdfDocument: "",
  // Trading account Identity Tab Affiliation Data
  userAffiliationData: [],
  userAffiliationRecordsCount: 0,
  userAffiliationTotalRecordsCount: 0,
  userAffiliationTablePageIndex: 0,
  userAffiliationTableRowsPerPage: APP_DEFAULTS.TABLE_PAGE_SIZE_10,

  // Roles assigned to user
  userAssigendRoles: [],
  // User identity data - govermentid image
  userIdentityImageData: "",
  // Selected tab on trading account details
  activeTabOnUserDetails: "",
  // Selected trading account userid on trading account details
  activeUserUserId: "",
  // Selected trading account id on trading account details
  activeUserTradingAccountId: 0,

  // Sweetalert response
  userRolesUpdateStatus: ACTION_STATUS.INITSTATE,
  enableAccountResponse: ACTION_STATUS.INITSTATE,
  disableAccountResponse: ACTION_STATUS.INITSTATE,
  deleteAccountResponse: ACTION_STATUS.INITSTATE,
  resendEmailResponse: ACTION_STATUS.INITSTATE,
  govermentIdUploadResponse: ACTION_STATUS.INITSTATE,

  userAccountHistorySearch: [],

  referalUrl: "",
  referalEmail: "",
  // Other States
  resendConfirmationEmail: "",

  // New Users Form
  createUserErrors: "",
  // isNewUserCreated - stores value of create new user form submission response - true for success, false for failure
  isNewUserCreated: false,
  userSelectiveRestrictions: [],
  userSelectiveRestrictionStatus: "",
  listofPermissionGroups: [],
};

const app = (state = { ...initState }, action) => {
  switch (action.type) {
    //****************************************************/
    //        Trading Account Details - Listing
    //****************************************************/

    case SET_LISTING_DATA: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateDataHelper = {
            listingSearchTerm: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = { listingTablePageIndex: action.stateData };
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            listingRowsPerPage: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            lisitngOrderBy: orderData[0],
            listingTablePageIndex: 0,
            listingOrder: orderData[1],
          };
          break;
        }
        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            overrideFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            listingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            isActiveFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.BANK_REVIEW_FILTER: {
          stateDataHelper = {
            bankReviewFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.EVS_FILTER: {
          stateDataHelper = {
            evsFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.BROKER_FILTER: {
          stateDataHelper = {
            brokerFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateDataHelper = {
            statusFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ONBOARDING_FILTER: {
          stateDataHelper = {
            onboardingFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.BANK_MATCH_STATUS_FILTER: {
          stateDataHelper = {
            bankMatchStatusFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.EMAIL_CONFIRMED: {
          stateDataHelper = {
            emailConfirmedFilter: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            startDate: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            endDate: action.stateData,
            listingTablePageIndex: 0,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        listingData: action.data,
        listingRecordsCount: action.totalMatch,
        listingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    // Trading account - Override filter
    case SET_TRADING_ACCOUNT_LISTING_OVERRIDE_FILTER: {
      return { ...state, overrideFilter: action.payload };
    }

    // Set trading account listing active filter
    case SET_TRADING_ACCOUNT_LISTING_ACTIVE_FILTER: {
      return { ...state, isActiveFilter: action.payload };
    }

    // Set trading account listing status filter
    case SET_TRADING_ACCOUNT_LISTING_STATUS_FILTER: {
      return { ...state, statusFilter: action.payload };
    }

    // Set trading account listing broker filter
    case SET_TRADING_ACCOUNT_LISTING_BROKER_FILTER: {
      return { ...state, brokerFilter: action.payload };
    }

    // Set trading account listing email confirmed filter
    case SET_TRADING_ACCOUNT_LISTING_EMAILCONFIRMED_FILTER: {
      return { ...state, emailConfirmedFilter: action.payload };
    }

    // Set trading account listing evs filter
    case SET_TRADING_ACCOUNT_LISTING_EVS_FILTER: {
      return { ...state, evsFilter: action.payload };
    }

    // Set trading account listing onboarding filter
    case SET_TRADING_ACCOUNT_LISTING_ONBOARDING_FILTER: {
      return { ...state, onboardingFilter: action.payload };
    }

    // Set trading account listing bank match status filter
    case SET_TRADING_ACCOUNT_LISTING_BANK_MATCH_STATUS_FILTER: {
      return { ...state, bankMatchStatusFilter: action.payload };
    }

    // Set trading account listing start date filter
    case SET_TRADING_ACCOUNT_LISTING_START_DATE_FILTER: {
      return { ...state, startDate: action.payload };
    }

    // Set trading account listing end date filter
    case SET_TRADING_ACCOUNT_LISTING_END_DATE_FILTER: {
      return { ...state, endDate: action.payload };
    }

    // Set trading account listing search filter
    case SET_TRADING_ACCOUNT_LISTING_SEARCH_FILTER: {
      return { ...state, listingSearchTerm: action.payload };
    }

    // Reset trading account listing data
    case RESET_ACCOUNT_LISTING_FILTER: {
      return {
        ...state,

        listingSearchTerm: "",
        overrideFilter: FILTER_OPTIONS.SHOWALL,
        startDate: null,
        endDate: null,
        isActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,
        emailConfirmedFilter: CONFIRMED_EMAIL_FILTER_DROPDOWN.BOTH,
        onboardingFilter: [],
        bankMatchStatusFilter: [],
        evsFilter: [],
        brokerFilter: [],
        statusFilter: [],
      };
    }
    // Resend Email Confirmation
    case RESEND_EMAIL_CONFIRMATION: {
      return {
        ...state,
        resendEmailResponse: action.payload,
        resendConfirmationEmail: action.resendConfirmationEmail,
      };
    }
    // Get Referral Url
    case GET_REFERAL_URL: {
      return {
        ...state,
        referalUrl: action.payload,
        referalEmail: action.referalEmail,
      };
    }
    //****************************************************/
    //        Trading Account Details
    //****************************************************/
    // User search result
    case SET_USER_SEARCH_RESULT: {
      return { ...state, searchResultData: action.payload };
    }

    // Reset trading account listing data
    case RESET_TRADING_ACCOUNT_DETAILS_DATA: {
      return {
        ...initState,
        listingData: state.listingData, // tradingAccount listing data
        listingRecordsCount: state.listingRecordsCount,
        listingTotalRecordsCount: state.listingTotalRecordsCount,
        listingSearchTerm: state.listingSearchTerm,
        listingTablePageIndex: state.listingTablePageIndex,
        listingRowsPerPage: state.listingRowsPerPage,
        lisitngOrderBy: state.lisitngOrderBy,
        listingOrder: state.listingOrder,
        overrideFilter: state.overrideFilter,
        startDate: state.startDate,
        endDate: state.endDate,
        // Listing filter
        isActiveFilter: state.isActiveFilter,
        emailConfirmedFilter: state.emailConfirmedFilter,
        evsFilter: state.evsFilter,
        brokerFilter: state.brokerFilter,
        statusFilter: state.statusFilter,
        onboardingFilter: state.onboardingFilter,
        bankMatchStatusFilter: state.bankMatchStatusFilter,
        activeTabOnUserDetails: state.activeTabOnUserDetails,
      };
    }

    // Set search tab, userid and tradingaccountId on trading account details
    case SET_DETAILS_ACTIVE_TAB: {
      return {
        ...state,
        activeTabOnUserDetails: action.activeTab,
        activeUserUserId: action.activeUserId,
        activeUserTradingAccountId: action.activeTradingAccountId,
      };
    }

    // Account tab ( User details )
    case SET_USER_ACCOUNT_TRADING_DETAILS_DATA: {
      return { ...state, userAccountDetailsData: action.payload };
    }

    // Set user account details history tab selected item
    case SET_USER_ACCOUNT_HISTORY_ITEM: {
      return { ...state, userAccountHistoryItem: action.payload };
    }

    //****************************************************/
    //        Trading Account Details - Positions
    //****************************************************/

    // Position data of user (Trading account details roles)
    case SET_USER_POSITION_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.START_DATE:
        case LISTING_REQ_ORIGIN.END_DATE:
        case LISTING_REQ_ORIGIN.STOCK_TYPE_FILTER:
        case LISTING_REQ_ORIGIN.STOCK_STATUS_FILTER:
        case LISTING_REQ_ORIGIN.SEARCH:
          stateHelper.userPositionsDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userPositionsDetailsTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userPositionsDetailsRowsPerPage = action.originValue;
          stateHelper.userPositionsDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userPositionsDetailsOrderBy = action.originValue.orderBy;
          stateHelper.userPositionsDetailsOrder = action.originValue.order;
          stateHelper.userPositionsDetailsTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userPositionData: action.payload,
        userPositionsDetailsRecordsCount: action.recordsCount,
        userPositionsDetailsTotalRecordsCount: action.totalRecordsCount,
      };
    }

    // Position data stock status filter
    case SET_USER_POSITIONS_STOCK_STATUS_FILTER: {
      return {
        ...state,
        userPositionsStockStatusFilter: action.payload,
      };
    }

    // Position data stock type filter
    case SET_USER_POSITIONS_STOCK_TYPE_FILTER: {
      return {
        ...state,
        userPositionsStockTypeFilter: action.payload,
      };
    }

    // Position search filter
    case SET_USER_POSITIONS_SEARCH_FILTER: {
      return {
        ...state,
        userPositionSearchWord: action.payload,
      };
    }

    // Position data start date filter
    case SET_USER_POSITIONS_START_DATE_FILTER: {
      return {
        ...state,
        userPositionsStartDateFilter: action.payload,
      };
    }

    // Position data start date filter
    case SET_USER_POSITIONS_END_DATE_FILTER: {
      return {
        ...state,
        userPositionsEndDateFilter: action.payload,
      };
    }

    //****************************************************/
    //        Trading Account Details - Trades
    //****************************************************/

    // trades data to user (Trading account Trade details )
    case SET_USER_TRADES_DATA: {
      let stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateHelper = {
            userTradesListingSearchTerm: action.stateData,
            userTradesListingTablePageIndex: 0,
          };

          break;

        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper = {
            userTradesListingTablePageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper = {
            userTradesListingRowsPerPage: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateHelper = {
            userTradesListingOrderBy: action.stateData.orderBy,
            userTradesListingOrder: action.stateData.order,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX:
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateHelper = {
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateHelper = {
            userTradesListingFilter: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateHelper = {
            userTradeisActiveFilter: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.BUY_SELL_FILTER: {
          stateHelper = {
            userTradeisBuyOrSellFilter: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateHelper = {
            userTradesStatusFilter: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.SMARTFOLIO_FILTER: {
          stateHelper = {
            userTradeSmartfolioFilter: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE: {
          stateHelper = {
            userTradesStartDate: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.END_DATE: {
          stateHelper = {
            userTradesEndDate: action.stateData,
            userTradesListingTablePageIndex: 0,
          };
          break;
        }

        default:
          break;
      }
      return {
        ...state,
        userTradesListingData: action.payload,
        userTradesListingRecordsCount: action.recordsCount,
        userTradesListingTotalRecordsCount: action.totalRecordsCount,
        ...stateHelper,
      };
    }

    // Trading account details - trade tab - listing filter
    case SET_USER_TRADE_LISTING_FILTER: {
      return {
        ...state,
        userTradesListingFilter: action.payload,
      };
    }

    // Trading account details - trade tab - active filter
    case SET_USER_TRADE_ISACTIVE_FILTER: {
      return {
        ...state,
        userTradeisActiveFilter: action.payload,
      };
    }

    // Trading account details - trade tab - buysell filter
    case SET_USER_TRADE_ISBUYORSALE_FILTER: {
      return {
        ...state,
        userTradeisBuyOrSellFilter: action.payload,
      };
    }

    // Trading account details - trade tab - buysell status filter
    case SET_USER_TRADE_STATUS_FILTER: {
      return {
        ...state,
        userTradesStatusFilter: action.payload,
      };
    }
    // Trading account details - trade tab - smartfolio filter
    case SET_USER_TRADE_SMARTFOLIO_FILTER: {
      return {
        ...state,
        userTradeSmartfolioFilter: action.payload,
      };
    }

    // Trading account details - trade tab - start date filter
    case SET_USER_TRADE_START_DATE_FILTER: {
      return {
        ...state,
        userTradesStartDate: action.payload,
      };
    }

    // Trading account details - trade tab - end date filter
    case SET_USER_TRADE_END_DATE_FILTER: {
      return {
        ...state,
        userTradesEndDate: action.payload,
      };
    }

    // Trading account details - trade tab - serach filter
    case SET_USER_TRADE_SEARCH_FILTER: {
      return {
        ...state,
        userTradesListingSearchTerm: action.payload,
      };
    }
    // overriding on trading account tabs
    case OVERRIDE_TRADES_ID_TRADING_ACCOUNT: {
      let listingData = [...state.userTradesListingData];
      let index = listingData.findIndex(
        (x) => x.TradeId === action.payload.TradeId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, userTradesListingData: listingData };
    }
    //****************************************************/
    //        Trading Account Details - Cash Movenent
    //****************************************************/
    case SET_USER_BANK_TRANSFER_DATA: {
      let stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userBankTranferDetailsTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userBankTranferDetailsRowsPerPage = action.originValue;
          stateHelper.userBankTranferDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userBankTranferDetailsOrderBy =
            action.originValue.orderBy;
          stateHelper.userBankTranferDetailsOrder = action.originValue.order;
          stateHelper.userBankTranferDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX:
          stateHelper.userBankTranferDetailsTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userBankTransferData: action.payload,
        userBanktranferDetailsRecordsCount: action.recordsCount,
        userBanktranferDetailsTotalRecordsCount: action.totalRecordsCount,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_LISTING_FILTER: {
      return {
        ...state,
        userBankTranferDetailsListingFilter: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_ISACTIVE_FILTER: {
      return {
        ...state,
        userBankTranferDetailsIsActiveFilter: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_STATUS_FILTER: {
      return {
        ...state,
        userBankTranferDetailsStatusFilter: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_MOVEMENT_FILTER: {
      return {
        ...state,
        userBankTranferDetailsMovementFilter: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_SEARCH_FILTER: {
      return {
        ...state,
        userBankTranferDetailsSearchTerm: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_START_DATE_FILTER: {
      return {
        ...state,
        userBankTranferDetailsStartDateFilter: action.payload,
      };
    }
    // setting Trading Account Cash Movement
    case SET_USER_CASHMOVEMENT_END_DATE_FILTER: {
      return {
        ...state,
        userBankTranferDetailsEndDateFilter: action.payload,
      };
    }

    // overriding on trading account tab - CashMovement
    case OVERRIDE_TRANSFER_ID_TRADING_ACCOUNT: {
      let listingData = [...state.userBankTransferData];
      let index = listingData.findIndex(
        (x) => x.TransferId === action.payload.TransferId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, userBankTransferData: listingData };
    }
    // setting Ach return message
    case SET_USER_ACH_RETURN_UPDATE_STATUS: {
      return { ...state, achReturnUpdateStatus: action.payload };
    }

    //****************************************************/
    //        Trading Account Details - History
    //****************************************************/

    case SET_USER_ACCOUNT_HISTORY_DATA: {
      const stateHelper = {};

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateHelper.userAccountHistoryDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userAccountHistoryDetailsTablePageIndex =
            action.originValue;
          break;
        case LISTING_REQ_ORIGIN.STATUS_FILTER:
          stateHelper.userAccountHistoryDetailsTablePageIndex = 0;
          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userAccountHistoryDetailsRowsPerPage = action.originValue;
          stateHelper.userAccountHistoryDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userAccountHistoryDetailsOrderBy =
            action.originValue.orderBy;
          stateHelper.userAccountHistoryDetailsOrder = action.originValue.order;
          stateHelper.userAccountHistoryDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.AUDIT_TRAIL_FILTER:
          stateHelper.userAccountHistoryDetailsTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userAccountHistoryData: action.payload,
        userAccountHistoryDetailsRecordsCount: action.recordsCount,
        userAccountHistoryDetailsTotalRecordsCount: action.totalRecordsCount,
      };
    }
    // resetting useraccounthistory data on trading account details tab
    case RESET_USER_ACCOUNT_HISTORY_DATA: {
      return {
        ...state,
        userAccountHistoryData: [],
      };
    }

    // History tab ( Trading account details ) Status filter
    case SET_USER_ACCOUNT_HISTORY_TRADE_STATUS_FILTER: {
      return {
        ...state,
        userAccountHistoryTradeStatus: action.payload,
      };
    }

    // History tab ( Trading account details ) Type filter
    case SET_USER_ACCOUNT_HISTORY_TRADE_TYPE_FILTER: {
      return {
        ...state,
        userAccountHistoryTradeType: action.payload,
      };
    }

    // History tab ( Trading account details ) Status filter
    case SET_USER_ACCOUNT_HISTORY_OPEN_DATE_FILTER: {
      return {
        ...state,
        userAccountHistoryTradeOpenDate: action.payload,
      };
    }

    // History tab ( Trading account details ) Type filter
    case SET_USER_ACCOUNT_HISTORY_CLOSE_DATE_FILTER: {
      return {
        ...state,
        userAccountHistoryTradeCloseDate: action.payload,
      };
    }
    //****************************************************/
    //        Trading Account Details - Audit Trail
    //****************************************************/

    case SET_USER_AUDIT_TRAIL_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateHelper.userAuditTrailDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userAuditTrailDetailsTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userAuditTrailDetailsRowsPerPage = action.originValue;
          stateHelper.userAuditTrailDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userAuditTrailDetailsOrderBy = action.originValue.orderBy;
          stateHelper.userAuditTrailDetailsOrder = action.originValue.order;
          stateHelper.userAuditTrailDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.AUDIT_TRAIL_FILTER:
          stateHelper.userAuditTrailDetailsTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userAuditTrailData: action.payload,
        userAuditTrailDetailsRecordsCount: action.recordsCount,
        userAuditTrailDetailsTotalRecordsCount: action.totalRecordsCount,
      };
    }

    // Audit trail category filter
    case SET_AUDIT_TRAIL_CATEGORY_FILTER: {
      return {
        ...state,
        userAuditTrailCategoryFilter: action.payload,
      };
    }

    //  Audit trail serach filter
    case SET_AUDIT_TRAIL_SEARCH_FILTER: {
      return {
        ...state,
        userAuditTrailSearchWord: action.payload,
      };
    }
    //****************************************************/
    //        Trading Account Details - Bank Account
    //****************************************************/

    case SET_USER_BANK_ACCOUNT_DETAIL_BY_ID: {
      return {
        ...state,
        userBankAccountDetail: action.payload,
      };
    }
    case IS_BANK_DETAIL_FETCHED: {
      return {
        ...state,
        bankDetailFetched: action.payload,
      };
    }

    case SET_USER_BANK_PROCESSING_STATUS: {
      return {
        ...state,
        userBankAccountProcessStatus: action.payload.status,
        userBankAccountProcessMessage: action.payload.message,
      };
    }

    case SET_IDENTITY_UPDATE_STATUS: {
      return {
        ...state,
        userIdentityUpdateStatus: action.payload.status,
      };
    }

    case SET_USER_BANK_ACCOUNT_STATEMENT: {
      return {
        ...state,
        userBankAccountStatement: action.payload,
      };
    }
    case IS_LISTING_DATA_FETCHED: {
      return {
        ...state,
        isListingDataFetched: action.payload,
      };
    }

    case SET_USER_BANK_AUDIT_TRAIL_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userBankAuditTrailTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userBankAuditTrailTableRowsPerPage = action.originValue;
          stateHelper.userBankAuditTrailTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userBankAuditTrailTableOrderBy =
            action.originValue.orderBy;
          stateHelper.userBankAuditTrailTableOrder = action.originValue.order;
          stateHelper.userBankAuditTrailTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userBankAuditTrailData: action.payload,
        userBankAuditTrailRecordsCount: action.recordsCount,
        userBankAuditTrailTotalRecordsCount: action.totalRecordsCount,
      };
    }

    case SET_USER_BANK_ACCOUNT_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userBankAccountDetailsTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userBankAccountDetailsRowsPerPage = action.originValue;
          stateHelper.userBankAccountDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userBankAccountDetailsOrderBy =
            action.originValue.orderBy;
          stateHelper.userBankAccountDetailsOrder = action.originValue.order;
          stateHelper.userBankAccountDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX:
          stateHelper.userBankAccountDetailsTablePageIndex = 0;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userBankAccountData: action.payload,
        userBankAccountDetailsRecordsCount: action.recordsCount,
        userBankAccountDetailsTotalRecordsCount: action.totalRecordsCount,
      };
    }

    // setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_LISTING_FILTER: {
      return {
        ...state,
        userBankAccountDetailsListingFilter: action.payload,
      };
    }
    //  setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_ISACTIVE_FILTER: {
      return {
        ...state,
        userBankAccountDetailsIsActiveFilter: action.payload,
      };
    }
    //  setting Trading Account Bank Account Review
    case SET_USER_BANKACCOUNT_REVIEW_FILTER: {
      return {
        ...state,
        userBankAccountReviewFilter: action.payload,
      };
    }
    //  setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_STATUS_FILTER: {
      return {
        ...state,
        userBankAccountDetailsStatusFilter: action.payload,
      };
    }
    //  setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_SEARCH_FILTER: {
      return {
        ...state,
        userBankAccountDetailsSearchTerm: action.payload,
      };
    }
    //  setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_START_DATE_FILTER: {
      return {
        ...state,
        userBankAccountDetailsStartDateFilter: action.payload,
      };
    }
    //  setting Trading Account Bank Account
    case SET_USER_BANKACCOUNT_END_DATE_FILTER: {
      return {
        ...state,
        userBankAccountDetailsEndDateFilter: action.payload,
      };
    }
    // Bank Accounts Tab UI Overrider
    case OVERRIDE_BANKACCOUNT_ID: {
      let listingData = [...state.userBankAccountData];
      let index = listingData.findIndex(
        (x) => x.BankAccountId === action.payload.BankAccountId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, userBankAccountData: listingData };
    }
    //****************************************************/
    //        Trading Account Details - Identity
    //****************************************************/

    // User identity Tab Image Data Setter
    case SET_USER_IDENTITY_DATA: {
      return { ...state, userIdentityImageData: action.payload };
    }
    // User Identity Tab Audit trail table data setter
    case SET_USER_AUDIT_IDENTITY_TRAIL_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateHelper.userAuditTrailIdentityDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userAuditTrailIdentityDetailsTablePageIndex =
            action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userAuditTrailIdentityDetailsRowsPerPage =
            action.originValue;
          stateHelper.userAuditTrailIdentityDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userAuditTrailIdentityDetailsOrderBy =
            action.originValue.orderBy;
          stateHelper.userAuditTrailIdentityDetailsOrder =
            action.originValue.order;
          stateHelper.userAuditTrailIdentityDetailsTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.AUDIT_TRAIL_FILTER:
          stateHelper.userAuditTrailIdentityDetailsTablePageIndex = 0;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userIdentityAuditTrailData: action.payload,
        userAuditTrailIdentityDetailsRecordsCount: action.recordsCount,
        userAuditTrailIdentityDetailsTotalRecordsCount:
          action.totalRecordsCount,
      };
    }
    // Enable user account - Get useraccountdetails after the operation
    case ENABLE_USER_ACCOUNT_RESPONSE: {
      return {
        ...state,
        enableAccountResponse: action.payload,
      };
    }
    // Disable user account - Get useraccountdetails after the operation
    case DISABLE_USER_ACCOUNT_RESPONSE: {
      return { ...state, disableAccountResponse: action.payload };
    }
    // Update user account data for the event like enable/disable user
    case UPDATE_USER_ACCOUNT_DATA: {
      return { ...state, userAccountDetailsData: action.payload };
    }
    // Update user account data for the event like delete user
    case DELETE_USER_ACCOUNT_RESPONSE: {
      return { ...state, deleteAccountResponse: action.payload };
    }
    // Upload goverment id file - response status
    case UPLOAD_GOVERMETNT_ID_RESPONSE: {
      return { ...state, govermentIdUploadResponse: action.payload };
    }
    // Reset / reupload goverment id image
    case UPDATE_USER_GOVERMETNTID_IMAGE: {
      return { ...state, userIdentityImageData: action.payload };
    }
    // Set user identity employment details
    case SET_USER_IDENTITY_EMPLOYMENT: {
      return { ...state, userEmployeeDetailData: action.payload };
    }
    case SET_USER_IDENTITY_TRUSTED_CONTACT: {
      return { ...state, userTrustedContactDetailData: action.payload };
    }
    case SET_USER_IDENTITY_FINANCIALS: {
      return { ...state, userFinancialDetailData: action.payload };
    }
    case SET_USER_IDENTITY_AFFILIATIONS: {
      let stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper = {
            userAffiliationTablePageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper = {
            userAffiliationTableRowsPerPage: action.stateData,
            userAffiliationTablePageIndex: 0,
          };
          break;
        default:
          break;
      }
      return {
        ...state,
        userAffiliationData: action.payload,
        userAffiliationRecordsCount: action.totalRecordsCount,
        userAffiliationTotalRecordsCount: action.recordsCount,
        ...stateHelper,
      };
    }
    // User Onboarding Documents Pdf Url
    case SET_USER_ONBOARDING_DOCUMENTS: {
      return {
        ...state,
        userOnboardingDocuments: action.payload,
      };
    }
    // User Onboarding Document Pdf Data
    case SET_USER_ONBOARDING_PDF_DOCUMENTS: {
      return {
        ...state,
        userOnboardingPdfDocument: action.payload,
      };
    }
    //===============================================identity Tab - Documents Tab=======================
    case SET_USER_IDENTITY_DOCUMENT_CATEGORY: {
      return {
        ...state,
        userIdentityDocumentCategories: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_PROCESS_STATUS: {
      return {
        ...state,
        userIdentityDocProcessStatus: action.payload.status,
        userIdentityDocProcessMessage: action.payload.message,
      };
    }
    case SET_IDENTITY_PDF_DOCUMENT: {
      return {
        ...state,
        userIdentityFetchedDocument: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENTS_LIST: {
      let stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userIdentityDocumentTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userIdentityDocumentRowsPerPage = action.originValue;
          stateHelper.userIdentityDocumentTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateHelper = {
            userIdentityDocumentSearchTerm: action.originValue,
          };
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          stateHelper = {
            userIdentityDocumentOrderBy: action.originValue.orderBy,
            userIdentityDocumentOrder: action.originValue.order,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.DOCUMENT_BROKER_FILTER: {
          stateHelper = {
            userIdentityDocumentBrokerFilter: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.DOCUMENT_CATEGORY_FILTER: {
          stateHelper = {
            userIdentityDocumentCategoryFilter: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.DOCUMENT_USER_VISIBILITY_FILTER: {
          stateHelper = {
            userIdentityDocumentUserVisibilityFilter: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.DOCUMENT_VELOX_VISIBILITY_FILTER: {
          stateHelper = {
            userIdentityDocumentVeloxVisibilityFilter: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateHelper = {
            userIdentityDocumentStartDate: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateHelper = {
            userIdentityDocumentEndDate: action.originValue,
          };
          break;
        }
        default:
          break;
      }
      return {
        ...state,
        userIdentityDocumentList: action.payload,
        userIdentityDocumentRecordsCount: action.recordsCount,
        userIdentityDocumentTotalRecordsCount: action.totalRecordsCount,
        ...stateHelper,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_BROKER_FILTER: {
      return {
        ...state,
        userIdentityDocumentBrokerFilter: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_CATEGORY_FILTER: {
      return {
        ...state,
        userIdentityDocumentCategoryFilter: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_USER_VISIBILITY_FILTER: {
      return {
        ...state,
        userIdentityDocumentUserVisibilityFilter: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_VELOX_VISIBILITY_FILTER: {
      return {
        ...state,
        userIdentityDocumentVeloxVisibilityFilter: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_START_DATE_FILTER: {
      return {
        ...state,
        userIdentityDocumentStartDate: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_END_DATE_FILTER: {
      return {
        ...state,
        userIdentityDocumentEndDate: action.payload,
      };
    }
    case SET_USER_IDENTITY_DOCUMENT_SEARCH_FILTER: {
      return {
        ...state,
        userIdentityDocumentSearchTerm: action.payload,
      };
    }
    //****************************************************/
    //        Trading Account Details - Broker Documents
    //****************************************************/
    case SET_USER_BROKER_DOCUMENT_DATA: {
      const stateHelper = {};
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateHelper.userBrokerDocumentTablePageIndex = action.originValue;
          break;
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateHelper.userBrokerDocumentRowsPerPage = action.originValue;
          stateHelper.userBrokerDocumentTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.ORDERED_DATA:
          stateHelper.userBrokerDocumentOrderBy = action.originValue.orderBy;
          stateHelper.userBrokerDocumentOrder = action.originValue.order;
          stateHelper.userBrokerDocumentTablePageIndex = 0;
          break;
        case LISTING_REQ_ORIGIN.BROKER_DOCUMENT_FILTER:
          stateHelper.userBrokerDocumentOrganizationFilter = action.originValue;
          break;
        default:
          break;
      }
      return {
        ...state,
        ...stateHelper,
        userBrokerDocumentData: action.payload,
        userBrokerDocumentRecordsCount: action.recordsCount,
        userBrokerDocumentTotalRecordsCount: action.totalRecordsCount,
      };
    }
    // Set broker document type on broker documents tab
    case SET_USER_BROKER_DOCUMENT: {
      return {
        ...state,
        userBrokerDocumentFile: action.payload,
      };
    }
    // Set broker meta documents file url to fetch the pdf
    case SET_USER_BROKER_DOCUMENT_URL: {
      return {
        ...state,
        userBrokerDocumentUrl: action.payload,
      };
    }
    // Set broker doucment organization filter
    case SET_USER_BROKER_DOCUMENT_ORGANIZATION_FILTER: {
      return {
        ...state,
        userBrokerDocumentOrganizationFilter: action.payload,
      };
    }
    // Set broker doucment start date filter
    case SET_USER_BROKER_DOCUMENT_STARTDATE_FILTER: {
      return {
        ...state,
        userBrokerDoucmentStartDate: action.payload,
      };
    }
    // Set broker doucment end date filter
    case SET_USER_BROKER_DOCUMENT_ENDDATE_FILTER: {
      return {
        ...state,
        userBrokerDoucmentEndDate: action.payload,
      };
    }
    // Roles assigned to user (Trading account details roles)
    case SET_USER_ROLES_DATA: {
      return { ...state, userAssigendRoles: action.payload };
    }

    case SET_USER_ROLES_UPDATE_STATUS: {
      return { ...state, userRolesUpdateStatus: action.payload };
    }

    case SET_NEWUSER_CREATE_SUCCESS: {
      return { ...state, isNewUserCreated: true, createUserErrors: "" };
    }

    case SET_NEWUSER_CREATE_ERROR: {
      return {
        ...state,
        isNewUserCreated: false,
        createUserErrors: action.payload,
      };
    }
    case SET_EDITACCOUNT_SUCCESS: {
      return { ...state, isUserEdited: true, userEditErrors: "" };
    }

    case RESET_EDITACCOUNT_SUCCESS: {
      return { ...state, isUserEdited: false, userEditErrors: "" };
    }
    case SET_NEWUSER_CREATE_ERROR: {
      return {
        ...state,
        isUserEdited: false,
        userEditErrors: action.payload,
      };
    }
    case SET_MEMBERSHIP_INFO: {
      return {
        ...state,
        userAccountMembershipInfo: action.payload,
      };
    }
    case SET_MEMBERSHIP_INFO_UPDATED_STATUS: {
      return {
        ...state,
        userMembershipInfoUpdateStatus: action.status,
      };
    }
    case RESET_MEMBERSHIP_INFO_UPDATED_STATUS: {
      return {
        ...state,
        userMembershipInfoUpdateStatus: action.status,
      };
    }
    case SET_USER_REFERRAL_LINK: {
      return {
        ...state,
        userBranchLink: action.payload,
      };
    }
    case SET_USER_SMARTFOLIO_INFO: {
      return {
        ...state,
        userSmartfolioInfo: action.payload,
      };
    }
    case SET_WITHDRAWABLE_CASH: {
      return {
        ...state,
        withdrawableCash: action.payload,
      };
    }
    case SET_TRADING_ACCOUNT_COUNT: {
      return {
        ...state,
        userTradingAccountCount: action.payload,
      };
    }
    case UPDATE_ACCOUNTS_LISTINGDATA: {
      const listingData = [...state.listingData];
      let index = listingData.findIndex(
        (x) => x.TradingAccountId === action.payload.TradingAccountId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, listingData, userAccountDetailsData: action.payload };
    }
    case RESET_STATE: {
      return { ...initState };
    }
    case GET_USER_TRADINGACCOUNT_RESTRICTION: {
      return { ...state, userSelectiveRestrictions: action.payload };
    }
    case SET_USER_TRADINGACCOUNT_RESTRICTION: {
      return { ...state, userSelectiveRestrictionStatus: action.payload };
    }
    case SET_LIST_OF_PERMISSION_GROUP: {
      return { ...state, listofPermissionGroups: action.payload };
    }
    default:
      return state;
  }
};

export default app;
