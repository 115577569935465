// Library imports
import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Chip from "@material-ui/core/Chip";
import SweetAlert from "react-bootstrap-sweetalert";

// Views imports
import TableComponent from "../../../components/Table/TableComponent1";
import ContainerHeader from "components/ContainerHeader/index";
import InfoView from "../../../components/InfoView";
import ManageRoleDialog from "./ManageRoleDialog";
import ManageUsersDialog from "./ManageUsersDialog";

// Services and Utilities imports
import IntlMessages from "util/IntlMessages";
import {
  getAllRoles,
  getAllPermissions,
  createNewRole,
  resetRoleCreatedStatus,
  deleteRole,
  updateRole,
  getUsersByRoles,
  getUsersByEmailOrUsername as searchUsers,
  updateUserListWithRole as updateRoleList,
  resetUserListUpdateStatus,
  resetRoleUpdateStatus,
  resetRoleDeleteStatus,
} from "../../../actions/Roles";

import { debounce, ACTION_STATUS, ROLES_ACTIONS } from "../../../util/Utility";
import "./roles.css";

class ManageRoles extends React.Component {
  headCells = [
    { id: "Roles", numeric: false, disablePadding: false, label: "Roles" },
    {
      id: "Permissions",
      numeric: false,
      disablePadding: false,
      label: "Permissions",
    },
  ];

  headCellsWithEdit = [
    ...this.headCells,
    {
      id: "Actions",
      numeric: false,
      disablePadding: false,
      label: "Actions",
    },
  ];

  constructor(props) {
    super(props);
    this.closeSweetAlert = this.closeSweetAlert.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.state = {
      // ManageRoleDialog configurations
      showRoleDialog: false,
      roleName: "",
      roleDescription: "",
      permittedClaims: [],
      unPermittedClaims: [],
      roleAction: "",

      //ManageUsersDialog configuration
      showUsersDialog: false,
      filteredUsers: [],

      // Sweet Alert configurations
      showSweetAlert: false,
      sweetAlertType: "warning",
      sweetAlertShowCancel: true,
      sweetAlertConfirmBtnText: "Confirm",
      sweetAlertConfirmBtnStyle: "default",
      sweetAlertCancelBtnStyle: "default",
      sweetAlertTitle: "",
      sweetAlertMessage: "",
      sweetAlertConfirmHandler: () => this.setState({ showSweetAlert: false }),
      // resetting sweet alert configuration
      userListRoleUpdateStatus: ACTION_STATUS.INITSTATE,

      selectedRole: "",
      roleDeleteStatus: ACTION_STATUS.FAILED,
    };
  }

  componentDidMount() {
    this.props.getAllRoles();
    this.props.getAllPermissions();
  }

  componentDidUpdate() {
    if (this.state.userListRoleUpdateStatus === ACTION_STATUS.SUCCESS) {
      this.setState({
        userListRoleUpdateStatus: ACTION_STATUS.INITSTATE,
        sweetAlertConfirmHandler: () => {
          this.props.resetUserListUpdateStatus();
          this.closeSweetAlert();
        },
      });
    }
  }

  addRoleButtonHandler = () => {
    // flag -- move this to callback of sweet alert
    // this.props.resetRoleCreatedStatus();
    const { authUser } = this.props;

    if (authUser && authUser.Claims.includes("claims.manage")) {
      this.setState({
        showRoleDialog: true,
        roleName: "",
        roleDescription: "",
        permittedClaims: [],
        unPermittedClaims: this.props.allPermissionsList,
        roleAction: ROLES_ACTIONS.CREATE,
      });
    }
  };

  roleDialogHandler = () => {
    this.setState({ showRoleDialog: false });
  };

  roleEditHandler = (row) => () => {
    const unPermittedClaims = this.props.allPermissionsList.filter((item) => {
      return row.permissions.findIndex((x) => x.value === item.value) === -1
        ? true
        : false;
    });

    this.setState({
      showRoleDialog: true,
      roleName: row.name,
      roleDescription: row.description,
      permittedClaims: row.permissions,
      unPermittedClaims,
      roleAction: ROLES_ACTIONS.UPDATE,
    });
  };

  roleDeletePrompt = (row) => () => {
    this.setState({
      selectedRole: row,
      showSweetAlert: true,
      sweetAlertType: "danger",
      sweetAlertShowCancel: true,
      sweetAlertConfirmBtnText: <IntlMessages id="sweetAlerts.yesDeleteIt" />,
      sweetAlertConfirmBtnStyle: "danger",
      sweetAlertCancelBtnStyle: "success",
      sweetAlertTitle: <IntlMessages id="sweetAlerts.areYouSure" />,
      sweetAlertMessage: "You will not be able to recover this role!", //<IntlMessages id="sweetAlerts.youWillNotAble" />,
      sweetAlertConfirmHandler: this.deleteRole,
    });
  };

  deleteRole() {
    this.props.deleteRole(this.state.selectedRole.name);
    this.setState({
      showSweetAlert: false,
    });
  }

  showDeleteSuccessStatus = () => {
    //   dialog()
  };

  /**
   * @param {string} name - Role name
   * @param {string} description - Role description
   * @param {any} permisssionsList - Role permission
   */
  submitManageRoleDialogHandler = (name, description, permisssionsList) => {
    let permissions = [];
    permisssionsList.forEach((item) => {
      permissions.push({ Value: item.value });
    });

    let formBody = {
      Name: name,
      Description: description,
      Permissions: permissions,
    };
    if (this.state.roleAction === ROLES_ACTIONS.CREATE) {
      this.props.createNewRole(formBody);
    } else if (this.state.roleAction === ROLES_ACTIONS.UPDATE) {
      this.props.updateRole(formBody);
    }

    this.setState({ showRoleDialog: false });
  };

  closeSweetAlert() {
    this.setState({ showSweetAlert: false });
  }

  // Closes user dialog
  closeUserDialog = () => {
    this.setState({ showUsersDialog: false });
  };

  // Fetches users list according to the roles
  searchUserByRole = (role) => {
    this.props.getUsersByRoles(role);
  };

  // Fetches list of users according to the search term in the search box.
  searchUsersBySearchTerm = debounce((searchTerm) => {
    this.props.searchUsers(searchTerm);
  }, 200);

  //
  submitManageUserDialogHandler = (roleName, userList) => {
    this.props.updateRoleList(roleName, userList);
    this.setState({ showUsersDialog: false });
  };

  sweetAlertSuccessStatus = (confirmBtnHandler) => {
    // Updating this way as not to trigger rerendering
    this.state.showSweetAlert = true;
    this.state.sweetAlertType = "success";
    this.state.sweetAlertShowCancel = false;
    this.state.sweetAlertConfirmBtnText = "Ok";
    this.state.sweetAlertConfirmBtnStyle = "success";
    this.state.sweetAlertTitle = "Role created successfully";
    this.state.sweetAlertMessage = "";
    this.state.sweetAlertCancelBtnStyle = "default";

    let { sweetAlertConfirmHandler } = this.state;

    sweetAlertConfirmHandler = (confirmBtnHandler) => {
      this.closeSweetAlert();
      if (confirmBtnHandler) confirmBtnHandler();
    };
  };

  render() {
    const {
      classes,
      tableData: tblData,
      recordsCount,
      totalRecordsCount,
      rowsPerPage,
      orderBy,
      order,
      pageIndex,
      roleCreationStatus,
      resetRoleCreatedStatus,
      allRoles,
      usersByRole,
      searchedUsers,
      listUpdateStatus,
      resetUserListUpdateStatus,
      resetRoleUpdateStatus,
      roleUpdatedStatus,
      authUser,
    } = this.props;

    let {
      showRoleDialog,
      showSweetAlert,
      sweetAlertType,
      sweetAlertShowCancel,
      sweetAlertConfirmBtnText,
      sweetAlertConfirmBtnStyle,
      sweetAlertCancelBtnStyle,
      sweetAlertTitle,
      sweetAlertMessage,
      sweetAlertConfirmHandler,
      roleName,
      roleDescription,
      permittedClaims,
      unPermittedClaims,
      roleAction,
      showUsersDialog,
    } = this.state;

    // Updating state without refreshing the page
    if (roleCreationStatus === ACTION_STATUS.SUCCESS) {
      showSweetAlert = true;
      sweetAlertType = "success";
      sweetAlertShowCancel = false;
      sweetAlertConfirmBtnText = "Ok";
      sweetAlertConfirmBtnStyle = "success";
      sweetAlertTitle = "Role created successfully";
      sweetAlertMessage = "";
      sweetAlertCancelBtnStyle = "default";
      sweetAlertConfirmHandler = () => {
        this.closeSweetAlert();
        resetRoleCreatedStatus();
      };
    }

    if (roleUpdatedStatus === ACTION_STATUS.SUCCESS) {
      showSweetAlert = true;
      sweetAlertType = "success";
      sweetAlertShowCancel = false;
      sweetAlertConfirmBtnText = "Ok";
      sweetAlertConfirmBtnStyle = "success";
      sweetAlertCancelBtnStyle = "default";
      sweetAlertTitle = "Role Updated Successfully";
      sweetAlertMessage = "";
      sweetAlertConfirmHandler = () => {
        this.closeSweetAlert();
        resetRoleUpdateStatus();
      };
    }

    // Updating state this way as to not to refresh the page again
    if (listUpdateStatus === ACTION_STATUS.SUCCESS) {
      showSweetAlert = true;
      sweetAlertType = "success";
      sweetAlertShowCancel = false;
      sweetAlertConfirmBtnText = "Ok";
      sweetAlertConfirmBtnStyle = "success";
      sweetAlertCancelBtnStyle = "default";
      sweetAlertTitle = "List Updated Successfully";
      sweetAlertMessage = "";
      sweetAlertConfirmHandler = () => {
        this.closeSweetAlert();
        resetUserListUpdateStatus();
      };
    } else if (listUpdateStatus === ACTION_STATUS.FAILED) {
      alert("Failed to update");
      resetUserListUpdateStatus();
    }

    let tableData = JSON.parse(JSON.stringify(tblData));

    tableData.forEach((row) => {
      row.Roles = row.name;
      row.Permissions = row.permissions.map((item) => {
        return (
          <Chip key={item.value} label={item.name} className="chip-outline" />
        );
      });
      row.Actions = (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={this.roleEditHandler(row)}
            //flag -- create a class for this
            style={{ margin: "7px" }}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.roleDeletePrompt(row)}
            //flag -- create a class for this
            style={{ margin: "7px" }}
          >
            Delete
          </Button>
        </>
      );
    });

    return (
      <div className="app-wrapper">
        <SweetAlert
          show={showSweetAlert}
          type={sweetAlertType}
          showCancel={sweetAlertShowCancel}
          confirmBtnText={sweetAlertConfirmBtnText}
          confirmBtnBsStyle={sweetAlertConfirmBtnStyle}
          cancelBtnBsStyle={sweetAlertCancelBtnStyle}
          title={sweetAlertTitle}
          onConfirm={sweetAlertConfirmHandler}
          onCancel={() => this.setState({ showSweetAlert: false })}
        >
          {sweetAlertMessage}
        </SweetAlert>
        <ManageRoleDialog
          openDialog={showRoleDialog}
          roleName={roleName}
          roleDescription={roleDescription}
          permittedClaims={permittedClaims}
          unPermittedClaims={unPermittedClaims}
          roleAction={roleAction}
          dialogCloseHandler={this.roleDialogHandler}
          submitForm={this.submitManageRoleDialogHandler}
        />
        {authUser && authUser.Claims.includes("claims.assign") && (
          <ManageUsersDialog
            openDialog={showUsersDialog}
            dialogCloseHandler={this.closeUserDialog}
            searchUserByRole={this.searchUserByRole}
            usersByRole={usersByRole}
            searchUsers={this.searchUsersBySearchTerm}
            searchedUsers={searchedUsers}
            roles={allRoles}
            submitForm={this.submitManageUserDialogHandler}
          />
        )}

        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <InfoView />
          <ContainerHeader
            match={this.props.match}
            title={<IntlMessages id="pages.manageRoles" />}
          />
        </div>

        <div className="d-flex justify-content-center dashboard-user-grid">
          <Grid container direction="column">
            {authUser &&
              (authUser.Claims.includes("claims.manage") ||
                authUser.Claims.includes("claims.assign")) && (
                <Grid item>
                  <Paper>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className="listing-table-view"
                    >
                      <Grid item md={8}></Grid>

                      <Grid item md={4} style={{ textAlign: "right" }}>
                        {authUser && authUser.Claims.includes("claims.manage") && (
                          <Button
                            variant="contained"
                            color="primary"
                            className="jr-btn"
                            onClick={this.addRoleButtonHandler}
                            style={{ margin: "14px 16px" }}
                          >
                            <i className="zmdi zmdi-account-add" />
                            <span>{"Add Role"}</span>
                          </Button>
                        )}

                        {authUser && authUser.Claims.includes("claims.assign") && (
                          <Button
                            variant="contained"
                            color="primary"
                            className="jr-btn"
                            onClick={() => {
                              this.setState({ showUsersDialog: true });
                            }}
                            style={{ margin: "14px 0px" }}
                          >
                            <i className="zmdi zmdi-account-add" />
                            <span>{"Manage Users"}</span>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}
            <Grid item>
              <Paper style={{ padding: "24px", marginTop: "24px" }}>
                <TableComponent
                  count={recordsCount}
                  totalRecordsCount={totalRecordsCount}
                  headCells={
                    authUser && authUser.Claims.includes("claims.manage")
                      ? this.headCellsWithEdit
                      : this.headCells
                  }
                  tableData={tableData}
                  changePageHandler={this.fetchTableDataNewPage}
                  rowsPerPageChangeHandler={this.rowsPerPageChangeHandler}
                  rowsPerPage={rowsPerPage}
                  orderBy={orderBy}
                  order={order}
                  sortingHandler={this.dataSortHandler}
                  sortableColumns={[]}
                  primaryFieldName={"Roles"}
                  pageIndex={pageIndex}
                  tableName={""}
                  classes={classes}
                  className="role-listing"
                />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tableData: state.roles.roles,
    recordsCount: state.roles.rolesRecordsCount,
    totalRecordsCount: state.roles.rolesTotalRecordsCount,
    searchTerm: state.roles.rolesSearchTerm,
    pageIndex: state.roles.rolesTablePageIndex,
    rowsPerPage: state.roles.rolesRowsPerPage,
    orderBy: state.roles.rolesOrderBy,
    order: state.roles.rolesOrder,
    roleCreationStatus: state.roles.roleCreationStatus,
    allPermissionsList: state.roles.permissionsList,
    allRoles: state.roles.roles,
    usersByRole: state.roles.usersByRole,
    searchedUsers: state.roles.searchedUsers,
    listUpdateStatus: state.roles.userListRoleUpdateStatus,
    roleUpdatedStatus: state.roles.roleUpdatedStatus,
    authUser: state.auth.authUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAllRoles,
      getAllPermissions,
      createNewRole,
      resetRoleCreatedStatus,
      deleteRole,
      updateRole,
      getUsersByRoles,
      searchUsers,
      updateRoleList,
      resetUserListUpdateStatus,
      resetRoleUpdateStatus,
      resetRoleDeleteStatus,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageRoles);
