import { Box, styled } from "@material-ui/core";
import { maxBy, minBy, filter, chain, takeRight } from "lodash";
import moment from "moment";

const calcPreviousPeriodCloseValue = (stockChartData, primarySeries) => {
  const firstInTPIndex = stockChartData.findIndex((p) =>
    moment
      .utc(p.x)
      .isSame(moment.min(primarySeries.map((d) => moment.utc(d.x))))
  );

  let previousIndex;

  if (firstInTPIndex > 0) {
    // Case 1: There is a point immediately before the first in the current TP
    previousIndex = firstInTPIndex - 1;
  } else if (firstInTPIndex === -1) {
    // Case 2: There are no dates in the current TP, return the last point in the series
    previousIndex = 0;
  } else {
    // Case 3: All points in the series are in this TP, use the first one
    previousIndex = 0;
  }

  const previousPeriodCloseValue = stockChartData[previousIndex]?.y ?? 0;
  return previousPeriodCloseValue;
};

const generateDataPoint = (minDate, maxDate, endDate, maxItemCount, period) => {
  const array = [];
  let ItemCount = 1;
  const datetime = minDate;

  if (maxItemCount > 0) {
    if (period === "1_day" || period === "1_week") {
      const interval = period === "1_day" ? 5 : 30;
      let isBefore = moment.utc(datetime).isBefore(moment.utc(endDate));

      while (!isBefore) {
        const data = moment
          .utc(datetime)
          .subtract(interval * ItemCount, "minutes")
          .format();
        array.push({ x: data, y: null });
        ItemCount++;
        isBefore = moment.utc(data).isBefore(moment.utc(endDate));
        if (ItemCount > maxItemCount) {
          break;
        }
      }
    } else {
      let isBefore = moment.utc(datetime).isBefore(moment.utc(endDate));

      while (!isBefore) {
        const data = moment
          .utc(datetime)
          .startOf("day")
          .subtract(ItemCount, "day")
          .format();
        array.push({ x: data, y: null });
        ItemCount++;
        isBefore = moment.utc(data).isBefore(moment.utc(endDate));
        if (ItemCount > maxItemCount) {
          break;
        }
      }
    }
  }
  return array;
};

export const convertArrayToChartSeries = (PriceSeries, period) => {
  let lastDay;
  const array = [
    {
      previousPeriodCloseValue: 0,
      type: "line",
      data: [],
      minValue: 0,
      maxValue: 0,
    },
  ];

  if (PriceSeries) {
    const stockChartData = PriceSeries;
    const maxDate =
      stockChartData?.length > 0
        ? stockChartData[stockChartData.length - 1].x
        : null;

    let maxGenerateDataPoint = 0;
    switch (period) {
      case "1_day":
        lastDay = moment
          .utc(maxDate)
          .subtract(395, "m")
          .format();

        maxGenerateDataPoint = 80;
        break;
      case "1_week":
        lastDay = moment
          .utc(maxDate)
          .endOf("day")
          .subtract(1, "week")
          .format();
        maxGenerateDataPoint = 60;
        break;
      default:
        lastDay = "";
        break;
    }
    if (lastDay) {
      if (period === "1_day" || period === "1_week") {
        let filteredObjects = filter(stockChartData, (each) =>
          moment.utc(each.x).isSameOrAfter(lastDay)
        );
        if (period === "1_week") {
          filteredObjects = filteredObjects.filter((item) => {
            const time = moment.utc(item.x).format("hh:mm");
            if (time.indexOf(":00") > 0 || time.indexOf(":30") > 0) {
              return item;
            }
            return false;
          });
          filteredObjects.splice(0, 1);
        }

        let generatedDataPoint = [];
        if (filteredObjects && filteredObjects.length > 0) {
          generatedDataPoint = generateDataPoint(
            filteredObjects[0].x,
            filteredObjects[filteredObjects.length - 1].x,
            lastDay,
            maxGenerateDataPoint - filteredObjects.length,
            period
          );
        }
        if (period === "1_day")
          array[0].data = filteredObjects.concat(generatedDataPoint);
        else array[0].data = filteredObjects.concat(generatedDataPoint);

        array[0].previousPeriodCloseValue = calcPreviousPeriodCloseValue(
          stockChartData,
          filteredObjects
        );

        array[0].minValue = minBy(filteredObjects, "y")?.y;
        array[0].maxValue = maxBy(filteredObjects, "y")?.y;
      }
    }
  }

  return array;
};

export const getPreviousDayCloseValue = (PriceSeries) => {
  let previousPeriodCloseValue;

  if (PriceSeries && PriceSeries.length > 0) {
    const stockChartData = takeRight(PriceSeries, 150);
    const maxDate = stockChartData[stockChartData.length - 1].x;

    // let maxGenerateDataPoint = 0;

    const lastDay = moment
      .utc(maxDate)
      .subtract(395, "m")
      .format();

    const filteredObjects = filter(stockChartData, (each) =>
      moment.utc(each.x).isSameOrAfter(lastDay)
    );

    previousPeriodCloseValue = calcPreviousPeriodCloseValue(
      stockChartData,
      filteredObjects
    );
  }

  return previousPeriodCloseValue;
};

export const getChartPreviousLine = (GroupData, period) => {
  let lastDay;
  const obj = {
    previousPeriodCloseValue: 0,
    minValue: 0,
    maxValue: 0,
    minDate: "",
  };

  if (GroupData) {
    const stockChartData = GroupData;
    const maxDate = stockChartData[stockChartData.length - 1].x;

    switch (period) {
      case "1_month":
        lastDay = moment
          .utc(maxDate)
          .endOf("day")
          .subtract(1, "month")
          .format("YYYY/MM/DD");

        break;
      case "3_month":
        lastDay = moment
          .utc(maxDate)
          .endOf("day")
          .subtract(3, "month")
          .format("YYYY/MM/DD");
        break;
      case "1_year":
        lastDay = moment
          .utc(maxDate)
          .endOf("day")
          .subtract(1, "year")
          .format("YYYY/MM/DD");
        break;
      default:
        lastDay = stockChartData[0].x;
        break;
    }
    if (lastDay) {
      obj.minDate = lastDay;
      const filteredObjects = filter(stockChartData, (each) =>
        moment.utc(each.x).isSameOrAfter(lastDay)
      );

      obj.previousPeriodCloseValue = calcPreviousPeriodCloseValue(
        stockChartData,
        filteredObjects
      );
      obj.minValue = minBy(filteredObjects, "y").y;
      obj.maxValue = maxBy(filteredObjects, "y").y;
    }
  }

  return obj;
};

export const GroupByDate = (PriceSeries) => {
  if (PriceSeries) {
    const filteredObjects = chain(
      PriceSeries.map((chr) => ({
        x: moment.utc(chr.x).format("YYYY/MM/DD"),
        y: chr.y,
      }))
    )
      .groupBy("x")
      .map((v, i) =>
        // return [new Date(i).getTime(), v[v.length - 1].y];
        ({
          x: moment.utc(i).format("YYYY/MM/DD"),
          y: v[v.length - 1].y,
        })
      )
      .valueOf();
    return filteredObjects;
  }
  return [];
};

export const bigNumberFormatter = (value, isShortDisplayVisible = false) => {
  const number = Number(value);
  let formatted = null;

  if (number >= 1000000000) {
    formatted = (number / 1000000000)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return `${formatted}B`;
  }

  if (number >= 1000000) {
    formatted = (number / 1000000)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return `${formatted}M`;
  }
  if (isShortDisplayVisible) {
    // example
    // 93,643.27 => 93.6 k
    formatted = (number / 1000)
      .toFixed(1)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return `${formatted}K`;
  }

  return decimalFormatter(value);
};

export const decimalFormatter = (value) =>
  value
    ? Number(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        .replace("999,999,999.00", "Withdraw All")
    : "0.00";

export const BlankCard = styled(Box)(({ theme, pattern = "plain" }) => ({
  borderRadius: "10px",
  padding: "20px",
  boxShadow: "0px 15px 40px #0000001a",
  //   background:
  //     pattern === "plain"
  //       ? theme.vars.palette.background.paper
  //       : "#171f32 url(assets/images/StatsCustom2.png) 0% 0% no-repeat padding-box",
  //   color: theme.vars.palette.text.secondary,
}));
