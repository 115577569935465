import {
  RESET_STATE,
  GET_ALL_BANK_ACCOUNTS,
  GET_SINGLE_BANK_ACCOUNTS,
  RESET_BANK_ACCOUNTS_TABLE,
  OVERRIDE_BANKACCOUNT_ID,
  SET_END_DATE_BANKACCOUNTS,
  SET_START_DATE_BANKACCOUNTS
} from "../constants/ActionTypes";
import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  ACTIVE_FILTER_DROPDOWN,
  BANK_REVIEW_FILTER_DROPDOWN
} from "../util/Utility";

const initState = {
  // account listing info
  bankAccountListingData: [],
  bankAccountListingRecordsCount: 0,
  bankAccountListingTotalRecordsCount: 0,
  bankAccountListingSearchTerm: "",
  bankAccountListingTablePageIndex: 0,
  bankAccountListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  bankAccountListingOrderBy: "Created",
  bankAccountListingOrder: "desc",
  bankAccountStatusFilter: [],
  bankAccountMatchStatusFilter: [],
  bankAccountListingFilter: FILTER_OPTIONS.SHOWALL,
  startDate: null,
  endDate: null,

  // Single bank account
  bankAccountData: "",
  isActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,
  bankReviewFilter: BANK_REVIEW_FILTER_DROPDOWN.ALL
};

const bankAccounts = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_ALL_BANK_ACCOUNTS: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateDataHelper = {
            bankAccountListingSearchTerm: action.stateData,
            bankAccountListingTablePageIndex: 0
          };
          break;

        case LISTING_REQ_ORIGIN.STATUS_FILTER:
          stateDataHelper = {
            bankAccountStatusFilter: action.stateData,
            bankAccountListingTablePageIndex: 0
          };

          break;

        case LISTING_REQ_ORIGIN.BANK_MATCH_STATUS_FILTER:
          stateDataHelper = {
            bankAccountMatchStatusFilter: action.stateData,
            bankAccountListingTablePageIndex: 0
          };

          break;

        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            bankAccountListingTablePageIndex: action.stateData
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            bankAccountListingRowsPerPage: action.stateData,
            bankAccountListingTablePageIndex: 0
          };
          break;

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            bankAccountListingOrderBy: orderData[0],
            bankAccountListingOrder: orderData[1],
            bankAccountListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            bankAccountListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            bankAccountListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            bankAccountListingFilter: action.stateData,
            bankAccountListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            isActiveFilter: action.stateData
          };
          break;
        }

        case LISTING_REQ_ORIGIN.BANK_REVIEW_FILTER: {
          stateDataHelper = {
            bankReviewFilter: action.stateData
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            startDate: action.stateData,
            bankAccountListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            endDate: action.stateData,
            bankAccountListingTablePageIndex: 0
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            bankAccountListingTablePageIndex: 0,
          };
          break;
        }

        default:
          break;
      }

      return {
        ...state,
        bankAccountListingData: action.data,
        bankAccountListingRecordsCount: action.totalMatch,
        bankAccountListingTotalRecordsCount: action.total,
        ...stateDataHelper
      };
    }

    case GET_SINGLE_BANK_ACCOUNTS: {
      return { ...state, bankAccountData: action.payload };
    }

    case RESET_BANK_ACCOUNTS_TABLE: {
      return { ...initState };
    }

    case OVERRIDE_BANKACCOUNT_ID: {
      let listingData = [...state.bankAccountListingData];
      let index = listingData.findIndex(
        x => x.BankAccountId === action.payload.BankAccountId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, bankAccountListingData: listingData };
    }

    case SET_END_DATE_BANKACCOUNTS: {
      return { ...state, endDate: action.payload };
    }

    case SET_START_DATE_BANKACCOUNTS: {
      return { ...state, startDate: action.payload };
    }

    case RESET_STATE: {
      return { ...initState };
    }
    

    default:
      return state;
  }
};

export default bankAccounts;
