import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Typography } from "@material-ui/core";
import { fetchGenerateUserAssetReport } from "../../../../actions/GenerateUserAssetReport";
import InfoView from "../../../../components/InfoView";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import DateFilter from "../../filters/DateFilter";
import Button from "@material-ui/core/Button";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const GenerateUserAssetReport = ({ match }) => {
  const dispatch = useDispatch();
  const csvData = useSelector((state) => state.userAssetReportData);
  const [startDateTerm, setStartDateTerm] = useState(null);
  const [endDateTerm, setEndDateTerm] = useState(null);

  function convertData(inputData) {
    const csvData = [];

    inputData.forEach((entry) => {
      const csvString = Object.values(entry)[0];
      const csvArray = csvString.split(",");

      const rowData = {
        "S.No": parseInt(csvArray[0]),
        "First Name": csvArray[1],
        "Last Name": csvArray[2],
        Email: csvArray[3],
        "Start Date": csvArray[4],
        "Total Net Liquidity": parseFloat(csvArray[5]?.replace("\r", "")),
      };

      csvData.push(rowData);
    });

    return csvData;
  }

  function convertCSVToObject(data) {
    const lines = data.split("\n");
    const headers = lines[0].split(";");
    const dataArray = [];
    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(";");
      const rowData = {};
      for (let j = 0; j < headers.length; j++) {
        rowData[headers[j]] = values[j];
      }
      dataArray.push(rowData);
    }
    return dataArray;
  }
  function startDateChangeHandler(event) {
    setStartDateTerm(event);
  }

  function endDateChangeHandler(event) {
    setEndDateTerm(event);
  }

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsb";

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsb", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  let finalData = convertCSVToObject(csvData.generateUserAssetReportData);
  const generateCSV = (startDateTerm, endDateTerm) => {
    const data = convertData(finalData);
    exportToCSV(data, "UserAssetReport");
  };

  useEffect(() => {
    dispatch(fetchGenerateUserAssetReport(startDateTerm, endDateTerm));
  }, [startDateTerm, endDateTerm]);

  return (
    <div>
      <InfoView />
      <ContainerHeader
        match={match}
        title={<IntlMessages id="User & Asset Report" />}
      />

      <Grid container direction="column">
        <Grid item>
          <Paper>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="filter-contaner"
            >
              <Typography variant="h5" gutterBottom>
                Generate User & Asset Report
              </Typography>
              <Typography variant="subtitle2" align="center">
                To specify a date range, enter start and/or end dates. Leaving
                the dates empty will return all records.
              </Typography>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                {/* Start Date */}
                <DateFilter
                  label="Start Date"
                  date={startDateTerm}
                  onDateChange={startDateChangeHandler}
                  checkEndDate={endDateTerm}
                />

                {/* End Date */}
                <DateFilter
                  label="End Date"
                  date={endDateTerm}
                  onDateChange={endDateChangeHandler}
                  checkStartDate={startDateTerm}
                />
              </div>

              <Grid rid item style={{ marginTop: "6.5px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => generateCSV(startDateTerm, endDateTerm)}
                >
                  Generate and Download CSV
                </Button>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default GenerateUserAssetReport;
