import axios from "util/Api";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_SYMBOLS_DATA,
  GET_SYMBOLS_DATA_SEARCH,
  GET_SYMBOLS_ID_DATA,
  GET_SYMBOLS_ROW_PER_PAGE,
  SHOW_MESSAGE,
} from "../constants/ActionTypes";
import { getBasePriceURL } from "../util/Api";
import { LISTING_REQ_ORIGIN } from "../util/Utility";
import { getUserServer } from "../util/localStorageService";

export const sKey = "c9ca1b82-af1f-4c01-9f1e-aa982957ae3f1";

let searchdata;

export const getAllSymbolData = () => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  const priceBase = getBasePriceURL(getUserServer());

  let url = `/api/v1/SymbolStrategy?IsActive=true`;

  axios
    .get(url, { baseURL: priceBase })
    .then(({ data }) => {
      const rowsNumber = data.length < 10 ? data.length : 10;
      dispatch({
        type: GET_ALL_SYMBOLS_DATA,
        payload: data.data,
      });
      dispatch({
        type: GET_SYMBOLS_ROW_PER_PAGE,
        payload: rowsNumber,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on getting All Notification data. ${error}`,
      });
    });
};

export const getSearchSymbolData = (search) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  searchdata = search;
  const priceBase = getBasePriceURL(getUserServer());

  let url = `/api/v1/Asset/search?searchTerm=${search}`;

  axios
    .get(url, { baseURL: priceBase })
    .then(({ data }) => {
      const rowsNumber = data.length < 10 ? data.length : 10;
      dispatch({
        type: GET_SYMBOLS_DATA_SEARCH,
        payload: data,
      });
      dispatch({
        type: GET_SYMBOLS_ROW_PER_PAGE,
        payload: rowsNumber,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on getting All Notification data. ${error}`,
      });
    });
};

export const updateSymbols = (payload) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  const priceBase = getBasePriceURL(getUserServer());

  let url = `/api/v1/Asset`;

  axios
    .put(url, payload, { baseURL: priceBase })
    .then(({ data }) => {
      dispatch({
        type: SHOW_MESSAGE,
        payload: `Updated Sucessfully`,
      });
      dispatch(getSearchSymbolData(searchdata));
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on Editing Notification . ${error}`,
      });
    });
};

export const getSymbolIdData = (symbol) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  const priceBase = getBasePriceURL(getUserServer());

  let url = `/api/v1/Asset/${symbol}`;

  axios
    .get(url, { baseURL: priceBase })
    .then(({ data }) => {
      dispatch({
        type: GET_SYMBOLS_ID_DATA,
        payload: data,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on Editing Notification . ${error}`,
      });
    });
};
