// Library imports
import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";

// Services and Utilities
import "./roles.css";
import { ROLES_ACTIONS } from "../../../util/Utility";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  paper: {
    width: 300,
    height: 230,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

export default function ManageRoleDialog(props) {
  const classes = useStyles();

  const [roleName, setRoleName] = useState(props.roleName);
  const [roleDescription, setRoleDescription] = useState(props.roleDescription);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(props.unPermittedClaims);
  const [right, setRight] = React.useState(props.permittedClaims);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCancel = () => {
    props.dialogCloseHandler();
  };

  useEffect(() => {
    setLeft(props.unPermittedClaims);
    setRight(props.permittedClaims);
    setRoleName(props.roleName);
    setRoleDescription(props.roleDescription);
  }, [
    props.permittedClaims,
    props.unPermittedClaims,
    props.roleName,
    props.roleDescription
  ]);

  const handleToggle = item => () => {
    const currentIndex = checked.findIndex(x => x.value === item.value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    setRight(right.concat(left));
    setLeft([]);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = items => {
    return (
      <Paper className={classes.paper}>
        <List dense component="div" role="list">
          {items.map(item => {
            const labelId = `transfer-list-item-${item.value}-label`;
            return (
              <ListItem
                key={item.value}
                role="listitem"
                button
                onClick={handleToggle(item)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      checked.findIndex(x => x.value === item.value) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.name}`} />
              </ListItem>
            );
          })}
          <ListItem />
        </List>
      </Paper>
    );
  };

  const submitForm = () => {
    if (roleName && roleDescription) {
      props.submitForm(roleName, roleDescription, right);
    }
  };

  return (
    <Dialog
      open={props.openDialog}
      TransitionComponent={Slide}
      onClose={handleCancel}
    >
      <DialogTitle>{props.roleAction === ROLES_ACTIONS.CREATE ? "Create Role" : "Edit Role"}</DialogTitle>
      <DialogContent>
        <TextField
          required
          id="role-name"
          label="Role Name"
          value={roleName}
          onInput={e => setRoleName(e.target.value)}
          margin="normal"
          fullWidth
          disabled={props.roleAction === ROLES_ACTIONS.UPDATE ? true : false}
        />

        <TextField
          label="Description"
          placeholder="Description"
          value={roleDescription}
          onInput={e => setRoleDescription(e.target.value)}
          multiline
          margin="normal"
          fullWidth
        />

        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList(left)}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right)}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary">
          {props.roleAction === ROLES_ACTIONS.CREATE ? "Create" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
