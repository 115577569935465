import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Compliance = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/reconciliation`} />
        <Route
          path={`${match.url}/tradereport`}
          component={asyncComponent(() => import("./routes/Reports/TradeReport"))}
        />
        <Route
          path={`${match.url}/positionreport`}
          component={asyncComponent(() => import("./routes/Reports/PositionReport"))}
        />
        <Route
          path={`${match.url}/accountbalancereport`}
          component={asyncComponent(() => import("./routes/Reports/AccountBalanceReport"))}
        />
     <Route
          path={`${match.url}/onboardingstatusReport`}
          component={asyncComponent(() => import("./routes/Reports/OnboardingStatusReport"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Compliance;
