//*****************************************/
//        Trading Account Listing
//*****************************************/
export const RESET_ACCOUNT_LISTING_FILTER = "reset_account_listing_filter";

export const SET_TRADING_ACCOUNT_LISTING_OVERRIDE_FILTER =
  "set_trading_account_listing_override_filter";
export const SET_TRADING_ACCOUNT_LISTING_ACTIVE_FILTER =
  "set_trading_account_listing_active_filter";
export const SET_TRADING_ACCOUNT_LISTING_STATUS_FILTER =
  "set_trading_account_listing_status_filter";
export const SET_TRADING_ACCOUNT_LISTING_BROKER_FILTER =
  "set_trading_account_listing_broker_filter";
export const SET_TRADING_ACCOUNT_LISTING_EMAILCONFIRMED_FILTER =
  "set_trading_account_listing_emailconfirmed_filter";
export const SET_TRADING_ACCOUNT_LISTING_EVS_FILTER =
  "set_trading_account_listing_evs_filter";
export const SET_TRADING_ACCOUNT_LISTING_ONBOARDING_FILTER =
  "set_trading_account_listing_onboarding_filter";
export const SET_TRADING_ACCOUNT_LISTING_BANK_MATCH_STATUS_FILTER =
  "set_trading_account_listing_bank_match_status_filter";
export const SET_TRADING_ACCOUNT_LISTING_END_DATE_FILTER =
  "set_trading_account_listing_end_date_filter";
export const SET_TRADING_ACCOUNT_LISTING_START_DATE_FILTER =
  "set_trading_account_listing_start_date_filter";
export const SET_TRADING_ACCOUNT_LISTING_SEARCH_FILTER =
  "set_trading_account_listing_search_filter";

//*****************************************/
//        Trading Account Details - Position
//*****************************************/
export const SET_USER_POSITIONS_STOCK_STATUS_FILTER =
  "set_user_positions_stock_status_filter";
export const SET_USER_POSITIONS_STOCK_TYPE_FILTER =
  "set_user_positions_stock_type_filter";
export const SET_USER_POSITIONS_SEARCH_FILTER =
  "set_user_positions_search_filter";
export const SET_USER_POSITIONS_START_DATE_FILTER =
  "set_user_positions_start_date_filter";
export const SET_USER_POSITIONS_END_DATE_FILTER =
  "set_user_positions_end_date_filter";

//*****************************************/
//        Trading Account Details - BankAccount
//*****************************************/
export const SET_USER_BANK_ACCOUNT_DATA = "set_user_bank_account_data";
export const SET_USER_BANKACCOUNT_LISTING_FILTER =
  "set_user_bankaccount_listing_filter";
export const SET_USER_BANKACCOUNT_ISACTIVE_FILTER =
  "set_user_bankaccount_isactive_filter";
export const SET_USER_BANKACCOUNT_REVIEW_FILTER =
  "set_user_bankaccount_review_filter";
export const SET_USER_BANKACCOUNT_STATUS_FILTER =
  "set_user_bankaccount_status_filter";
export const SET_USER_BANKACCOUNT_MATCH_STATUS_FILTER =
  "set_user_bankaccount_match_status_filter";
export const SET_USER_BANKACCOUNT_SEARCH_FILTER =
  "set_user_bankaccount_search_filter";
export const SET_USER_BANKACCOUNT_START_DATE_FILTER =
  "set_user_bankaccount_start_date_filter";
export const SET_USER_BANKACCOUNT_END_DATE_FILTER =
  "set_user_bankaccount_end_date_filter";

export const SET_USER_BANK_ACCOUNT_DETAIL_BY_ID =
  "set_user_bank_account_detail_by_id";
export const SET_USER_BANK_PROCESSING_STATUS =
  "set_user_bank_processing_status";
export const IS_BANK_DETAIL_FETCHED = "is_bank_detail_fetched";
export const SET_USER_BANK_ACCOUNT_STATEMENT = "set_user_bank_statement";
export const IS_LISTING_DATA_FETCHED = "is_listing_data_fetched";
export const SET_USER_BANK_AUDIT_TRAIL_DATA = "set_user_bank_audit_trail_data";

//*****************************************/
//        Trading Account Details - Trades
//*****************************************/
export const SET_USER_TRADES_DATA = "set_user_trades_data";
export const OVERRIDE_TRADES_ID_TRADING_ACCOUNT =
  "override_trades_id_trading_account";
export const SET_USER_TRADE_LISTING_FILTER = "set_user_trade_listing_filter";
export const SET_USER_TRADE_END_DATE_FILTER = "set_user_trade_end_date_filter";
export const SET_USER_TRADE_ISACTIVE_FILTER = "set_user_trade_isactive_filter";
export const SET_USER_TRADE_ISBUYORSALE_FILTER =
  "set_user_trade_isbuyorsale_filter";
export const SET_USER_TRADE_START_DATE_FILTER =
  "set_user_trade_start_date_filter";
export const SET_USER_TRADE_STATUS_FILTER = "set_user_trade_status_filter";
export const SET_USER_TRADE_SMARTFOLIO_FILTER =
  "set_user_trade_smartfolio_filter";
export const SET_USER_TRADE_SEARCH_FILTER = "set_user_trade_serach_filter";
//*****************************************/
//        Trading Account Details - CashMovement
//*****************************************/
export const OVERRIDE_TRANSFER_ID_TRADING_ACCOUNT =
  "override_transfer_id_trading_account";
export const SET_USER_BANK_TRANSFER_DATA = "set_user_bank_transfer_data";
export const SET_USER_CASHMOVEMENT_LISTING_FILTER =
  "set_user_cashmovement_listing_filter";
export const SET_USER_CASHMOVEMENT_ISACTIVE_FILTER =
  "set_user_cashmovement_isactive_filter";
export const SET_USER_CASHMOVEMENT_STATUS_FILTER =
  "set_user_cashmovement_status_filter";
export const SET_USER_CASHMOVEMENT_MOVEMENT_FILTER =
  "set_user_cashmovement_movement_filter";
export const SET_USER_CASHMOVEMENT_SEARCH_FILTER =
  "set_user_cashmovement_search_filter";
export const SET_USER_CASHMOVEMENT_START_DATE_FILTER =
  "set_user_cashmovement_start_date_filter";
export const SET_USER_CASHMOVEMENT_END_DATE_FILTER =
  "set_user_cashmovement_end_date_filter";
export const SET_USER_ACH_RETURN_UPDATE_STATUS =
  "set_user_ach_return_update_status";
//*****************************************/
//        Bank Account Listing Screen
//*****************************************/
export const RESET_BANK_ACCOUNT_LISTING_FILTER =
  "reset_bank_account_listing_filter";
export const GET_ALL_BANK_ACCOUNTS = "get_all_bank_accounts";
export const GET_SINGLE_BANK_ACCOUNTS = "get_single_bank_accounts";
export const RESET_BANK_ACCOUNTS_TABLE = "reset_bank_account_table";
export const OVERRIDE_BANKACCOUNT_ID = "override_bankaccount_id";
export const SET_START_DATE_BANKACCOUNTS = "set_start_date_bankaccounts";
export const SET_END_DATE_BANKACCOUNTS = "set_end_date_bankaccounts";

// Customizer const
export const TOGGLE_COLLAPSED_NAV = "toggle_collapse_menu";
export const DRAWER_TYPE = "drawer_type";
export const FIXED_DRAWER = "fixed_drawer";
export const COLLAPSED_DRAWER = "collapsible";
export const MINI_DRAWER = "mini_drawer";
export const THEME_COLOR = "theme_color";
export const DARK_THEME = "dark_theme";
export const WINDOW_WIDTH = "window-width";
export const SWITCH_LANGUAGE = "switch-language";
export const CHANGE_DIRECTION = "change-direction";

export const CHANGE_NAVIGATION_STYLE = "change-navigation-style";
export const HORIZONTAL_NAVIGATION = "horizontal_navigation";
export const VERTICAL_NAVIGATION = "vertical_navigation";

export const HORIZONTAL_MENU_POSITION = "horizontal_menu_position";
export const ABOVE_THE_HEADER = "above_the_header";
export const INSIDE_THE_HEADER = "inside_the_header";
export const BELOW_THE_HEADER = "below_the_header";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const NETWORK_ERROR = "network_error";

export const FETCH_ALL_CONTACT = "fetch_all_contact";
export const FETCH_ALL_CONTACT_SUCCESS = "fetch_all_contact_success";
export const ADD_FAVOURITE = "add_favourite";
export const ON_CONTACT_SELECT = "on_contact_select";
export const ON_ADD_CONTACT = "on_add_contact";
export const ON_CONTACT_CLOSE = "on_contact_close";
export const ON_FILTER_OPTION_SELECT = "on_filter_option_select";
export const ON_SAVE_CONTACT = "on_save_contact";
export const ON_DELETE_CONTACT = "on_delete_contact";
export const ON_DELETE_SELECTED_CONTACT = "on_delete_selected_contact";
export const FILTER_CONTACT = "filter_contact";
export const GET_ALL_CONTACT = "get_all_contact";
export const GET_UNSELECTED_ALL_CONTACT = "get_unselected_all_contact";
export const ON_ALL_CONTACT_SELECT = "on_all_contact_select";
export const UPDATE_SEARCH_USER = "update_search_user";
export const ON_TOGGLE_DRAWER = "on_toggle_drawer";
export const HANDLE_REQUEST_CLOSE = "handle_request_close";
export const HIDE_CONTACT_LOADER = "hide_contact_loader";

//Auth const
export const SIGNUP_USER = "signup_user";
export const SIGNUP_USER_SUCCESS = "signup_user_success";
export const SIGNIN_GOOGLE_USER = "signin_google_user";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER = "signin_facebook_user";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER = "signin_twitter_user";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER = "signin_github_user";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER = "signin_user";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER = "signout_user";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";
export const INIT_URL = "init_url";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";
export const USER_UNAUTHORIZED_LOGIN = "user_unauthorized_to_login";
export const SET_IMPERSONATED_TOKEN = "set_user_impersonated_token";

// Chat Module const
export const FETCH_ALL_CHAT_USER = "fetch_all_chat_user";
export const FETCH_ALL_CHAT_USER_CONVERSATION =
  "fetch_all_chat_user_conversation";
export const FETCH_ALL_CHAT_USER_SUCCESS = "fetch_all_chat_user_success";
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS =
  "fetch_all_chat_user_conversation_success";
export const FILTER_USERS = "filter_users";
export const ON_SELECT_USER = "on_select_user";
export const ON_SHOW_LOADER = "on_show_loader";
export const ON_HIDE_LOADER = "on_hide_loader";
export const USER_INFO_STATE = "user_info_state";
export const SUBMIT_COMMENT = "submit_comment";
export const UPDATE_MESSAGE_VALUE = "update_message_value";
export const UPDATE_SEARCH_CHAT_USER = "update_search_chat_user";

//// Mail Module const
export const ADD_LABEL = "ADD_LABEL";
export const GET_ALL_MAIL = "get_all_mail";
export const FETCH_ALL_MAIL = "fetch_all_mail";
export const FETCH_ALL_MAIL_SUCCESS = "fetch_all_mail_success";
export const GET_IMPORTANT_MAIL = "get_important_mail";
export const GET_NAV_FILTERS = "get_nav_filters";
export const GET_NAV_LABELS = "get_nav_labels";
export const GET_NAV_FOLDER = "get_nav_folder";
export const GET_READ_MAIL = "get_read_mail";
export const GET_STARRED_MAIL = "get_starred_mail";
export const GET_UNIMPORTANT_MAIL = "get_unimportant_mail";
export const GET_UNREAD_MAIL = "get_unread_mail";
export const GET_UNSELECTED_ALL_MAIL = "get_unselected_all_mail";
export const GET_UNSTARRED_MAIL = "get_unstarred_mail";
export const ON_ALL_MAIL_SELECT = "on_all_mail_select";
export const ON_FOLDER_MENU_ITEM_SELECT = "on_folder_menu_item_select";
export const ON_FOLDER_SELECT = "on_folder_select";
export const ON_IMPORTANT_SELECT = "on_important_select";
export const ON_LABEL_MENU_ITEM_SELECT = "on_label_menu_item_select";
export const GET_MARK_AS_START = "get_mark_as_start";
export const ON_LABEL_SELECT = "on_label_select";
export const ON_MAIL_CHECKED = "on_mail_checked";
export const ON_MAIL_SELECT = "on_mail_select";
export const ON_MAIL_SEND = "on_mail_send";
export const ON_OPTION_MENU_ITEM_SELECT = "on_option_menu_item_select";
export const ON_OPTION_MENU_SELECT = "on_option_menu_select";
export const ON_START_SELECT = "on_start_select";
export const SEARCH_MAIL = "search_mail";
export const ON_DELETE_MAIL = "on_delete_mail";
export const SET_CURRENT_MAIL_NULL = "set_current_mail_null";
export const ON_COMPOSE_MAIL = "on_compose_mail";

//// TO-DO Module const

export const ON_SORTEND = "on_sortend";
export const FETCH_ALL_TODO = "fetch_all_todo";
export const FETCH_ALL_TODO_SUCCESS = "fetch_all_todo-success";
export const FETCH_ALL_TODO_CONVERSATION = "fetch_all_todo_conversation";
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS =
  "fetch_all_todo_conversation_success";
export const SELECT_ALL_TODO = "select_all_todo";
export const GET_ALL_TODO = "get_all_todo";
export const GET_UNSELECTED_ALL_TODO = "get_unselected_all_todo";
export const GET_STARRED_TODO = "get_starred_todo";
export const GET_UNSTARRED_TODO = "get_unstarred_todo";
export const GET_IMPORTANT_TODO = "get_important_todo";
export const GET_UNIMPORTANT_TODO = "get_unimportant_todo";
export const ON_LABEL_UPDATE = "on_label_update";
export const ON_TODO_UPDATE = "on_todo_update";
export const ON_DELETE_TODO = "on_delete_todo";
export const SEARCH_TODO = "search_todo";
export const SHOW_TODOS = "show_todos";
export const GET_TODO_CONVERSATION = "get_todo_conversation";
export const ON_TODO_CHECKED = "on_todo_checked";
export const ON_TODO_ADD = "on_todo_add";
export const ON_TODO_SELECT = "on_todo_select";
export const SET_CURRENT_TODO_NULL = "set_current_todo_null";
export const REMOVE_LABEL = "remove_label";
export const UPDATE_SEARCH = "update_search";

// App Data
export const SET_USER_TIMEZONE = "set_user_timezone";
export const RESET_STATE = "reset_state";

// Trading Accounts Data - Listing
export const SET_LISTING_DATA = "set_listing_data";
export const GET_REFERAL_URL = "get_referal_url";

// Trading Accounts Data - Details

export const SET_USER_ACCOUNT_TRADING_DETAILS_DATA =
  "set_user_account_trading_details_data";
export const SET_USER_TRADING_ACCOUNT_DATA = "set_user_trading_account_data";
export const SET_USER_ACCOUNT_HISTORY_DATA = "set_user_account_history_data";
export const RESET_USER_ACCOUNT_HISTORY_DATA =
  "reset_user_account_history_data";
export const SET_USER_AUDIT_TRAIL_DATA = "set_user_audit_trail_data";
export const SET_USER_AUDIT_IDENTITY_TRAIL_DATA =
  "set_user_audit_identity_trail_data";
export const SET_USER_AUDIT_TRAIL_CATEGORY_FILTER =
  "set_user_audit_trail_category_filter";
export const SET_USER_POSITION_DATA = "set_user_position_data";
export const SET_USER_IDENTITY_DATA = "set_user_identity_data";
export const RESET_TRADING_ACCOUNT_DETAILS_DATA =
  "reset_trading_account_details_data";

export const SET_USER_BROKER_DOCUMENT_DATA = "set_user_broker_document_data";
export const SET_USER_BROKER_DOCUMENT = "set_user_broker_document";
export const SET_USER_BROKER_DOCUMENT_URL = "set_user_broker_document_url";

export const SET_USER_BROKER_DOCUMENT_ORGANIZATION_FILTER =
  "set_user_broker_document_organization_filter";
export const SET_USER_ACCOUNT_HISTORY_TRADE_STATUS_FILTER =
  "set_user_account_history_trade_status_filter";
export const SET_USER_ACCOUNT_HISTORY_OPEN_DATE_FILTER =
  "set_user_account_history_open_date_filter";
export const SET_USER_ACCOUNT_HISTORY_CLOSE_DATE_FILTER =
  "set_user_account_history_close_date_filter";
export const SET_USER_BROKER_DOCUMENT_STARTDATE_FILTER =
  "set_user_broker_document_startdate_filter";
export const SET_USER_BROKER_DOCUMENT_ENDDATE_FILTER =
  "set_user_broker_document_enddate_filter";
export const SET_USER_ACCOUNT_HISTORY_TRADE_TYPE_FILTER =
  "set_user_account_history_trade_type_filter";
export const SET_USER_IDENTITY_EMPLOYMENT = "set_user_identity_employement";
export const SET_USER_IDENTITY_TRUSTED_CONTACT =
  "set_user_identity_trusted_contact";
export const SET_USER_IDENTITY_FINANCIALS = "set_user_identity_financials";
export const SET_USER_IDENTITY_AFFILIATIONS = "set_user_identity_affiliations";
export const SET_USER_ONBOARDING_DOCUMENTS = "set_user_onboarding_documents";
export const SET_USER_ONBOARDING_PDF_DOCUMENTS =
  "set_user_onboarding_pdf_documents";
export const UPDATE_USER_ACCOUNT_DATA = "update_user_account_data";
export const UPDATE_USER_GOVERMETNTID_IMAGE = "update_user_govermentid_image";

export const SET_USER_ACCOUNT_HISTORY_ITEM = "set_account_history_item";
//export const SEARCH_USER_DETAILS = "search_user_details";
export const SET_NEWUSER_CREATE_SUCCESS = "set_newuser_create_s";
export const SET_NEWUSER_CREATE_ERROR = "set_newuser_create_error";

export const SET_EDITACCOUNT_SUCCESS = "set_editaccount_success";
export const RESET_EDITACCOUNT_SUCCESS = "reset_editaccount_success";
export const SET_EDITACCOUNT_ERROR = "set_editaccount_error";

export const UPDATE_ACCOUNTS_LISTINGDATA = "update_accounts_listingdata";
export const SET_MEMBERSHIP_INFO = "set_membership_info";
export const SET_MEMBERSHIP_INFO_UPDATED_STATUS =
  "set_membership_info_updated_status";
export const RESET_MEMBERSHIP_INFO_UPDATED_STATUS =
  "reset_membership_info_updated_status";
export const SET_USER_ROLES_DATA = "set_user_roles_data";
export const SET_USER_ROLES_UPDATE_STATUS = "set_user_roles_update_status";

export const SET_USER_REFERRAL_LINK = "set_user_referral_link";
export const SET_USER_SMARTFOLIO_INFO = "set_user_smartfolio_info";
export const SET_WITHDRAWABLE_CASH = "set_withdrawable_cash";
export const SET_TRADING_ACCOUNT_COUNT = "set_trading_account_count";

export const RESEND_EMAIL_CONFIRMATION = "resend_email_confirmation";
export const SET_DETAILS_ACTIVE_TAB = "set_details_active_tab";
export const SET_USER_SEARCH_RESULT = "user_search_result";

// Trading Account - Identity tab
export const DELETE_USER_ACCOUNT_RESPONSE = "delete_user_account_resposne";
export const DISABLE_USER_ACCOUNT_RESPONSE = "disable_user_account_response";
export const ENABLE_USER_ACCOUNT_RESPONSE = "enable_user_account_response";
export const UPLOAD_GOVERMETNT_ID_RESPONSE = "upload_goverment_id_response";
export const SET_USER_IDENTITY_DOCUMENT_CATEGORY =
  "set_user_identity_document_category";
export const SET_USER_IDENTITY_DOCUMENTS_LIST =
  "sett_user_identity_documents_list";
export const SET_USER_IDENTITY_DOCUMENT_BROKER_FILTER =
  "set_user_identity_document_broker_filter";
export const SET_USER_IDENTITY_DOCUMENT_CATEGORY_FILTER =
  "set_user_identity_document_category_filter";
export const SET_USER_IDENTITY_DOCUMENT_VELOX_VISIBILITY_FILTER =
  "set_user_identity_velox_visibility_filter";
export const SET_USER_IDENTITY_DOCUMENT_USER_VISIBILITY_FILTER =
  "set_user_identity_document_user_visibility_filter";
export const SET_USER_IDENTITY_DOCUMENT_START_DATE_FILTER =
  "set_user_identity_document_start_date_filter";
export const SET_USER_IDENTITY_DOCUMENT_END_DATE_FILTER =
  "set_user_identity_document_end_date_filter";
export const SET_USER_IDENTITY_DOCUMENT_SEARCH_FILTER =
  "set_user_identity_document_search_filter";
export const SET_USER_IDENTITY_DOCUMENT_PROCESS_STATUS =
  "set_user_identity_document_process_status";
export const SET_IS_USER_IDENTITY_DOCUMENT_PROCESSING_ENDED =
  "set_user_identity_document_processing_ended";
export const SET_IDENTITY_PDF_DOCUMENT = "set_identity_pdf_document";
export const SET_IDENTITY_UPDATE_STATUS = "set_identity_update_status";

// Operation Section
export const GET_OPERATION_INFUSIONSOFTAPIREQUEST =
  "get_operation_infusionsoftapirequest";
export const RESET_OPERATION_INFUSIONSOFTAPIREQUEST =
  "reset_operation_infusionsoftapirequest";
export const SET_REQUEST_POINT_IN_TIME_RESTORE_STATUS =
  "set_request_point_in_time_restore_status";
export const RESET_REQUEST_POINT_IN_TIME_RESTORE_STATUS =
  "reset_request_point_in_time_restore_status";
// Transfers Listing
export const GET_ALL_TRANSFERS_LISTINGS = "get_all_transfers_listings";
export const GET_SINGLE_TRANSFER_INFO = "get_single_transfer_info";
export const RESET_TRANSFER_TABLE = "reset_transfer_table";
export const OVERRIDE_TRANSFER_ID = "override_transfer_id";
export const SET_END_DATE_TRANSFERSLISTING = "set_end_date_transferListing";
export const SET_START_DATE_TRANSFERSLISTING = "set_start_date_transferListing";
export const SET_ACH_RETURN_UPDATE_STATUS = "set_ach_return_update_status";

// Trades Listing
export const GET_ALL_TRADES_LISTINGS = "get_all_trades_listing";
export const GET_ALL_ORDERS_LISTINGS = "get_all_orders_listing";
export const GET_ALL_ORDERS_BASICS = "get_all_orders_basics";
export const GET_SINGLE_TRADES_INFO = "get_single_trades_info";
export const RESET_TRADES_TABLE = "reset_trades_table";
export const SET_END_DATE_TRADESLISTING = "set_end_date_tradeslisting";
export const SET_START_DATE_TRADESLISTING = "set_start_date_tradelisting";
export const OVERRIDE_TRADE_ID = "override_trade_id";
export const SET_TRADE_RETURN_UPDATE_STATUS = "set_trade_return_update_status";
export const GET_AGGREGATE_TRADING_ACCOUNT_ORDERS_LISTINGS =
  "get_aggregate_trading_account_orders_listings";
export const GET_AGGREGATE_SYMBOL_ORDERS_LISTINGS =
  "get_aggregate_symbol_orders_listings";
export const GET_AGGREGATE_ACTION_ORDERS_LISTINGS =
  "get_aggregate_action_orders_listings";
export const GET_ALL_FIXED_ORDERS_LISTING = "get_all_fixed_orders_listing";
export const GET_ALL_EXECUTION_LISTING = "get_all_execution_reports_listing";

// Audit Trail

export const SET_AUDIT_TRAIL_CATEGORY_FILTER =
  "set_audit_trail_category_filter";
export const SET_AUDIT_TRAIL_SEARCH_FILTER = "set_audit_trail_serach_filter";

// Manage Roles
export const SET_ALL_ROLES = "set_all_roles";
export const SET_ALL_PERMISSIONS = "set_all_permissions";
export const ROLE_CREATED_STATUS = "role_created_status";
export const RESET_ROLE_CREATED_STATUS = "reset_role_created_status";
export const ROLE_DELETED_STATUS = "role_deleted_status";
export const ROLE_UPDATED_STATUS = "role_update_status";
export const SET_USERS_BY_ROLES = "set_users_by_roles";
export const SET_SEARCHED_USERS = "set_searched_users";
export const SET_USERLISTUPDATE_STATUS = "set_userlist_roles_update_status";
export const RESET_USERLISTUPDATE_STATUS = "reset_userlistupdate_status";
export const RESET_ROLEUPDATED_STATUS = "reset_roleUpdated_status";
export const RESET_ROLEDELETE_STATUS = "reset_roleDelete_status";
export const SHOW_MANAGEUSERS_DIALOG = "show_manage_users_dialog";

// User Accounts
export const USER_CREATION_STATUS = "user_creation_status";

//Compliance
export const RESET_COMPLIANCE_LIST = "reset_compliance_list";
export const SET_COMPLIANCE_LISTING_DATA = "set_compliance_listing_data";
export const GET_ALL_AFFILIATION = "get_all_AffilIations";

// Restricted Permissions
export const GET_RESTRICTED_PERMISSIONS = "get_restricted_permissions";

//NegativeSettleReport
export const SET_NegativeSettle_REPORT_DATA = "negative_settle_report_data";

//SET_RESTRICTED_ACCOUNT_DATA
export const SET_RESTRICTED_ACCOUNT_DATA = "SET_RESTRICTED_ACCOUNT_DATA";

// Reconciliation Report
//export const SET_RECONCILIATION_REPORT_USERID = "set_reconciliation_report_userid";
export const SET_RECONCILIATION_REPORT_TRADE_REPORT_DATA =
  "set_compliance_trade_report_data"; // Compliance report trade data
export const SET_GENERATE_USER_ASSET_REPORT_DATA =
  "set_generate_user_asset_report_data";
export const SET_RECONCILIATION_REPORT_POSITIONS_REPORT_DATA =
  "set_compliance_positions_report_data"; // Compliance report position data
export const SET_RECONCILIATION_REPORT_ACCOUNT_BALANCE_REPORT_DATA =
  "set_compliance_account_balance_report_data"; // Compliance report account balance data

// FlipPrice Data
export const SYMBOL_STRATEGY_PAIR = "symbol_strategy_pair";
export const SYMBOL_STRATEGY_PAIR_PARAMETERS =
  "symbol_strategy_pair_parameters";

// Restrictive Permissions
export const GET_USER_TRADINGACCOUNT_RESTRICTION =
  "get_user_trading_account_restrictions";
export const SET_USER_TRADINGACCOUNT_RESTRICTION =
  "set_user_trading_account_restrictions";

export const SET_LIST_OF_PERMISSION_GROUP = "set_list_of_permission_group";

// Restricted Account Listing

export const GET_ALL_RESTRICTED_ACCOUNTS = "get_all_restricted_accounts";

export const GET_ALL_NOTIFICATION = "get_all_notification";

export const GET_ALL_NOTIFICATION_OPTIONS = "get_all_notification_options";

export const GET_ID_NOTIFICATION = "get_id_notification";

export const GET_SYMBOLS_DATA_SEARCH = "get_symbols_data_search";

export const GET_ALL_SYMBOLS_DATA = "get_all_symbol_data";

export const GET_SYMBOLS_ID_DATA = "get_symbols_id_data";

export const GET_SYMBOLS_ROW_PER_PAGE = "get_symbol_row_per_page";

export const GET_SYMBOLS_DATA_COUNT = "get_symbol_data_count";

export const GET_SYMBOLS_DATA_PAGE_INDEX = "get_symbol_data_page_index";

//Designer page

export const SET_SMARTFOLIO_DASHBOARD_SPARK_CHART_RESPONSE_V3 =
  "set_smartfolio_dashboard_spark_chart_response_v3";

export const SET_DASHBOARD_SPARK_CHART_RESPONSE_V3 =
  "set_dashboard_spark_chart_response_v3";

export const SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V3 =
  "set_stockdashboard_spark_chart_response_v3";

export const UPDATE_WATCHLIST_CARD = "update_watchlist_card";

export const DELETE_WATCHLIST = "delete_watchlist";

export const NEW_WATCHLIST = "new_watchlist";

export const SET_COMBINED_OWNEDSTOCK_WATCHLIST_INFO_LOADING_STATUS =
  "set_combined_ownedstock_watchlist_info_loading_status";

export const SET_COMBINED_OWNEDSTOCK_WATCHLIST_INFO =
  "set_combined_ownedstock_watchlist_info";

export const SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE =
  "set_stockdashboard_spark_chart_response";
export const SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V2 =
  "set_stockdashboard_spark_chart_response_v2";

export const RESET_STOCK_CHART_DATA = "reset_stock_chart_data";

export const UPDATE_INVESTED_AMOUNT = "update_invested_amount";

export const SMARTFOLIO_LIST_DESIGNER = "smartfolio_list_Designer";

export const RESET_SEARCHED_STOCKS = "reset_searched_stocks";

export const SEARCHED_STOCKS = "searched_stocks";

export const SET_PAGE_LOCK_MESSAGE = "set_page_lock_message";

export const SMARTFOLIO_ALLOCATED_COMPONENT = "smartfolio_allocated_component";

// ***********Chart types ********************

export const SET_CHART_dATA = "set_chart_data";
