import React from "react";
import { Tooltip } from "@material-ui/core";

const formatDateTime = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleString();
};

const formatDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  return dateTime.toLocaleDateString();
};

const getActionColumn = (row, editHandler, deleteHandler) => (
  <div style={{ display: "flex" }}>
    <Tooltip title="Edit Notification">
      <img
        src={require("assets/images/edit.png")}
        alt="Edit"
        width={20}
        height={20}
        style={{ margin: 7, cursor: "pointer" }}
        onClick={() => editHandler(row.notificationMasterId)}
      />
    </Tooltip>
    <Tooltip title="Delete Notification">
      <img
        src={require("assets/images/delete.png")}
        alt="Delete"
        width={20}
        height={20}
        style={{ margin: 7, cursor: "pointer" }}
        onClick={() => deleteHandler(row.notificationMasterId)}
      />
    </Tooltip>
  </div>
);

const getTypeColumn = (row) => {
  return <div>{row}</div>;
};

const getColumnComponent = (row, type) => (
  <div>
    {type === "date" && formatDate(row)}
    {type === "datetime" && formatDateTime(row)}
    {type === "string" && (
      <Tooltip title={row}>
        <div style={{ width: "100px" }} className="ellipsis">
          {row}
        </div>
      </Tooltip>
    )}
    {type === "action" && row}
  </div>
);

export { formatDateTime, getActionColumn, getColumnComponent, getTypeColumn };
