import React from "react";
import { Redirect, Route } from "react-router-dom";
import GenerateUserAssetReports from "./Reports/GenerateUserAssetReport";

const GenerateUserAssetReport = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Redirect exact from={`${match.url}/`} to={`${match.url}`} />
      <Route path={`${match.url}`} component={GenerateUserAssetReports} />
    </div>
  );
};

export default GenerateUserAssetReport;
