import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const TradesListing = ({ match }) => {
  const authUser = useSelector(state => state.auth.authUser);
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/listing`} />
        {authUser && authUser.Claims.includes("Trade.viewList") && (
          <Route
            path={`${match.url}/listing`}
            component={asyncComponent(() => import("./Listing"))}
          />
        )}
        {authUser && authUser.Claims.includes("Trade.viewDetails") && (
          <Route
            path={`${match.url}/details`}
            component={asyncComponent(() => import("./Details"))}
          />
        )}
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default TradesListing;
