import { ACTION_STATUS } from "../util/Utility";
import { SYMBOL_STRATEGY_PAIR, SYMBOL_STRATEGY_PAIR_PARAMETERS } from "../constants/ActionTypes";

const initState = {
  priceSymbolStrategyData: [],
  priceSymbolStrategyParametersData: [],
  priceResponse: ACTION_STATUS.INITSTATE,
  priceResponseErrors: {}
};

const PriceReducer = (state = initState, action) => {
  switch (action.type) {
    case SYMBOL_STRATEGY_PAIR:
      return {
        ...state,
        priceSymbolStrategyData: action.payload,
        priceResponseErrors: action.errors
      };
    case SYMBOL_STRATEGY_PAIR_PARAMETERS:
      return {
        ...state,
        priceSymbolStrategyParametersData: action.payload,
        priceResponseErrors: action.errors
      };
    default:
      return state;
  }
};

export default PriceReducer;
