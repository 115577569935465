import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

const BankAccounts = ({ match }) => {
  const authUser = useSelector(state => state.auth.authUser);

  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/listing`} />
        {authUser && authUser.Claims.includes("bankAccount.viewList") && (
          <Route
            path={`${match.url}/listing`}
            component={asyncComponent(() => import("./routes/Listing"))}
          />
        )}
        {authUser && authUser.Claims.includes("bankAccount.viewDetails") && (
          <Route
            path={`${match.url}/account`}
            component={asyncComponent(() => import("./routes/Account"))}
          />
        )}
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default BankAccounts;
