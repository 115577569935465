// Library imports
import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Slide from "@material-ui/core/Slide";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import { Alert } from "reactstrap";

// Services and Utilities
import "./roles.css";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  paper: {
    width: 300,
    height: 230,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}

function getRolesList(roles) {
  return roles.map(role => role.name);
}

export default function ManageUsersDialog(props) {
  const classes = useStyles();

  const [roles, setRoles] = useState(getRolesList(props.roles));
  const [selectedRole, setSelectedRole] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [right, setRight] = React.useState(props.usersByRole);
  const [left, setLeft] = React.useState(
    filterSearchedUsers(props.searchedUsers)
  );

  const [checked, setChecked] = React.useState([]);
  const [showErrorAlert, setShowErrorAlert] = React.useState(false);
  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleCancel = () => {
    setLeft([]);
    setRight([]);
    setChecked([]);
    setSelectedRole("");
    setSearchTerm("");
    setShowErrorAlert(false);
    props.dialogCloseHandler();
  };

  useEffect(() => {
    setRoles(getRolesList(props.roles));
  }, [props.roles]);

  useEffect(() => {
    rightUpdateHandler(props.usersByRole);
  }, [props.usersByRole]);

  useEffect(() => {
    setLeft(filterSearchedUsers(props.searchedUsers));
  }, [props.searchedUsers]);

  function rightUpdateHandler(newList) {
    setRight(newList);
    setLeft(left.filter(item => !newList.includes(item)));
    setChecked(checked.filter(item => !newList.includes(item)));
  }

  function filterSearchedUsers(searchedUsers) {
    return searchedUsers
      .filter(user => !right.includes(user.UserName))
      .map(item => item.UserName);
  }

  const selectRoleHandler = event => {
    let selectedRole = event.target.value;
    if (selectedRole) {
      setShowErrorAlert(false);
      setSelectedRole(selectedRole);
      props.searchUserByRole(selectedRole);
    } else {
      setSelectedRole("");
      setRight([]);
    }
  };

  const searchHandler = event => {
    let searchTerm = event.target.value;
    if (searchTerm) {
      setSearchTerm(searchTerm);
      props.searchUsers(searchTerm);
    } else {
      setSearchTerm("");
      setLeft([]);
    }
  };

  const handleToggle = item => () => {
    const currentIndex = checked.findIndex(x => x === item);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight([...right, ...leftChecked]);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (items, col) => {
    return (
      <div>
        <div>
          {col === "left" ? "Unassigned users' list" : "Assgined users list"}
        </div>
        <Paper className={classes.paper}>
          <List dense component="div" role="list">
            {items.map(item => {
              const labelId = `transfer-list-item-${item}-label`;
              return (
                <ListItem
                  key={item}
                  role="listitem"
                  button
                  onClick={handleToggle(item)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.findIndex(x => x === item) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={`${item}`} />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Paper>
      </div>
    );
  };

  const submitForm = () => {
    if (selectedRole) {
      setLeft([]);
      setSearchTerm("");
      setRight([]);
      setSelectedRole("");
      props.submitForm(selectedRole, right);
    } else {
      setShowErrorAlert(true);
    }
  };

  return (
    <Dialog
      open={props.openDialog}
      TransitionComponent={Slide}
      onClose={handleCancel}
    >
      <DialogTitle>Manage Users</DialogTitle>
      <DialogContent>
        {showErrorAlert && (
          <Alert className="bg-danger shadow-lg text-white">
            Please select a Role
          </Alert>
        )}
        <FormControl className="w-100 mb-2">
          <InputLabel htmlFor="role-select">Roles</InputLabel>
          <Select
            value={selectedRole}
            onChange={selectRoleHandler}
            input={<Input id="role-select" />}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {roles.map(role => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Search Users"
          value={searchTerm}
          onInput={searchHandler}
          margin="normal"
          fullWidth
        />

        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          className={classes.root}
        >
          <Grid item>{customList(left, "left")}</Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Grid>
          </Grid>
          <Grid item>{customList(right, "right")}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={submitForm} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
