import React from "react";
import { Redirect, Route } from "react-router-dom";
import ItemNotification from "./itemNotification";

const Notification = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Redirect exact from={`${match.url}/`} to={`${match.url}`} />
      <Route path={`${match.url}`} component={ItemNotification} />
    </div>
  );
};

export default Notification;
