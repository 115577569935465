import React, { useEffect, useState } from "react";
import InfoView from "../../../../components/InfoView";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { useDispatch, useSelector } from "react-redux";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import TableComponent from "../../../../components/Table/TableComponent1";
import { useMemo } from "react";
import {
  getActionColumnSymbol,
  getColumnComponentSymbol,
  headCells,
} from "./tableHead";
import {
  getSearchSymbolData,
  getSymbolIdData,
} from "../../../../actions/Symbol";
import EditSymbol from "../editSymbols";
import {
  GET_SYMBOLS_DATA_COUNT,
  GET_SYMBOLS_DATA_PAGE_INDEX,
  GET_SYMBOLS_ROW_PER_PAGE,
} from "../../../../constants/ActionTypes";

const SearchSymbol = ({ match }) => {
  const dispatch = useDispatch();
  const {
    searchedSymbolsData,
    symbolIdData,
    symbolListingRecordsCount: totalSymbolCount = null,
    symbolListingTablePageIndex: symbolListPageIndex,
    symbolListingRowsPerPage: symbolListRowsPerPage,
  } = useSelector((state) => state.symbol);

  const { loading } = useSelector((state) => state.commonData);

  const [state, setState] = useState({
    showRoleDialog: false,
    showTable: false,
  });
  const [search, setSearch] = useState();
  const [filterData, setFilterData] = useState();

  const editDatafunction = (symbol) => {
    setState({ ...state, showRoleDialog: true });
    dispatch(getSymbolIdData(symbol));
  };

  const transformData = useMemo(() => {
    return filterData?.map((row) => ({
      symbol: getColumnComponentSymbol(row.symbol, "string"),
      cusiP_NUMBER: getColumnComponentSymbol(row.cusiP_NUMBER, "string"),
      type: getColumnComponentSymbol(row.type, "string"),
      isEnabled: getColumnComponentSymbol(row.isEnabled, "boolean"),
      isBlacklist: getColumnComponentSymbol(row.isBlacklist, "boolean"),
      isWhitelist: getColumnComponentSymbol(row.isWhitelist, "boolean"),
      isFractionable: getColumnComponentSymbol(row.isFractionable, "boolean"),
      action: getActionColumnSymbol(row, editDatafunction),
    }));
  }, [filterData]);

  const searchData = (e) => {
    setSearch(e.target.value);
  };

  const onSubmit = () => {
    dispatch(getSearchSymbolData(search));
    setState({ ...state, showTable: true });
  };

  const handleChangePage = (event) => {
    if (symbolListPageIndex < event) {
      dispatch({
        type: GET_SYMBOLS_DATA_PAGE_INDEX,
        payload: event,
      });
    } else {
      dispatch({
        type: GET_SYMBOLS_DATA_PAGE_INDEX,
        payload: event,
      });
    }
  };

  function rowPerPages(event) {
    const numRows =
      event === "All"
        ? searchedSymbolsData?.length
        : Math.min(event, searchedSymbolsData?.length);

    dispatch({
      type: GET_SYMBOLS_ROW_PER_PAGE,
      payload: numRows,
    });
  }

  useEffect(() => {
    dispatch({
      type: GET_SYMBOLS_DATA_COUNT,
      payload: searchedSymbolsData?.length,
    });
    if (symbolListRowsPerPage === searchedSymbolsData?.length) {
      setFilterData(searchedSymbolsData);
    } else {
      let data = [];
      const startIndex = symbolListPageIndex * symbolListRowsPerPage;
      const endIndex = startIndex + symbolListRowsPerPage;
      data = searchedSymbolsData?.slice(startIndex, endIndex);
      setFilterData(data);
    }
  }, [searchedSymbolsData, symbolListRowsPerPage, symbolListPageIndex]);

  return (
    <div>
      <InfoView />
      <ContainerHeader match={match} title={<IntlMessages id="Symbols" />} />
      <Paper
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{ fontSize: "22px", fontWeight: "500" }}
          gutterBottom
        >
          Assets Management
        </Typography>
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            width: "100%",
          }}
        >
          <TextField
            label="Search by Symbol, Company Name or CUSIP"
            style={{ width: "50%" }}
            type="search"
            variant="outlined"
            autoComplete="off"
            value={search}
            onChange={(e) => searchData(e)}
          />
          <Button
            variant="contained"
            onClick={() => onSubmit()}
            style={{
              backgroundColor: "#673ab7",
              color: "white",
              textTransform: "none",
              padding: "6.5px 20px",
            }}
            endIcon={<Search />}
          >
            Search
          </Button>
        </div>
      </Paper>
      {state.showTable && (
        <Paper style={{ padding: "24px", marginTop: "24px" }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" align="center" gutterBottom>
                Loading please wait ...
              </Typography>
            </div>
          ) : transformData.length > 0 ? (
            <TableComponent
              count={totalSymbolCount}
              totalRecordsCount={totalSymbolCount}
              headCells={headCells}
              tableData={transformData}
              rowsPerPage={symbolListRowsPerPage}
              rowsPerPageChangeHandler={rowPerPages}
              pageIndex={symbolListPageIndex}
              changePageHandler={handleChangePage}
            />
          ) : (
            <Typography variant="h6" align="center" gutterBottom>
              No Data Found
            </Typography>
          )}
        </Paper>
      )}
      <EditSymbol
        setShowRoleDialog={() => setState({ ...state, showRoleDialog: false })}
        openDialog={state.showRoleDialog}
        symbolData={symbolIdData}
      />
    </div>
  );
};

export default SearchSymbol;
