import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import {
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER
} from "constants/ActionTypes";
import SearchBox from "components/SearchBox";
import MailNotification from "../MailNotification/index";
import AppNotification from "../AppNotification/index";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import { setUserTimeZone } from "actions/App";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import Menu from "components/TopNav/Menu";
import UserInfoPopup from "components/UserInfo/UserInfoPopup";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import Tooltip from "@material-ui/core/Tooltip";
import "./header.css";
import { withStyles } from "@material-ui/core/styles";
import { UTC_OFFSET_ZONES } from "../../util/timeZones";

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14
  }
}))(Tooltip);

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    });
  };

  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    });
  };

  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };

  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    });
  };

  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    });
  };

  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    });
  };

  onTimeZoneSelect = () => {
    this.setState({
      timeZone: !this.state.timeZone
    });
  };

  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };

  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/calendar/basic">
            <i className="zmdi zmdi-calendar zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.calendar.basic" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.toDo" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.mail" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.chat" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw" />
            <span className="jr-list-text">
              <IntlMessages id="sidebar.appModule.contact" />
            </span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw" />
            <span className="jr-list-text">Add New</span>
          </Link>
        </li>
      </ul>
    );
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: "",
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      timeZone: false,
      tzSearchText: "",
      offsetTmz: [],
      userTimeZone: props.userTimeZone
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { ...prevState, userTimeZone: nextProps.userTimeZone };
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value
    });
  }

  searchTimeZones = event => {
    let searchText = event.target.value;
    this.setState({ tzSearchText: searchText });

    searchText = searchText.trim().toLowerCase();

    let filteredTz = UTC_OFFSET_ZONES.filter(x =>
      x.toLowerCase().includes(searchText)
    );

    this.setState({ offsetTmz: filteredTz });
  };

  getTZ() {
    if (this.state.tzSearchText.trim().length > 0) {
      const { offsetTmz, userTimeZone } = this.state;
      let filteredTzs = [];

      for (let i = 0; i < offsetTmz.length; i++) {
        filteredTzs.push(
          <li
            key={i}
            className={`jr-list-item ${
              offsetTmz[i].includes(userTimeZone) ? "active-timezone" : ""
            }`}
            onClick={() => {
              this.props.setUserTimeZone(
                offsetTmz[i],
                offsetTmz[i].slice(3, 9)
              );
              this.setState({ tzSearchText: "" });
              setTimeout(() => {
                this.setState({ timeZone: false });
              }, 500);
            }}
          >
            {offsetTmz[i]}
          </li>
        );
      }

      return filteredTzs;
    } else {
      let offsetTmz = [];
      for (let i = 0; i < UTC_OFFSET_ZONES.length; i++) {
        offsetTmz.push(
          <li
            key={i}
            className={`jr-list-item ${
              UTC_OFFSET_ZONES[i].includes(this.state.userTimeZone)
                ? "active-timezone"
                : ""
            }`}
            onClick={() => {
              this.props.setUserTimeZone(
                UTC_OFFSET_ZONES[i],
                UTC_OFFSET_ZONES[i].slice(3, 9)
              );
              setTimeout(() => {
                this.setState({ timeZone: false });
              }, 500);
            }}
          >
            {UTC_OFFSET_ZONES[i]}
          </li>
        );
      }
      return offsetTmz;
    }
  }

  render() {
    const {
      drawerType,
      locale,
      navigationStyle,
      horizontalNavPosition
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "d-block d-xl-none"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "d-block"
      : "d-none";

    return (
      <AppBar
        className={`app-main-header ${
          navigationStyle === HORIZONTAL_NAVIGATION &&
          horizontalNavPosition === BELOW_THE_HEADER
            ? "app-main-header-top"
            : ""
        }`}
      >
        <Toolbar className="app-toolbar" disableGutters={false}>
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div
              className="d-block d-md-none pointer mr-3"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon" />
            </IconButton>
          )}

          <Link className="app-logo mr-2 d-none d-sm-block" to="/">
            <img
              src={require("assets/images/logo.png")}
              alt="iflip admin"
              title="iFlip Admin Home"
            />
          </Link>

          {/* <SearchBox
            styleName="d-none d-lg-block"
            placeholder=""
            onChange={this.updateSearchText.bind(this)}
            value={this.state.searchText}
          /> */}
          {navigationStyle === HORIZONTAL_NAVIGATION &&
            horizontalNavPosition === INSIDE_THE_HEADER && <Menu />}

          <ul className="header-notifications list-inline ml-auto timeZone-list">
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.timeZone}
                toggle={this.onTimeZoneSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <LightTooltip
                    disableFocusListener
                    title={this.state.userTimeZone}
                  >
                    <AccessTimeIcon />
                  </LightTooltip>
                </DropdownToggle>

                <DropdownMenu right className="w-50 timezone-menu">
                  <Input
                    placeholder="Search time zones"
                    value={this.state.tzSearchText}
                    onChange={this.searchTimeZones}
                    className="search-timezone"
                  />
                  <ul className="jr-list jr-list-half all-tzs">
                    {this.getTZ()}
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu app-notification"
                isOpen={this.state.apps}
                toggle={this.onAppsSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg" />
                    <span>Apps</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu>{this.Apps()}</DropdownMenu>
              </Dropdown>
            </li>
            <li className="d-inline-block d-lg-none list-inline-item">
              <Dropdown
                className="quick-menu nav-searchbox"
                isOpen={this.state.searchBox}
                toggle={this.onSearchBoxSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-search zmdi-hc-fw" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="p-0">
                  <SearchBox
                    styleName="search-dropdown"
                    placeholder=""
                    onChange={this.updateSearchText.bind(this)}
                    value={this.state.searchText}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.langSwitcher}
                toggle={this.onLangSwitcherSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`} />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher
                    switchLanguage={this.props.switchLanguage}
                    handleRequestClose={this.handleRequestClose}
                  />
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.appNotification}
                toggle={this.onAppNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-notifications-none icon-alert animated infinite wobble" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center"
                    heading={<IntlMessages id="appNotification.title" />}
                  />
                  <AppNotification />
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item mail-tour">
              <Dropdown
                className="quick-menu"
                isOpen={this.state.mailNotification}
                toggle={this.onMailNotificationSelect.bind(this)}
              >
                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown"
                >
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw" />
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader
                    styleName="align-items-center"
                    heading={<IntlMessages id="mailNotification.title" />}
                  />
                  <MailNotification />
                </DropdownMenu>
              </Dropdown>
            </li>

            {navigationStyle === HORIZONTAL_NAVIGATION && (
              <li className="list-inline-item user-nav">
                <Dropdown
                  className="quick-menu"
                  isOpen={this.state.userInfo}
                  toggle={this.onUserInfoSelect.bind(this)}
                >
                  <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown"
                  >
                    <IconButton className="icon-btn size-30">
                      <Avatar
                        alt="..."
                        src={"https://via.placeholder.com/150x150"}
                        className="size-30"
                      />
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right>
                    <UserInfoPopup />
                  </DropdownMenu>
                </Dropdown>
              </li>
            )}
          </ul>

          <div className="ellipse-shape"></div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  const {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition
  } = state.settings;
  const { userTimeZone } = state.app;
  return {
    drawerType,
    locale,
    navigationStyle,
    horizontalNavPosition,
    userTimeZone
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    { toggleCollapsedNav, switchLanguage, setUserTimeZone }
  )(Header)
);
