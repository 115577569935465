import moment from "moment";

import {
  RESET_SEARCHED_STOCKS,
  RESET_STOCK_CHART_DATA,
  SEARCHED_STOCKS,
  SET_PAGE_LOCK_MESSAGE,
  SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE,
  SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V2,
  SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V3,
  SMARTFOLIO_ALLOCATED_COMPONENT,
  SMARTFOLIO_LIST_DESIGNER,
  UPDATE_INVESTED_AMOUNT,
} from "../constants/ActionTypes";
import {
  GroupByDate,
  getPreviousDayCloseValue,
} from "../app/routes/symbolStrategies/designer/helper";

const initState = {
  // ****Smartfolio Dashboard Page ****
  stockDashboardChartStockData: {},
  stockDashboardChartStockDataV2: {},
  ownedAllocation: {},
  smartfolioListDesignerPage: [
    {
      smartfolioId: 165,
      active: true,
      isCoreStrategy: false,
      symbol: "AAPL",
      startTime: "2023-10-04T20:30:48.6",
      performDownload: false,
      aggressiveness: 0,
      membershipTypeId: 0,
      membershipType: "Unknown",
      aggressivenessLabel: "Conservative",
      category: "Regular",
    },
    {
      smartfolioId: 6,
      active: true,
      isCoreStrategy: false,
      symbol: "Red Oil",
      startTime: "2020-04-01T00:00:00",
      minimumInvested: 500,
      title: "Red Oil Smartfolio Explained",
      description:
        "RED OIL is composed of well known energy related companies. If a user believes that demand for oil will increase over time from current levels then this is a Smartfolio they may want to evaluate for their portfolio and risk tolerances.",
      performDownload: true,
      aggressiveness: 8,
      membershipTypeId: 2,
      membershipType: "Lite_Legacy",
      marketingName: "red_oil",
      aggressivenessLabel: "Moderate+",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/add-red-oil-square.svg",
      category: "Regular",
    },
    {
      smartfolioId: 10,
      active: true,
      isCoreStrategy: true,
      symbol: "Conservative",
      startTime: "2004-01-01T00:00:00",
      minimumInvested: 500,
      title: "Conservative Smartfolio Explained",
      description:
        "Lower-risk investing to protect wealth, using dividends to maintain performance.",
      performDownload: true,
      aggressiveness: 5,
      membershipTypeId: 3,
      membershipType: "Advanced_Legacy",
      marketingName: "conservative_plus",
      aggressivenessLabel: "Conservative+",
      wideImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-conservative-wide.svg",
      squareImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-conservative.svg",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-conservative-no-text.svg",
      category: "Core",
    },
    {
      smartfolioId: 13,
      active: true,
      isCoreStrategy: true,
      symbol: "Balanced",
      startTime: "2004-01-01T00:00:00",
      minimumInvested: 500,
      title: "Balanced Smartfolio Explained",
      description:
        "Weighing risk and return equally to attempt to achieve stability and performance.",
      performDownload: true,
      aggressiveness: 8,
      membershipTypeId: 3,
      membershipType: "Advanced_Legacy",
      marketingName: "mod_plus",
      aggressivenessLabel: "Moderate+",
      wideImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-balanced-wide.svg",
      squareImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-balanced.svg",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-balanced-no-text.svg",
      category: "Core",
    },
    {
      smartfolioId: 3,
      active: true,
      isCoreStrategy: false,
      symbol: "SPY+",
      startTime: "2004-01-01T00:00:00",
      minimumInvested: 500,
      title: "SPY+ Smartfolio Explained",
      description:
        "SPY+ is targeted at a 60% allocation to the SPY ETF (an index fund of the S&P 500 stocks) and a 40% allocation to Apple Inc.",
      performDownload: true,
      aggressiveness: 8,
      membershipTypeId: 1,
      membershipType: "Free_Legacy",
      marketingName: "spy_plus",
      aggressivenessLabel: "Moderate+",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/add-spy-plus-square.svg",
      category: "Regular",
    },
    {
      smartfolioId: 9,
      active: true,
      isCoreStrategy: true,
      symbol: "Aggressive",
      startTime: "2012-06-01T00:00:00",
      minimumInvested: 500,
      title: "Aggressive Smartfolio Explained",
      description: "Investing to increase returns while tolerating more risk.",
      performDownload: true,
      aggressiveness: 9,
      membershipTypeId: 4,
      membershipType: "Pro_Legacy",
      marketingName: "aggressive",
      aggressivenessLabel: "Aggressive",
      wideImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-aggressive-wide.svg",
      squareImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-aggressive.svg",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/smartfolio-aggressive-no-text.svg",
      category: "Core",
    },
    {
      smartfolioId: 12,
      active: true,
      isCoreStrategy: false,
      symbol: "Big Tech",
      startTime: "2012-06-01T00:00:00",
      minimumInvested: 500,
      title: "Big Tech Smartfolio Explained",
      description:
        "Big Tech is targeted at an equal allocation of Meta, Apple Inc, Amazon, Netflix & Google.  They are largely responsible for the Nasdaq's rise in recent years. A portfolio consisting of these has a high 'beta' - meaning that as the market advances these stocks will likely advance more. But the contrary is also true - if the market falls they will likely fall more. A portfolio constructed like this is highly aggressive. You need to ask yourself...are you comfortable with the potential for increased volatility.",
      performDownload: true,
      aggressiveness: 10,
      membershipTypeId: 2,
      membershipType: "Lite_Legacy",
      marketingName: "faang",
      aggressivenessLabel: "Aggressive+",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/add-big-tech-square.svg",
      category: "Regular",
    },
    {
      smartfolioId: 1,
      active: true,
      isCoreStrategy: false,
      symbol: "4Q Rotate",
      startTime: "2020-01-01T00:00:00",
      minimumInvested: 500,
      title: "4Q Rotate Smartfolio Explained",
      description:
        "4Q Rotate adjusts based on the highest 'directional beta' symbols in the SmartFolios traded stocks based on the previous quarter. Every quarter the mix of stocks will likely change. This is an aggressive Smartfolio and will experience greater volatility (ups and downs) than other more conservative Smartfolios. You need to ask yourself...are you comfortable with the potential for increased volatility.",
      performDownload: true,
      aggressiveness: 9,
      membershipTypeId: 4,
      membershipType: "Pro_Legacy",
      marketingName: "fourq_rotate",
      aggressivenessLabel: "Aggressive",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/add-4qr-square.svg",
      category: "Regular",
    },
    {
      smartfolioId: 8,
      active: true,
      isCoreStrategy: false,
      symbol: "S&P 500",
      startTime: "2004-01-01T00:00:00",
      minimumInvested: 500,
      title: "SP500 Smartfolio Explained",
      description:
        "S&P 500 underscores what iFlip offers its community. This Smartfolio is made of 100% of the SPY ETF (an index fund of the S&P 500 stocks). The SP500 Smartfolio tactically switches between cash and ownership which attempts to smooth out the journey of a simple Buy and Hold strategy.",
      performDownload: true,
      aggressiveness: 7,
      membershipTypeId: 2,
      membershipType: "Lite_Legacy",
      marketingName: "sp_fivehundred",
      aggressivenessLabel: "Moderate",
      squareNoTextImageUrl:
        "https://flipappstpublicn7dev.blob.core.windows.net/images/smartfolio/add-sp-500-square.svg",
      category: "Regular",
    },
  ],
  searchedStocks: {},
  pageLockMessage: "",
  smarfolioAllocatedComponent: [],
};

const designer = (state = initState, action) => {
  switch (action.type) {
    case SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE: {
      const { payload } = action;
      const dashboardChartStockData = { ...state.stockDashboardChartStockData };

      return {
        ...state,
        stockDashboardChartStockData: getChartSeriesV1(
          dashboardChartStockData,
          payload
        ),
      };
    }

    case SMARTFOLIO_LIST_DESIGNER: {
      return { ...state, smartfolioListDesignerPage: action.payload };
    }

    case RESET_SEARCHED_STOCKS: {
      return { ...state, searchedStocks: action.payload };
    }

    case SEARCHED_STOCKS: {
      return { ...state, searchedStocks: action.payload };
    }

    case SMARTFOLIO_ALLOCATED_COMPONENT: {
      return { ...state, smarfolioAllocatedComponent: action.payload };
    }

    case SET_PAGE_LOCK_MESSAGE:
      return {
        ...state,
        pageLockMessage: action.payload,
      };

    case SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V2: {
      const { payload } = action;
      const dashboardChartStockData = {
        ...state.stockDashboardChartStockDataV2,
      };

      return {
        ...state,
        stockDashboardChartStockDataV2: getChartSeriesV2(
          dashboardChartStockData,
          payload
        ),
      };
    }
    case SET_STOCKDASHBOARD_SPARK_CHART_RESPONSE_V3: {
      const { payload } = action;

      return {
        ...state,
        stockDashboardChartStockData: payload,
      };
    }
    case UPDATE_INVESTED_AMOUNT: {
      return { ...state, updatedInvestedAccount: action.payload };
    }
    case RESET_STOCK_CHART_DATA: {
      return {
        ...state,
        // dashboardChartStockData: "",
        stockDashboardChartStockData: "",
        stockDashboardChartStockDataV2: "",
        stockCardCompanyInfo: "",
        stockCardStockInfo: "",
        stockPositionData: "",
        stockCardNewsValue: "",
        stockHistoryData: "",
        stockCardPriceValue: "",
        symbolInfoCombinedData: {},
        stockCardInfoFetched: true,
      };
    }
    default:
      return state;
  }
};

export const getChartSeriesV1 = (ChartData, payload) => {
  let bigChartSeries = ChartData.PriceSeries || [];

  let { CurrentMetricValue } = ChartData;
  switch (payload.Command) {
    case "Reset":
      bigChartSeries = payload.PriceSeries.map((val) => ({
        x: moment.utc(val[0]),
        y: val[1],
      }));
      CurrentMetricValue = bigChartSeries[bigChartSeries.length - 1] || 0;
      break;
    default:
      break;
  }

  return {
    PriceSeries: bigChartSeries,
    CurrentMetricValue,
    PreviousDayCloseValue: getPreviousDayCloseValue(bigChartSeries),
    GroupedPriceSeries: GroupByDate(bigChartSeries),
  };
};

export const getChartSeriesV2 = (ChartData, payload) => {
  let intradayPriceSeries = ChartData.IntradayPriceSeries || [];
  let dailyPriceSeries = ChartData.DailyPriceSeries || [];
  let intradayGroupedPriceSeries = ChartData.IntradayGroupedPriceSeries || [];
  let dailyGroupedPriceSeries = ChartData.DailyGroupedPriceSeries || [];
  let intradayCurrentMetricValue = ChartData.IntradayCurrentMetricValue || 0;
  let dailyCurrentMetricValue = ChartData.DailyCurrentMetricValue || 0;
  let intradayPreviousDayCloseValue =
    ChartData.IntradayPreviousDayCloseValue || 0;
  let dailyPreviousDayCloseValue = ChartData.DailyPreviousDayCloseValue || 0;

  if (payload?.daily) {
    const momentifiePriceSeries = payload?.daily.map((val) => ({
      x: moment.utc(val[0]),
      y: val[1],
    }));
    dailyPriceSeries = momentifiePriceSeries;
    dailyCurrentMetricValue =
      dailyPriceSeries[dailyPriceSeries.length - 1] || 0;
    dailyPreviousDayCloseValue = getPreviousDayCloseValue(dailyPriceSeries);
    dailyGroupedPriceSeries = GroupByDate(dailyPriceSeries);
  }

  if (payload?.dailyCash) {
    const momentifiePriceSeries = payload?.dailyCash.map((val) => ({
      x: moment.utc(val[0]),
      y: val[1],
    }));
    intradayPriceSeries = momentifiePriceSeries;
    intradayCurrentMetricValue =
      dailyPriceSeries[dailyPriceSeries.length - 1] || 0;
    intradayPreviousDayCloseValue = getPreviousDayCloseValue(dailyPriceSeries);
    intradayGroupedPriceSeries = GroupByDate(intradayPriceSeries);
  }

  return {
    IntradayPriceSeries: intradayPriceSeries,
    DailyPriceSeries: dailyPriceSeries,
    IntradayGroupedPriceSeries: intradayGroupedPriceSeries,
    DailyGroupedPriceSeries: dailyGroupedPriceSeries,
    IntradayCurrentMetricValue: intradayCurrentMetricValue,
    DailyCurrentMetricValue: dailyCurrentMetricValue,
    IntradayPreviousDayCloseValue: intradayPreviousDayCloseValue,
    DailyPreviousDayCloseValue: dailyPreviousDayCloseValue,
  };
};

export default designer;
