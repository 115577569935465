import {
  RESET_STATE,
  GET_ALL_TRADES_LISTINGS,
  GET_SINGLE_TRADES_INFO,
  RESET_TRADES_TABLE,
  SET_START_DATE_TRADESLISTING,
  SET_END_DATE_TRADESLISTING,
  OVERRIDE_TRADE_ID,
  SET_TRADE_RETURN_UPDATE_STATUS
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  ACTIVE_FILTER_DROPDOWN
} from "../util/Utility";

const initState = {
  // account listing info
  tradesListingData: [],
  tradesListingRecordsCount: 0,
  tradesListingTotalRecordsCount: 0,
  tradesListingSearchTerm: "",
  tradesListingTablePageIndex: 0,
  tradesListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  tradesListingOrderBy: "CreatedDate",
  tradesListingOrder: "desc",
  tradesListingFilter: FILTER_OPTIONS.SHOWALL,
  tradeStatusFilter: [],
  tradeStockTypeFilter:"",
  tradeisActiveFilter:ACTIVE_FILTER_DROPDOWN.ACTIVE,
  tradeisBuyOrSellFilter:"",
  startDate: null,
  endDate: null,

  // Single bank account
  tradesData: "",
  tradeReturnUpdateStatus:""
};

const tradesListing = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_ALL_TRADES_LISTINGS: {
      //*********** */

      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER:
          stateDataHelper = {
            tradesListingSearchTerm: action.stateData,
            tradesListingTablePageIndex: 0
          };

          break;

        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            tradesListingTablePageIndex: action.stateData
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            tradesListingRowsPerPage: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            tradesListingOrderBy: orderData[0],
            tradesListingOrder: orderData[1],
            tradestranferListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX:
        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            tradesListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            tradesListingFilter: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            tradeisActiveFilter: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.BUY_SELL_FILTER: {
          stateDataHelper = {
            tradeisBuyOrSellFilter: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;         
        }

        case LISTING_REQ_ORIGIN.STATUS_FILTER: {
          stateDataHelper = {
            tradeStatusFilter: action.stateData,
            listingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.STOCK_TYPE_FILTER: {
          stateDataHelper = {
            tradeStockTypeFilter: action.stateData,
            listingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            startDate: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;
        }

        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            endDate: action.stateData,
            tradesListingTablePageIndex: 0
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            tradesListingTablePageIndex: 0,
          };
          break;
        }
		

        default:
          break;
      }

      return {
        ...state,
        tradesListingData: action.data,
        tradesListingRecordsCount: action.totalMatch,
        tradesListingTotalRecordsCount: action.total,
        ...stateDataHelper
      };

      //************ */
    }
    case GET_SINGLE_TRADES_INFO: {
      return { ...state, tradesData: action.payload };
    }
    case RESET_TRADES_TABLE: {
      return { ...initState };
    }
    case OVERRIDE_TRADE_ID: {
      let listingData = [...state.tradesListingData];
      let index = listingData.findIndex(
        x => x.TradeId === action.payload.TradeId
      );
      if (index !== -1) {
        listingData[index] = action.payload;
      }
      return { ...state, tradesListingData: listingData };
    }
    
    case SET_END_DATE_TRADESLISTING: {
      return { ...state, endDate: action.payload };
    }

    case SET_START_DATE_TRADESLISTING: {
      return { ...state, startDate: action.payload };
    }

    case RESET_STATE: {
      return { ...initState };
    }

    case SET_TRADE_RETURN_UPDATE_STATUS:{
     
      return {...state, tradeReturnUpdateStatus:action.payload};
    }

    default:
      return state;
  }
};

export default tradesListing;
