import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Alert } from "reactstrap";

import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import { userSignIn, resetUnauthorizedLogin } from "actions/Auth";
import axios from "../util/Api";
import { setThemeColor } from "../actions/Setting";
import { DARK_DEEP_ORANGE, DARK_DEEP_PURPLE } from "../constants/ThemeColors";
import logo from "../assets/images/logo.png";
import { setUserServer } from "../util/localStorageService";
import {
  USER_SERVER,
  ACTION_STATUS,
  USER_SERVER_PREFIX,
} from "../util/Utility";
import { configureSweetAlert } from "../util/Utility";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameStatus: {
        touched: false,
        isValid: true,
      },
      passwordStatus: {
        touched: false,
        isValid: true,
      },
      showAlert: false,
      alertMessage: "",
      sweetAlert: configureSweetAlert(),
    };
  }

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.unauthorizedLogin === ACTION_STATUS.SUCCESS) {
      return {
        ...prevState,
        showAlert: true,
        alertMessage: "You are not authorized to login!",
      };
    }
    return prevState;
  }

  // Validdation is only for "required". Password Length is not validated.
  validateFields = (event) => {
    const id = event.target.id;
    const fieldValue = event.target.value;

    if (fieldValue.trim().length <= 0) {
      this.setState({ [`${id}Status`]: { touched: true, isValid: false } });
    } else {
      this.setState({ [`${id}Status`]: { touched: true, isValid: true } });
    }
  };

  getFieldErrorStatus = (id) => {
    const fieldState = `${id}Status`;
    return this.state[fieldState].touched && !this.state[fieldState].isValid
      ? true
      : false;
  };

  handleThemeColor = (colorCode) => {
    //const currentThemeColor = this.props.themeColor;
    const body = document.body.classList;
    // body.remove(currentThemeColor);
    // body.remove("dark-theme");
    while (body.length > 0) {
      body.remove(body.item(0));
    }
    body.add(colorCode);
    this.props.setThemeColor(colorCode);
  };

  submitForm = () => {
    let determinedPrefix = "";
    let baseURL = "";
    let { username, password } = this.state;
    if (username.trim().length > 0 && password.trim().length > 0) {
      this.setState({ showAlert: false, alertMessage: "" });
      const regExpArray = [
        new RegExp(/^[#].*$/),
        new RegExp(/^[*].*$/),
        new RegExp(/^[&].*$/),
        new RegExp(/^[%].*$/),
      ];
      regExpArray.forEach((regExp, i) => {
        if (regExp.test(username)) {
          determinedPrefix = USER_SERVER_PREFIX[i];
        }
      });

      switch (determinedPrefix) {
        case "":
          baseURL = "https://flipapi-prod.iflipinvest.com";
          this.handleThemeColor(DARK_DEEP_ORANGE);
          setUserServer(USER_SERVER.PROD);
          break;
        case "#":
          baseURL = "https://localhost:44350";
          username = username.slice(1);
          this.handleThemeColor(DARK_DEEP_PURPLE);
          setUserServer(USER_SERVER.LOCAL);
          break;
        case "*":
          baseURL = "https://flipapi-qa.iflipinvest.com";
          username = username.slice(1);
          this.handleThemeColor(DARK_DEEP_PURPLE);
          setUserServer(USER_SERVER.QA);
          break;
        case "&":
          baseURL = "https://flipapi-dev.iflipinvest.com";
          username = username.slice(1);
          this.handleThemeColor(DARK_DEEP_PURPLE);
          setUserServer(USER_SERVER.DEV);
          break;
        case "%":
          baseURL = "https://flipapi-dev2.iflipinvest.com";
          username = username.slice(1);
          this.handleThemeColor(DARK_DEEP_PURPLE);
          setUserServer(USER_SERVER.DEVALPHA);
          break;
        default:
          break;
      }
      axios.defaults.baseURL = baseURL;
      this.props.userSignIn({
        username,
        password,
        history: this.props.history,
      });
    } else {
      this.setState({
        showAlert: true,
        alertMessage: `Both "username" and "password" are required to login`,
      });
    }
  };

  closeSweetAlert = () => {
    this.setState({
      sweetAlert: { ...this.state.sweetAlert, showSweetAlert: false },
    });
  };

  render() {
    const { username, password } = this.state;

    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        {this.state.showAlert && (
          <Alert
            style={{
              position: "absolute",
              bottom: "20px",
              margin: "auto",
              padding: "10px 24px",
            }}
            className="shadow-lg"
            color="danger"
          >
            {this.state.alertMessage}
          </Alert>
        )}

        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={logo} alt="jambo" id="signin-logo" title="jambo" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.signin" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="pages.username" />}
                    error={this.getFieldErrorStatus("username")}
                    fullWidth
                    id="username"
                    onBlur={this.validateFields}
                    onChange={(event) =>
                      this.setState({ username: event.target.value })
                    }
                    defaultValue={username}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    error={this.getFieldErrorStatus("password")}
                    fullWidth
                    id="password"
                    onBlur={this.validateFields}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={this.submitForm}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                    {/* <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link> */}
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        <InfoView />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUser: state.auth.authUser,
    themeColor: state.settings.themeColor,
    unauthorizedLogin: state.auth.unauthorizedLogin,
  };
};

export default connect(
  mapStateToProps,
  { userSignIn, setThemeColor, resetUnauthorizedLogin }
)(SignIn);
