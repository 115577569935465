import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import BankAccount from "./BankAccounts";
import CashMovement from "./CashMovement";
import TradesListing from "./Trades";
import OrdersListing from "./Orders";
import Price from "./Price";
import UserAccounts from "./UserAccounts";
import Compliance from "./Compliance";
import Operations from "./Operations";
import ReconciliationReport from "./ReconciliationReport";
import Roles from "./Roles";
import App from "./App";
import Accounts from "../reducers/TradingAccounts";
import RestrictedPermissions from "../reducers/RestrictedPermissions";
import RestrictedAccount from "./RestrictedAccounts";
import GenerateUserAssetReport from "../reducers/GenerateUserAssetReport";
import Notifications from "../reducers/Notification";
import Symbols from "./Symbols";
import Designer from "./Designer";
import Charts from "./Chart";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    app: App,
    auth: Auth,
    commonData: Common,
    tradingAccounts: Accounts,
    bankAccounts: BankAccount,
    transferListing: CashMovement,
    tradesListing: TradesListing,
    ordersListing: OrdersListing,
    price: Price,
    roles: Roles,
    userAccounts: UserAccounts,
    compliance: Compliance,
    reconciliationReport: ReconciliationReport,
    restrictedPermissions: RestrictedPermissions,
    operations: Operations,
    restrictedAccounts: RestrictedAccount,
    userAssetReportData: GenerateUserAssetReport,
    notification: Notifications,
    symbol: Symbols,
    designer: Designer,
    chart: Charts,
  });
