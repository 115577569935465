import React from "react";
import { Redirect, Route } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const Smartfolio = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Redirect
        exact
        from={`${match.url}/`}
        to={`${match.url}/SymbolStrategies`}
      />
      <Route
        path={`${match.url}/designer`}
        component={asyncComponent(() => import("./routes/designer"))}
      />
      <Route
        path={`${match.url}/symbolStrategies`}
        component={asyncComponent(() => import("./routes/SymbolStrategies/"))}
      />
      <Route
        path={`${match.url}/position`}
        component={asyncComponent(() => import("./routes/Positions/"))}
      />
      <Route
        path={`${match.url}/overrides`}
        component={asyncComponent(() => import("./routes/Overrides"))}
      />
    </div>
  );
};

export default Smartfolio;
