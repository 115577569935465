import {
  RESET_STATE,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_UNAUTHORIZED_LOGIN,
  SET_IMPERSONATED_TOKEN,
} from "../constants/ActionTypes";

import { getAccessToken } from "../util/localStorageService";
import { ACTION_STATUS } from "../util/Utility";

const INIT_STATE = {
  token: getAccessToken(),
  initURL: "",
  authUser: JSON.parse(localStorage.getItem(USER_DATA)),
  unauthorizedLogin: ACTION_STATUS.INITSTATE,
  impersonatedToken: "",
  impersonatedRefreshToken: "",
  impersonatedTokenExpiresIn: 0
};

export default (state = { ...INIT_STATE }, action) => {
  switch (action.type) {
    case INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: "",
      };
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case USER_UNAUTHORIZED_LOGIN: {
      return { ...state, unauthorizedLogin: action.payload };
    }

    case RESET_STATE: {
      return { ...INIT_STATE };
    }

    case SET_IMPERSONATED_TOKEN: {
      return {
        ...state,
        impersonatedToken: action.payload.access_token,
        impersonatedRefreshToken: action.payload.refresh_token,
        impersonatedTokenExpiresIn: action.payload.expires_in
      };
    }

    default:
      return state;
  }
};
