import axios from "util/Api";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_ALL_NOTIFICATION,
  GET_ALL_NOTIFICATION_OPTIONS,
  GET_ID_NOTIFICATION,
  SHOW_MESSAGE,
} from "../constants/ActionTypes";
import { LISTING_REQ_ORIGIN } from "../util/Utility";

export const sKey = "c9ca1b82-af1f-4c01-9f1e-aa982957ae3f1";

export const getAllNotifications = () => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  let url = `/api/v2/Notification`;

  axios
    .get(url)
    .then(({ data }) => {
      dispatch({
        type: GET_ALL_NOTIFICATION,
        payload: data,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on getting All Notification data. ${error}`,
      });
    });
};

function notificationsType(inapp, pushNotification) {
  if (inapp === true && pushNotification === true) {
    return "Both";
  } else if ((pushNotification === true && inapp === false) || inapp === null) {
    return "Push";
  } else if (
    pushNotification === false ||
    (pushNotification === null && inapp === true)
  ) {
    return "InApp";
  } else {
    return "NotSet";
  }
}

function removeBlankAndNullValues(obj) {
  for (const key in obj) {
    if (obj[key] === "" || obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
}

export const createNotifications = (data) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });
  let url = `/api/v2/Notification`;

  const payload = {
    title: data.title,
    description: data.description,
    startDateUtc: data.startDate,
    endDateUtc: data.endDate,
    targetAudience: data.targetAudience,
    route: data.routeTypes,
    action: data.actionType,
    routeParams: data.routeParams,
    externalUrl: data.externalUrl,
    hideAllowedAfter: data.hideAllowAfter,
    notificationType: notificationsType(data.inApp, data.pushNotification),
    textContent: data.textContent,
    htmlContent: data.htmlContent,
    modalGraphicUrl: data.modalGraphicUrl,
    color: data.colorOfBanner,
    sequence: data.colorSequence,
    icon: data.icon,
    actionText: data.actionText,
    modalActionText: data.modalActionText,
    modalDismissText: data.dismissText,
  };

  axios
    .post(url, removeBlankAndNullValues(payload))
    .then(({ data }) => {
      dispatch(getAllNotifications());
      dispatch({
        type: SHOW_MESSAGE,
        payload: `Notification Created Sucessfully`,
      });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on Create Notification. ${error}`,
      });
    });
};

export const updateNotifications = (id, data) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  const payload = {
    notificationMasterId: id,
    title: data.title,
    description: data.description,
    startDateUtc: data.startDate,
    endDateUtc: data.endDate,
    targetAudience: data.targetAudience,
    route: data.routeTypes,
    action: data.actionType,
    routeParams: data.routeParams,
    externalUrl: data.externalUrl,
    hideAllowedAfter: data.hideAllowAfter,
    notificationType: notificationsType(data.inApp, data.pushNotification),
    textContent: data.textContent,
    htmlContent: data.htmlContent,
    modalGraphicUrl: data.modalGraphicUrl,
    color: data.colorOfBanner,
    sequence: data.colorSequence,
    icon: data.icon,
    actionText: data.actionText,
    modalActionText: data.modalActionText,
    modalDismissText: data.dismissText,
  };

  let url = `/api/v2/Notification/${id}`;

  axios
    .put(url, removeBlankAndNullValues(payload))
    .then(({ data }) => {
      dispatch({
        type: GET_ID_NOTIFICATION,
        payload: null,
      });
      dispatch(getAllNotifications());
      dispatch({
        type: SHOW_MESSAGE,
        payload: `Notification Updated Sucessfully`,
      });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on Editing Notification . ${error.validationErrors[0].messages}`,
      });
    });
};

export const deleteNotifications = (id) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  let url = `/api/v2/Notification/${id}`;

  axios
    .delete(url)
    .then(({ data }) => {
      dispatch(getAllNotifications());
      dispatch({
        type: SHOW_MESSAGE,
        payload: `Notification Deleted Sucessfully`,
      });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on Delete Notification . ${error}`,
      });
    });
};

export const getNotificationsOptions = () => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  let url = `/api/v2/Notification/selectables`;

  axios
    .get(url)
    .then(({ data }) => {
      dispatch({
        type: GET_ALL_NOTIFICATION_OPTIONS,
        payload: data,
      });
      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured on getting Notification Dropdown. ${error}`,
      });
    });
};

export const getNotificationsById = (id) => (dispatch) => {
  if (origin !== LISTING_REQ_ORIGIN.SEARCH) dispatch({ type: FETCH_START });

  let url = `/api/v2/Notification/${id}`;

  axios
    .get(url)
    .then(({ data }) => {
      dispatch({
        type: GET_ID_NOTIFICATION,
        payload: data,
      });

      dispatch({ type: FETCH_SUCCESS });
    })
    .catch(function(error) {
      dispatch({
        type: FETCH_ERROR,
        payload: `An error occured when fetching this ${id}  Notification . ${error}`,
      });
    });
};
