import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

const RestrictedAccountListing = ({ match }) => {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/restrictions`}
        />

        {authUser && authUser.Claims.includes("transfer.viewList") && (
          <Route
            path={`${match.url}/listing`}
            component={asyncComponent(() => import("./routes/Listing"))}
          />
        )}
      </Switch>
    </div>
  );
};

export default RestrictedAccountListing;
