import React from "react";
import { useSelector } from "react-redux";

const Footer = () => {
  const env = useSelector(state => state.settings.env);
  return (
    <footer className="app-footer">
      <span className="d-inline-block">iFlip Investor LLC &copy; 2019</span>
      <span className="">{`Env: ${env}`}</span>
    </footer>
  );
};
export default Footer;
