import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

const Accounts = ({ match }) => {
  const authUser = useSelector(state => state.auth.authUser);
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/listing`} />
        {/* Leaving this route unprotected as this is the default route where all users land after login    */}
        <Route
          path={`${match.url}/listing`}
          component={asyncComponent(() => import("./routes/Listing"))}
        />
        {authUser && authUser.Claims.includes("tradingAccount.viewDetails") && (
          <Route
            path={`${match.url}/account`}
            component={asyncComponent(() => import("./routes/Account"))}
          />
        )}
        <Route
          path={`${match.url}/createUser`}
          component={asyncComponent(() => import("./routes/NewUser"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Accounts;
