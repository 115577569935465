import {
  RESET_STATE,
  SET_ALL_ROLES,
  SET_ALL_PERMISSIONS,
  ROLE_CREATED_STATUS,
  ROLE_DELETED_STATUS,
  ROLE_UPDATED_STATUS,
  RESET_ROLE_CREATED_STATUS,
  SET_USERS_BY_ROLES,
  SET_SEARCHED_USERS,
  SET_USERLISTUPDATE_STATUS,
  RESET_USERLISTUPDATE_STATUS,
  RESET_ROLEUPDATED_STATUS,
  RESET_ROLEDELETE_STATUS,
  SHOW_MANAGEUSERS_DIALOG
} from "../constants/ActionTypes";

import { APP_DEFAULTS, ACTION_STATUS } from "../util/Utility";

const initState = {
  // role data
  roles: [],
  rolesRecordsCount: 0,
  rolesTotalRecordsCount: 0,
  // flag remove search term if not required,
  rolesSearchTerm: "",
  rolesTablePageIndex: 0,
  rolesRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  rolesOrderBy: "Created",
  rolesOrder: "desc",
  roleCreationStatus: ACTION_STATUS.INITSTATE,
  roleDeletedStatus: ACTION_STATUS.INITSTATE,
  roleUpdatedStatus: ACTION_STATUS.INITSTATE,
  permissionsList: [],

  //ManageUsers Dialog
  showManageUsersDialog: false,

  usersByRole: [],
  searchedUsers: [],
  userListRoleUpdateStatus: ACTION_STATUS.INITSTATE
};

const roles = (
  state = {
    ...initState
  },
  action
) => {
  switch (action.type) {
    case SET_ALL_ROLES: {
      let roles = action.payload;
      let rolesTotalRecordsCount = roles.length;

      return { ...state, roles, rolesTotalRecordsCount };
    }

    case SET_ALL_PERMISSIONS:
      return { ...state, permissionsList: action.payload };

    case ROLE_CREATED_STATUS:
      return { ...state, roleCreationStatus: action.payload };

    case RESET_ROLE_CREATED_STATUS:
      return {
        ...state,
        roleCreationStatus: ACTION_STATUS.FAILED
      };

    case ROLE_DELETED_STATUS:
      return {
        ...state,
        roleDeletedStatus: action.payload
      };

    case ROLE_UPDATED_STATUS:
      return { ...state, roleUpdatedStatus: action.payload };

    case SET_USERS_BY_ROLES:
      return { ...state, usersByRole: action.payload };

    case SET_SEARCHED_USERS: {
      return { ...state, searchedUsers: action.payload };
    }

    case SET_USERLISTUPDATE_STATUS:
      return { ...state, userListRoleUpdateStatus: action.payload };

    case RESET_USERLISTUPDATE_STATUS:
      return { ...state, userListRoleUpdateStatus: ACTION_STATUS.INITSTATE };

    case RESET_ROLEUPDATED_STATUS: {
      return { ...state, roleUpdatedStatus: ACTION_STATUS.INITSTATE };
    }

    case RESET_ROLEDELETE_STATUS: {
      return { ...state, roleDeletedStatus: ACTION_STATUS.INITSTATE };
    }

    case RESET_STATE: {
      return { ...initState };
    }

    case SHOW_MANAGEUSERS_DIALOG:
      return { ...state, showManageUsersDialog: action.payload };

    default:
      return state;
  }
};

export default roles;
