import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';

const Marketing = ({ match }) => (


  <div className="app-wrapper">

    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/marketdata`} />
      <Route path={`${match.url}/marketdata`} component={asyncComponent(() => import('./routes/MarketData'))} />
      <Route path={`${match.url}/authentication`} component={asyncComponent(() => import('./routes/Authentication'))} />
      < Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
    </Switch>
  </div>
);

export default Marketing;