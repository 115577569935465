import {
  RESET_STATE,
  GET_OPERATION_INFUSIONSOFTAPIREQUEST,
  RESET_OPERATION_INFUSIONSOFTAPIREQUEST,
  SET_REQUEST_POINT_IN_TIME_RESTORE_STATUS,
  RESET_REQUEST_POINT_IN_TIME_RESTORE_STATUS,
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  LISTING_FILTER_DROPDOWN as FILTER_OPTIONS,
  ACTIVE_FILTER_DROPDOWN,
} from "../util/Utility";

const initState = {
  // account listing info
  infusionSoftAPIRequestListingData: [],
  infusionSoftAPIRequestListingRecordsCount: 0,
  infusionSoftAPIRequestListingTotalRecordsCount: 0,
  infusionSoftAPIRequestListingSearchTerm: "",
  infusionSoftAPIRequestListingTablePageIndex: 0,
  infusionSoftAPIRequestListingRowsPerPage:
    APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  infusionSoftAPIRequestListingOrderBy: "Created",
  infusionSoftAPIRequestListingOrder: "desc",
  infusionSoftAPIRequestStatusFilter: [],
  infusionSoftAPIRequestListingFilter: FILTER_OPTIONS.SHOWALL,
  startDate: null,
  endDate: null,

  // Single bank account
  infusionSoftAPIRequestData: "",
  isActiveFilter: ACTIVE_FILTER_DROPDOWN.ACTIVE,

  // Request Point In Time Restore
  requestPointInTimeRestoreStatus : ""
};

const operations = (state = { ...initState }, action) => {
  switch (action.type) {
    case GET_OPERATION_INFUSIONSOFTAPIREQUEST: {
      let stateDataHelper;
      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH:
          stateDataHelper = {
            infusionSoftAPIRequestListingSearchTerm: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;

        case LISTING_REQ_ORIGIN.STATUS_FILTER:
          stateDataHelper = {
            infusionSoftAPIRequestStatusFilter: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };

          break;

        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA:
          stateDataHelper = {
            infusionSoftAPIRequestListingTablePageIndex: action.stateData,
          };

          break;

        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP:
          stateDataHelper = {
            infusionSoftAPIRequestListingRowsPerPage: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;

        case LISTING_REQ_ORIGIN.ORDERED_DATA: {
          let orderData = action.stateData.split(" ");
          stateDataHelper = {
            infusionSoftAPIRequestListingOrderBy: orderData[0],
            infusionSoftAPIRequestListingOrder: orderData[1],
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.UIOVERRIDE_SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.SHOWINCOMPLETE_CHECKBOX: {
          stateDataHelper = {
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.LISTING_FILTER: {
          stateDataHelper = {
            infusionSoftAPIRequestListingFilter: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.ACTIVE_FILTER: {
          stateDataHelper = {
            isActiveFilter: action.stateData,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.START_DATE: {
          stateDataHelper = {
            startDate: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.END_DATE: {
          stateDataHelper = {
            endDate: action.stateData,
            infusionSoftAPIRequestListingTablePageIndex: 0,
          };
          break;
        }

        default:
          break;
      }

      return {
        ...state,
        infusionSoftAPIRequestListingData: action.data,
        infusionSoftAPIRequestListingRecordsCount: action.totalMatch,
        infusionSoftAPIRequestListingTotalRecordsCount: action.total,
        ...stateDataHelper,
      };
    }

    case RESET_OPERATION_INFUSIONSOFTAPIREQUEST: {
      return { ...initState };
    }

    case RESET_STATE: {
      return { ...initState };
    }

    case SET_REQUEST_POINT_IN_TIME_RESTORE_STATUS: {
      return {
        ...state,
        requestPointInTimeRestoreStatus:action.payload
        
      }
    }
    case RESET_REQUEST_POINT_IN_TIME_RESTORE_STATUS: {
      return {
         ...state,
         requestPointInTimeRestoreStatus:action.payload
      }
    }

    default:
      return state;
  }
};

export default operations;
