import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

export default function UserAccounts({ match }) {
  const authUser = useSelector((state) => state.auth.authUser);
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact path={`${match.url}`} to={`${match.url}/listing`} />
        {authUser && authUser.Claims.includes("users.view") && (
          <Route
            path={`${match.url}/listing`}
            component={asyncComponent(() => import("./Listing"))}
          />
        )}

        {authUser && authUser.Claims.includes("users.manage") && (
          <Route
            path={`${match.url}/createUser`}
            component={asyncComponent(() => import("./CreateUser"))}
          />
        )}

        {authUser && authUser.Claims.includes("users.manage") && (
          <Route
            path={`${match.url}/resendEmail`}
            component={asyncComponent(() => import("./ResendEmail"))}
          />
        )}

        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
}
