import { SET_USER_TIMEZONE } from "../constants/ActionTypes";
import { UTC_OFFSET_ZONES } from "../util/timeZones";
import {
  getUserTimeZoneLS,
  setUserTimeZoneLS,
  setOffsetTime
} from "../util/localStorageService";
var moment = require("moment-timezone");

const getLocalTimeZone = () => {
  const timeZoneLS = getUserTimeZoneLS();
  if (timeZoneLS) {
    const getOffTimeLS = timeZoneLS.slice(3, 9);
    const signCharcode = getOffTimeLS.charCodeAt(0);
    const addToUTCTime = signCharcode === 43 ? true : false;
    const offsetHours =
      parseInt(getOffTimeLS.slice(1, 3)) + parseInt(getOffTimeLS.slice(4)) / 60;
    return {
      userTimeZone: timeZoneLS,
      timeZoneOffsetTime: getOffTimeLS,
      addToUTCTime,
      offsetHours
    };
  } else {
    const guessedTimeZ = moment.tz.guess();
    const offsetTime = moment()
      .tz(guessedTimeZ)
      .format("Z");
    const guessedTimeZone = UTC_OFFSET_ZONES.find(element =>
      element.includes(offsetTime)
    );
    const signCharcode = offsetTime.charCodeAt(0);
    const addToUTCTime = signCharcode === 43 ? true : false;
    const offsetHours =
      parseInt(offsetTime.slice(1, 3)) + parseInt(offsetTime.slice(4)) / 60;
    setUserTimeZoneLS(guessedTimeZone);
    setOffsetTime(offsetTime);
    return {
      userTimeZone: guessedTimeZone,
      timeZoneOffsetTime: offsetTime,
      addToUTCTime,
      offsetHours
    };
  }
};

const reducer = (state = { ...getLocalTimeZone() }, action) => {
  switch (action.type) {
    case SET_USER_TIMEZONE: {
      const timeZoneOffsetTime = action.payload.offsetTime;
      const signCharCode = timeZoneOffsetTime.charCodeAt(0);
      const toBeAdded = signCharCode === 43 ? true : false;
      const hours =
        parseInt(timeZoneOffsetTime.slice(1, 3)) +
        parseInt(timeZoneOffsetTime.slice(4)) / 60;
      return {
        ...state,
        userTimeZone: action.payload.timeZone,
        timeZoneOffsetTime,
        addToUTCTime: toBeAdded,
        offsetHours: hours
      };
    }
    default:
      return state;
  }
};

export default reducer;
