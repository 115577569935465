import React from "react";

export const headCells = [
  {
    id: "symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
  },
  { id: "cusiP_NUMBER", numeric: false, disablePadding: false, label: "Cusip" },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "isFractionable",
    numeric: false,
    disablePadding: false,
    label: "IsFractionable",
  },

  {
    id: "isEnabled",
    numeric: false,
    disablePadding: false,
    label: "IsEnabled",
  },
  {
    id: "isBlacklist",
    numeric: false,
    disablePadding: false,
    label: "IsBlackList",
  },
  {
    id: "isWhitelist",
    numeric: false,
    disablePadding: false,
    label: "IsWhitelist",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export const getActionColumnSymbol = (row, editHandler) => (
  <div style={{ display: "flex" }}>
    <img
      src={require("assets/images/edit.png")}
      alt="Edit"
      width={20}
      height={20}
      style={{ margin: 7, cursor: "pointer" }}
      onClick={() => editHandler(row.symbol)}
    />
  </div>
);

export const getTypeColumn = (row) => {
  return <div>{row}</div>;
};

export const getColumnComponentSymbol = (row, type) => (
  <div style={{ width: "100px" }}>
    {type === "string" && <div className="ellipsis">{row}</div>}
    {type === "boolean" && <div>{row === true ? "True" : "False"}</div>}
    {type === "action" && row}
  </div>
);
