import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  USER_DATA,
  USER_TOKEN_SET,
  USER_UNAUTHORIZED_LOGIN,
  SET_IMPERSONATED_TOKEN,
} from "../constants/ActionTypes";
import axios from "util/Api";
import {
  setTokens,
  getPublicIP,
  clearLocalStorageData,
} from "../util/localStorageService";
import { ACTION_STATUS } from "../util/Utility";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const resetUnauthorizedLogin = () => {
  return {
    type: USER_UNAUTHORIZED_LOGIN,
    payload: ACTION_STATUS.INITSTATE,
  };
};

export const resetImpersonatedToken = () => {
  return {
    type: SET_IMPERSONATED_TOKEN,
    payload: {
      access_token: "",
      refresh_token: "",
      expires_in: 0,
    },
  };
};

export const userSignUp = ({ name, email, password }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["access-token"] =
            "Bearer " + data.token.access_token;

          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getTokenToImpersonate = (email) => {
  const accessToken = localStorage.getItem("access_token");
  let loginData = new FormData();
  loginData.append("UserName", email);
  loginData.append("client_id", "proxy_client");
  loginData.append(
    "grant_type",
    "urn:ietf:params:oauth:grant-type:token-exchange"
  );
  loginData.append("client_secret", "secret");
  loginData.append("scope", "flipapi_api");
  loginData.append(
    "subject_token_type",
    "urn:ietf:params:oauth:token-type:access_token"
  );
  loginData.append("subject_token", accessToken);
  loginData.append("exchange_style", "impersonation");

  const urlEncodedData = new URLSearchParams();
  for (const pair of loginData) {
    urlEncodedData.append(pair[0], pair[1]);
  }

  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post("/connect/token", urlEncodedData)
      .then(({ data }) => {
        if (data.access_token) {
          dispatch({ type: SET_IMPERSONATED_TOKEN, payload: data });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
          return null;
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const userSignIn = (body) => {
  let loginData = new FormData();
  loginData.append("username", body.username);
  loginData.append("password", body.password);
  loginData.append("client_id", "flip_mobile");
  loginData.append("grant_type", "password");
  loginData.append(
    "scope",
    "openid email phone profile offline_access roles flipapi_api"
  );

  const urlEncodedData = new URLSearchParams();
  for (const pair of loginData) {
    urlEncodedData.append(pair[0], pair[1]);
  }
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    axios
      .post("/connect/token", urlEncodedData)
      .then(({ data }) => {
        if (data.access_token) {
          return data;
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
          return null;
        }
      })
      .then((tokens) => {
        if (tokens) {
          const ip = getPublicIP();
          axios
            .get(
              `/api/account/users/me/it@iflipinvest.com?version=3.0.10&SecurityKey=fhdhdfh58568568568`,
              {
                headers: {
                  "access-token": `Bearer ${tokens.access_token}`,
                  Authorization: `Bearer ${tokens.access_token}`,
                },
              }
            )
            .then(({ data }) => {
              dispatch({ type: FETCH_SUCCESS });
              if (data.User.Claims.includes("pageAccess")) {
                setTokens({
                  access_token: tokens.access_token,
                  refresh_token: tokens.refresh_token,
                });
                dispatch({
                  type: USER_TOKEN_SET,
                  payload: tokens.access_token,
                });

                dispatch({ type: USER_DATA, payload: data.User });
              } else {
                clearLocalStorageData();
                dispatch({
                  type: USER_UNAUTHORIZED_LOGIN,
                  payload: ACTION_STATUS.SUCCESS,
                });
              }
            })
            .catch((error) => {
              dispatch({ type: FETCH_ERROR, payload: error.message });
            });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
