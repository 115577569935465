import {
  GET_ALL_ORDERS_LISTINGS,
  GET_ALL_ORDERS_BASICS,
  GET_AGGREGATE_TRADING_ACCOUNT_ORDERS_LISTINGS,
  GET_AGGREGATE_SYMBOL_ORDERS_LISTINGS,
  GET_AGGREGATE_ACTION_ORDERS_LISTINGS,
  GET_ALL_FIXED_ORDERS_LISTING,
  GET_ALL_EXECUTION_LISTING,
} from "../constants/ActionTypes";

import {
  LISTING_REQ_ORIGIN,
  APP_DEFAULTS,
  ORDER_STATUS_DROPDOWN,
  SEARCH_ORDERS_BY_DROPDOWN,
} from "../util/Utility";

const todaysDate = new Date();
const yesterdaysDate = new Date();

const initState = {
  ordersAggregateTradingAccountData: [],
  ordersAggregateTradingAccountListingSearchTerm: "",
  ordersAggregateTradingAccountListingActionsTerm: [],
  ordersAggregateTradingAccountListingOrderStatusTerm: [],
  ordersAggregateTradingAccountListingStartDateTerm: `${yesterdaysDate.getMonth() +
    1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
  ordersAggregateTradingAccountListingEndDateTerm: `${todaysDate.getMonth() +
    1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,

  ordersAggregateSymbolData: [],
  ordersAggregateSymbolListingSearchTerm: "",
  ordersAggregateSymbolListingActionsTerm: [],
  ordersAggregateSymbolListingOrderStatusTerm: [],
  ordersAggregateSymbolListingStartDateTerm: `${yesterdaysDate.getMonth() +
    1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
  ordersAggregateSymbolListingEndDateTerm: `${todaysDate.getMonth() +
    1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,

  ordersAggregateActionData: [],
  ordersAggregateActionListingSearchTerm: "",
  ordersAggregateActionListingActionsTerm: [],
  ordersAggregateActionListingOrderStatusTerm: [],
  ordersAggregateActionListingStartDateTerm: `${yesterdaysDate.getMonth() +
    1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
  ordersAggregateActionListingEndDateTerm: `${todaysDate.getMonth() +
    1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,

  trades_ordersListingData: [],
  trades_ordersListingBasics: [],
  trades_ordersListingCount: 0,
  trades_ordersListingTotalCount: 0,
  trades_ordersListingMatchedCount: 0,
  trades_ordersListingTotalPagesCount: 0,
  trades_ordersListingSearchTerm: "",
  trades_ordersListingOptionsTerm: [],
  trades_ordersListingOrderActionsTerm: [],
  trades_ordersListingOrderStatusTerm: [],
  trades_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
    1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
  trades_ordersListingEndDateTerm: `${todaysDate.getMonth() +
    1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
  trades_ordersListingTablePageIndex: 0,
  trades_ordersListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  trades_ordersListingOrderBy: "CreatedDate",
  trades_ordersListingOrder: "",
  trades_ordersData: "",
  trades_isOnlySelected: false,

  fix_ordersListingData: [],
  fix_ordersListingCount: 0,
  fix_ordersListingTotalCount: 0,
  fix_ordersListingMatchedCount: 0,
  fix_ordersListingTotalPagesCount: 0,
  fix_ordersListingSearchTerm: "",
  fix_ordersListingTablePageIndex: 0,
  fix_ordersListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  fix_ordersListingOrderBy: "Created",
  fix_ordersListingOrder: "",

  executionReports: [],

  transfers_ordersListingData: [],
  transfers_ordersListingBasics: [],
  transfers_ordersListingCount: 0,
  transfers_ordersListingTotalCount: 0,
  transfers_ordersListingMatchedCount: 0,
  transfers_ordersListingTotalPagesCount: 0,
  transfers_ordersListingSearchTerm: "",
  transfers_ordersListingOptionsTerm: [],
  transfers_ordersListingOrderActionsTerm: [],
  transfers_ordersListingOrderStatusTerm: [],
  transfers_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
    1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
  transfers_ordersListingEndDateTerm: `${todaysDate.getMonth() +
    1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
  transfers_ordersListingTablePageIndex: 0,
  transfers_ordersListingRowsPerPage: APP_DEFAULTS.DEFAULT_TABLE_PAGE_SIZE,
  transfers_ordersListingOrderBy: "CreatedDate",
  transfers_ordersListingOrder: "",
  transfers_ordersData: "",
  transfers_isOnlySelected: false,
};

const ordersListing = (state = { ...initState }, action) => {
  if (action.pagePrefix && action.pagePrefix === "allTrades") {
    if (action.type === GET_ALL_ORDERS_LISTINGS) {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
          stateDataHelper = {
            trades_ordersListingSearchTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.OPTIONS_FILTER: {
          stateDataHelper = {
            trades_ordersListingOptionsTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDER_ACTIONS_FILTER: {
          stateDataHelper = {
            trades_ordersListingOrderActionsTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDER_STATUS_FILTER: {
          stateDataHelper = {
            trades_ordersListingOrderStatusTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            trades_ordersListingStartDateTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            trades_ordersListingEndDateTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.IS_ONLY_SELECTED_ORDERS_FILTER: {
          stateDataHelper = {
            trades_isOnlySelected: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP: {
          stateDataHelper = {
            trades_ordersListingRowsPerPage: action.originValue,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            trades_ordersListingSearchTerm: "",
            trades_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            trades_ordersListingOrderActionsTerm: [],
            trades_ordersListingOrderStatusTerm: [],
            trades_ordersListingOptionsTerm: [],
            trades_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            trades_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            trades_isOnlySelected: false,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDERS_SUBMITTED_PRESET: {
          stateDataHelper = {
            trades_ordersListingSearchTerm: "",
            trades_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            trades_ordersListingOrderActionsTerm: [],
            trades_ordersListingOrderStatusTerm: [
              ORDER_STATUS_DROPDOWN.COMPLETE,
              ORDER_STATUS_DROPDOWN.REJECTED,
              ORDER_STATUS_DROPDOWN.CANCELLED,
              ORDER_STATUS_DROPDOWN.SUBMITTEDOTHER,
            ],
            trades_ordersListingOptionsTerm: [],
            trades_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            trades_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            trades_isOnlySelected: false,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDERS_UNSUBMITTED_PRESET: {
          stateDataHelper = {
            trades_ordersListingSearchTerm: "",
            trades_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            trades_ordersListingOrderActionsTerm: [],
            trades_ordersListingOrderStatusTerm: [
              ORDER_STATUS_DROPDOWN.ONOPEN,
              ORDER_STATUS_DROPDOWN.UNSUBMITTEDOTHER,
            ],
            trades_ordersListingOptionsTerm: [],
            trades_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            trades_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            trades_isOnlySelected: false,
          };
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        trades_ordersListingData: action.data.Data.Items,
        trades_ordersListingCount: action.data.Data.Count,
        trades_ordersListingTotalCount: action.data.Data.TotalCount,
        trades_ordersListingMatchedCount: action.data.Data.MatchedCount,
        trades_ordersListingTotalPagesCount: action.data.Data.TotalPages,
        trades_ordersListingTablePageIndex: 0,
        ...stateDataHelper,
      };
    }

    if (action.type === GET_ALL_ORDERS_BASICS) {
      return {
        ...state,
        trades_ordersListingBasics: action.buySellRequestBasics,
      };
    }
  }

  if (action.pagePrefix && action.pagePrefix === "allFixOrders") {
    if (action.type === GET_ALL_FIXED_ORDERS_LISTING) {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
          stateDataHelper = {
            fix_ordersListingSearchTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.CHANGE_ROWSPP: {
          stateDataHelper = {
            fix_ordersListingRowsPerPage: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.NEW_PAGE_DATA: {
          stateDataHelper = {
            fix_ordersListingTablePageIndex: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            fix_ordersListingSearchTerm: "",
          };
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        fix_ordersListingData: action.data.Data.Items,
        fix_ordersListingCount: action.data.Data.Count,
        fix_ordersListingTotalCount: action.data.Data.TotalCount,
        fix_ordersListingMatchedCount: action.data.Data.MatchedCount,
        fix_ordersListingTotalPagesCount: action.data.Data.TotalPages,
        ...stateDataHelper,
      };
    }
  }

  if (action.pagePrefix && action.pagePrefix === "allTransfers") {
    if (action.type === GET_ALL_ORDERS_LISTINGS) {
      let stateDataHelper;

      switch (action.origin) {
        case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
          stateDataHelper = {
            transfers_ordersListingSearchTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.OPTIONS_FILTER: {
          stateDataHelper = {
            transfers_ordersListingOptionsTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDER_ACTIONS_FILTER: {
          stateDataHelper = {
            transfers_ordersListingOrderActionsTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDER_STATUS_FILTER: {
          stateDataHelper = {
            transfers_ordersListingOrderStatusTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
          stateDataHelper = {
            transfers_ordersListingStartDateTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
          stateDataHelper = {
            transfers_ordersListingEndDateTerm: action.originValue,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.IS_ONLY_SELECTED_ORDERS_FILTER: {
          stateDataHelper = {
            transfers_isOnlySelected: action.originValue,
          };
          break;
        }

        case LISTING_REQ_ORIGIN.RESET_FILTER: {
          stateDataHelper = {
            transfers_ordersListingSearchTerm: "",
            transfers_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            transfers_ordersListingOrderActionsTerm: [],
            transfers_ordersListingOrderStatusTerm: [],
            transfers_ordersListingOptionsTerm: [],
            transfers_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            transfers_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            transfers_isOnlySelected: false,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDERS_PROCESSED_PRESET: {
          stateDataHelper = {
            transfers_ordersListingSearchTerm: "",
            transfers_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            transfers_ordersListingOrderActionsTerm: [],
            transfers_ordersListingOrderStatusTerm: [
              ORDER_STATUS_DROPDOWN.COMPLETE,
              ORDER_STATUS_DROPDOWN.REJECTED,
              ORDER_STATUS_DROPDOWN.CANCELLED,
            ],
            transfers_ordersListingOptionsTerm: [],
            transfers_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            transfers_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            transfers_isOnlySelected: false,
          };
          break;
        }
        case LISTING_REQ_ORIGIN.ORDERS_UNPROCESSED_PRESET: {
          stateDataHelper = {
            transfers_ordersListingSearchTerm: "",
            transfers_ordersListingSearchOrdersByTerm:
              SEARCH_ORDERS_BY_DROPDOWN.ALL,
            transfers_ordersListingOrderActionsTerm: [],
            transfers_ordersListingOrderStatusTerm: [
              ORDER_STATUS_DROPDOWN.ONHOLD,
              ORDER_STATUS_DROPDOWN.SKIPPED,
            ],
            transfers_ordersListingOptionsTerm: [],
            transfers_ordersListingStartDateTerm: `${yesterdaysDate.getMonth() +
              1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
            transfers_ordersListingEndDateTerm: `${todaysDate.getMonth() +
              1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
            transfers_isOnlySelected: false,
          };
          break;
        }
        default:
          break;
      }

      return {
        ...state,
        transfers_ordersListingData: action.data.Data.Items,
        transfers_ordersListingCount: action.data.Data.Count,
        transfers_ordersListingTotalCount: action.data.Data.TotalCount,
        transfers_ordersListingMatchedCount: action.data.Data.MatchedCount,
        transfers_ordersListingTotalPagesCount: action.data.Data.TotalPages,
        transfers_ordersListingTablePageIndex: 0,
        ...stateDataHelper,
      };
    }

    if (action.type === GET_ALL_ORDERS_BASICS) {
      return {
        ...state,
        transfers_ordersListingBasics: action.buySellRequestBasics,
      };
    }
  }

  if (action.type === GET_AGGREGATE_TRADING_ACCOUNT_ORDERS_LISTINGS) {
    let stateDataHelper;

    switch (action.origin) {
      case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingSearchTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_ACTIONS_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingActionsTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_STATUS_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingOrderStatusTerm:
            action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingStartDateTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingEndDateTerm: action.originValue,
        };
        break;
      }

      case LISTING_REQ_ORIGIN.RESET_FILTER: {
        stateDataHelper = {
          ordersAggregateTradingAccountListingSearchTerm: "",
          ordersAggregateTradingAccountListingActionsTerm: [],
          ordersAggregateTradingAccountListingOrderStatusTerm: [],
          ordersAggregateTradingAccountListingStartDateTerm: `${yesterdaysDate.getMonth() +
            1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
          ordersAggregateTradingAccountListingEndDateTerm: `${todaysDate.getMonth() +
            1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
        };
        break;
      }
      default:
        break;
    }

    return {
      ...state,
      ordersAggregateTradingAccountData: action.data.Data,
      ...stateDataHelper,
    };
  }

  if (action.type === GET_AGGREGATE_SYMBOL_ORDERS_LISTINGS) {
    let stateDataHelper;

    switch (action.origin) {
      case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingSearchTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_ACTIONS_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingActionsTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_STATUS_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingOrderStatusTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingStartDateTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingEndDateTerm: action.originValue,
        };
        break;
      }

      case LISTING_REQ_ORIGIN.RESET_FILTER: {
        stateDataHelper = {
          ordersAggregateSymbolListingSearchTerm: "",
          ordersAggregateSymbolListingActionsTerm: [],
          ordersAggregateSymbolListingOrderStatusTerm: [],
          ordersAggregateSymbolListingStartDateTerm: `${yesterdaysDate.getMonth() +
            1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
          ordersAggregateSymbolListingEndDateTerm: `${todaysDate.getMonth() +
            1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
        };
        break;
      }
      default:
        break;
    }

    return {
      ...state,
      ordersAggregateSymbolData: action.data.Data,
      ...stateDataHelper,
    };
  }

  if (action.type === GET_AGGREGATE_ACTION_ORDERS_LISTINGS) {
    let stateDataHelper;

    switch (action.origin) {
      case LISTING_REQ_ORIGIN.SEARCH_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingSearchTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_ACTIONS_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingActionsTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.ORDER_STATUS_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingOrderStatusTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.START_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingStartDateTerm: action.originValue,
        };
        break;
      }
      case LISTING_REQ_ORIGIN.END_DATE_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingEndDateTerm: action.originValue,
        };
        break;
      }

      case LISTING_REQ_ORIGIN.RESET_FILTER: {
        stateDataHelper = {
          ordersAggregateActionListingSearchTerm: "",
          ordersAggregateActionListingActionsTerm: [],
          ordersAggregateActionListingOrderStatusTerm: [],
          ordersAggregateActionListingStartDateTerm: `${yesterdaysDate.getMonth() +
            1}-${yesterdaysDate.getDate()}-${yesterdaysDate.getFullYear()}`,
          ordersAggregateActionListingEndDateTerm: `${todaysDate.getMonth() +
            1}-${todaysDate.getDate()}-${todaysDate.getFullYear()}`,
        };
        break;
      }
      default:
        break;
    }

    return {
      ...state,
      ordersAggregateActionData: action.data.Data,
      ...stateDataHelper,
    };
  }

  if (action.type === GET_ALL_EXECUTION_LISTING) {
    return {
      ...state,
      executionReports: action.payload,
    };
  }

  return state;
};

export default ordersListing;
