import axios from "util/Api";
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SET_ALL_ROLES,
  SET_ALL_PERMISSIONS,
  ROLE_CREATED_STATUS,
  ROLE_DELETED_STATUS,
  ROLE_UPDATED_STATUS,
  RESET_ROLE_CREATED_STATUS,
  SET_USERS_BY_ROLES,
  SET_SEARCHED_USERS,
  SET_USERLISTUPDATE_STATUS,
  RESET_USERLISTUPDATE_STATUS,
  RESET_ROLEUPDATED_STATUS,
  RESET_ROLEDELETE_STATUS,
} from "../constants/ActionTypes";
import { ACTION_STATUS } from "../util/Utility";

export const resetRoleCreatedStatus = () => {
  return { type: RESET_ROLE_CREATED_STATUS };
};

export const resetUserListUpdateStatus = () => {
  return { type: RESET_USERLISTUPDATE_STATUS };
};

export const resetRoleUpdateStatus = () => {
  return {
    type: RESET_ROLEUPDATED_STATUS,
  };
};

export const resetRoleDeleteStatus = () => {
  return {
    type: RESET_ROLEDELETE_STATUS,
  };
};

export const getAllRoles = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let url = `api/Account/roles/-1/-1`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SET_ALL_ROLES, payload: response.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const getAllPermissions = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let url = `/api/Account/permissions`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SET_ALL_PERMISSIONS, payload: response.data });
        } else {
          dispatch({ type: FETCH_ERROR, payload: response.error });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const createNewRole = (formBody) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let url = `/api/account/role`;
    axios
      .post(url, formBody)
      .then(({ data }) => {
        if (data.Message === "Success") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: ROLE_CREATED_STATUS,
            payload: ACTION_STATUS.SUCCESS,
          });
          dispatch(getAllRoles());
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ROLE_CREATED_STATUS,
          payload: ACTION_STATUS.FAILED,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const deleteRole = (roleName) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let url = `/api/account/role/${roleName}`;

    axios
      .delete(url)
      .then(({ data }) => {
        if (data.Message === "Success") {
          // dispatch({ type: FETCH_SUCCESS });
          dispatch(getAllRoles());
          dispatch({
            type: ROLE_DELETED_STATUS,
            payload: ACTION_STATUS.SUCCESS,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ROLE_DELETED_STATUS,
          payload: ACTION_STATUS.FAILED,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Updates the list of claims/permissions for a role
 * @param {*} formBody
 */
export const updateRole = (formBody) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let url = `/api/account/role/${formBody.Name}`;

    axios
      .post(url, formBody)
      .then(({ data }) => {
        if (data.Message === "Success") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch(getAllRoles());
          dispatch({
            type: ROLE_UPDATED_STATUS,
            payload: ACTION_STATUS.SUCCESS,
          });
        } else {
          dispatch({
            type: ROLE_UPDATED_STATUS,
            payload: ACTION_STATUS.FAILED,
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: ROLE_UPDATED_STATUS,
          payload: ACTION_STATUS.FAILED,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Fetches list of users who are assigned a particular role
 * @param {string} roleName
 */
export const getUsersByRoles = (roleName) => {
  return (dispatch) => {
    let skip = -1,
      take = -1;

    dispatch({ type: FETCH_START });
    const url = `/api/account/role/${roleName}/user/${skip}/${take}`;

    axios
      .get(url)
      .then((response) => {
        if (response.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_USERS_BY_ROLES,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * search users with a search term
 * @param {*} searchTerm
 */
export const getUsersByEmailOrUsername = (searchTerm) => {
  return (dispatch) => {
    let skip = 0,
      take = 10;

    dispatch({ type: FETCH_START });
    const url = `/api/account/user/username/${skip}/${take}?searchTerm=${searchTerm}`;
    axios
      .get(url)
      .then(({ data }) => {
        if (data.Message === "Success") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_SEARCHED_USERS,
            payload: data.Data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/**
 * Updates list of users with role. This removes any users not in the list and adds any users in the list
 * @param {string} roleName
 * @param {*} userList
 */
export const updateUserListWithRole = (roleName, userList) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const url = `/api/account/user/role/${roleName}`;
    axios
      .post(url, userList)
      .then(({ data }) => {
        if (data.Message === "Success") {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SET_USERLISTUPDATE_STATUS,
            payload: ACTION_STATUS.SUCCESS,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: SET_USERLISTUPDATE_STATUS,
          payload: ACTION_STATUS.FAILED,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
