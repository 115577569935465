import React from "react";
import SearchSymbol from "./searchSymbols";
import { Redirect, Route } from "react-router-dom";

const Symbols = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Redirect exact from={`${match.url}/`} to={`${match.url}`} />
      <Route path={`${match.url}`} component={SearchSymbol} />
    </div>
  );
};

export default Symbols;
