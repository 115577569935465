import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

const TransfersListing = ({ match }) => {
  const authUser = useSelector(state => state.auth.authUser);
  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect
          exact
          from={`${match.url}/`}
          to={`${match.url}/cashMovement`}
        />

        {authUser && authUser.Claims.includes("transfer.viewList") && (
          <Route
            path={`${match.url}/listing`}
            component={asyncComponent(() => import("./routes/Listing"))}
          />
        )}

        {authUser && authUser.Claims.includes("transfer.viewDetails") && (
          <Route
            path={`${match.url}/details`}
            component={asyncComponent(() => import("./routes/Details"))}
          />
        )}

        <Route
          path={`${match.url}/createListing`}
          component={asyncComponent(() => import("./routes/NewListing"))}
        />
        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default TransfersListing;
