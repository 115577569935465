import { APP_DEFAULTS } from "./Utility";

export const clearLocalStorageData = () => {
  localStorage.clear();
};

// ***** TOKENS *****
export const setTokens = tokenObj => {
  localStorage.setItem("access_token", tokenObj.access_token);
  localStorage.setItem("refresh_token", tokenObj.refresh_token);
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const getRefreshToken = () => {
  return localStorage.getItem("refresh_token");
};

export const clearTokens = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
};

// ***** USER DATA *****
export const setUserData = userData => {
  localStorage.setItem("user_data", userData);
};

export const removeUserData = () => {
  localStorage.removeItem("user_data");
};

// ***** TIMEZONE & OFFSETTIME *****
export const setUserTimeZoneLS = timeZone => {
  localStorage.setItem(APP_DEFAULTS.USER_TIMEZONE, timeZone);
};

export const getUserTimeZoneLS = () => {
  return localStorage.getItem(APP_DEFAULTS.USER_TIMEZONE);
};

export const removeUserTimeZoneLS = () => {
  localStorage.removeItem(APP_DEFAULTS.USER_TIMEZONE);
};

export const setOffsetTime = offsetTime => {
  localStorage.setItem(APP_DEFAULTS.OFFSET_TIME, offsetTime);
};

export const getOffsetTime = () => {
  return localStorage.getItem(APP_DEFAULTS.OFFSET_TIME);
};

export const removeOffsetTime = () => {
  localStorage.removeItem(APP_DEFAULTS.OFFSET_TIME);
};

// ***** THEME COLOR *****
export const setThemeColor = themeColor => {
  return localStorage.setItem("theme_color", themeColor);
};

export const getThemeColor = () => {
  return localStorage.getItem("theme_color");
};

// ***** Public IP ******
export const setPublicIP = ip => {
  localStorage.setItem("public_ip", ip);
};

export const getPublicIP = () => {
  return localStorage.getItem("public_ip");
};

// ***** User Server *****
export const setUserServer = server => {
  localStorage.setItem("user_server", server);
};

export const getUserServer = () => {
  return localStorage.getItem("user_server");
};
