import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

export default function DateFilter({
  label,
  date,
  onDateChange,
  checkStartDate = null,
  checkEndDate = null,
}) {
  const [value, setValue] = useState(date);
  const [isdateWarned, setIsDateWarned] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");

  useEffect(() => {
    setValue(date);
  }, [date]);

  const dateChangeHandler = (date) => {
    let formattedDate = date ? moment(date).format("MM-DD-YYYY") : null;
    if (
      checkStartDate &&
      checkStartDate !== null &&
      moment(checkStartDate).isAfter(formattedDate)
    ) {
      setIsDateWarned(true);
      setWarningMessage("End Date is Before Start Date!");
      return;
    } else if (
      checkEndDate &&
      checkEndDate !== null &&
      moment(checkEndDate).isBefore(formattedDate)
    ) {
      setIsDateWarned(true);
      setWarningMessage("Start date is After End Date!");
      return;
    }
    onDateChange(formattedDate);
  };
  const handleDialogCancel = () => {
    setIsDateWarned(false);
  };

  return (
    <div>
      <Dialog
        open={isdateWarned}
        TransitionComponent={Slide}
        onClose={handleDialogCancel}
      >
        <div>
          <DialogTitle>{"Be Careful With The Date"}</DialogTitle>
          <DialogContent>
            <Paper>
              <DialogContentText>{warningMessage}</DialogContentText>
              <div className="clearfix"></div>
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogCancel} color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          clearable
          disableFuture="true"
          animateYearScrolling="true"
          inputVariant="outlined"
          format="MM-dd-yyyy"
          emptyLabel={label}
          value={value}
          onChange={dateChangeHandler}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
