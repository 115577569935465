import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import "../../app/routes/tradingAccounts/routes/Listing/listing.css";

function EnhancedTableHead(props) {
  const { headCells, classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={true}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  footerTotalCount: {
    position: "absolute",
    bottom: 16,
    right: 100
  },
  tableFooter: {
    position: "relative"
  }
}));

export default function EnhancedTable(props) {
  const {
    tableData,
    headCells,
    changePageHandler,
    count,
    rowsPerPage: recordsPerPage,
    rowsPerPageChangeHandler,
    orderBy: orderByField,
    order: orderingDirection,
    sortingHandler,
    rowClickHandler,
    sortableColumns,
    primaryFieldName,
    pageIndex,
    totalRecordsCount
  } = props;

  const classes = useStyles();
  const [order, setOrder] = useState(orderingDirection);
  const [orderBy, setOrderBy] = useState(orderByField);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(recordsPerPage);

  useEffect(() => {
    setPage(pageIndex);
    setRowsPerPage(recordsPerPage);
    setOrder(orderingDirection);
    setOrderBy(orderByField);
  }, [pageIndex, recordsPerPage, orderByField, orderingDirection]);

  const handleRequestSort = (event, property) => {
    if (sortableColumns.includes(property)) {
      const isDesc = orderBy === property && order === "desc";
      const sortingValue = isDesc ? "asc" : "desc";
      sortingHandler(rowsPerPage, property, sortingValue);
    }
  };

  const handleClick = (event, rowData) => {
    if (rowClickHandler) {
      rowClickHandler(rowData);
    }
  };

  const handleChangePage = (event, newPage) => {
    changePageHandler(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    let rowsCount = parseInt(
      event.target.value.toString().replace("All", totalRecordsCount+1),
      10
    );
    rowsPerPageChangeHandler(rowsCount);
  };

  const emptyRows = rowsPerPage - tableData.length;

  return (
    <div className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            headCells={headCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {tableData.map((row, index) => {
              const labelId = `enhanced-table-${index}`;

              return (
                <TableRow
                  hover
                  onClick={event => handleClick(event, row)}
                  tabIndex={-1}
                  key={row[primaryFieldName]+"-"+index}
                >
                  {headCells.map((headCell, index) => {
                    return index === 0 ? (
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="left"
                        padding="none"
                        key={headCell.id}
                      >
                        {row[headCell.id]}
                        
                      </TableCell>
                    ) : (
                      <TableCell align="center" key={headCell.id}>
                        {" "}
                        {typeof row[headCell.id] === "boolean" ?(
                              <>
                                {row[headCell.id]?"Yes":"No"}
                              </>
                          ):(
                              <>
                              {row[headCell.id]}
                              </>
                          )}
                        
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className={classes.tableFooter}>
        <TablePagination
          rowsPerPageOptions={["10", "25", "50", "All"]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "previous page"
          }}
          nextIconButtonProps={{
            "aria-label": "next page"
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={classes.testClass}
        />

        <div
          className={classes.footerTotalCount}
        >{`[of ${totalRecordsCount}]`}</div>
      </div>
    </div>
  );
}
