import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import asyncComponent from "../../../util/asyncComponent";

const Operations = ({ match }) => {
  const authUser = useSelector((state) => state.auth.authUser);

  return (
    <div className="app-wrapper">
      <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/operations`} />
        {authUser && authUser.Claims.includes("operation.viewList") && (
          <Route
            path={`${match.url}/infusionsoftapirequest`}
            component={asyncComponent(() =>
              import("./routes/InfusionSoftAPIRequest/")
            )}
          />
        )}

        <Route
          path={`${match.url}/requestpointintimerestore`}
          component={asyncComponent(() =>
              import("./routes/RequestPointInTimeRestore/")
            )}
        />

        <Route
          component={asyncComponent(() =>
            import("app/routes/extraPages/routes/404")
          )}
        />
      </Switch>
    </div>
  );
};

export default Operations;
